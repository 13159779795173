import { getIncomers, isNode, getOutgoers, getConnectedEdges } from "react-flow-renderer";
import splitElements from "./splitElements";
import dagre from "dagre";

export function checkIntegrationChild(elements, selected) {
	var element = elements.find((element) => element.id == selected);
	var edges = splitElements(elements).edges;
	if (!element) return;
	var parents = getIncomers(element, splitElements(elements).nodes, edges);
	var parentsArray = [];
	while (parents.length > 0) {
		parentsArray.push(parents[0]);
		parents = getIncomers(parents[0], splitElements(elements).nodes, edges);
	}
	var childOfInteraction = false;
	parentsArray.forEach((parent) => {
		if (parent.type == "button" || parent.type == "selectMenuOption") {
			childOfInteraction = true;
		}
	});

	return childOfInteraction;
}

export function checkSplitChild(elements, id) {
	var element = elements.find((element) => element.id == id);
	var edges = splitElements(elements).edges;

	var parents = getIncomers(element, splitElements(elements).nodes, edges);
	var parentsArray = [];
	while (parents.length > 0) {
		parentsArray.push(parents[0]);
		parents = getIncomers(parents[0], splitElements(elements).nodes, edges);
	}
	var childOfInteraction = false;
	parentsArray.forEach((parent) => {
		if (parent.type == "button" || parent.type == "selectMenuOption" || parent.type == "conditionChild" || (parent.type == "action" && parent?.data?.data?.success_handles)) {
			childOfInteraction = true;
		}
	});

	return childOfInteraction;
}

export function checkErrorChild(elements, sourceId, targetId) {
	var targetElement = elements.find((element) => element.id == targetId);
	var edges = splitElements(elements).edges;

	var parents = getIncomers(targetElement, splitElements(elements).nodes, edges);
	var parentsArray = [];
	var targetParent = null;
	while (parents.length > 0) {
		if (parents[0].type == "option") {
			parents = [];
		} else {
			targetParent = parents[0];
			parents = getIncomers(parents[0], splitElements(elements).nodes, edges);
		}
	}

	var sourceElement = elements.find((element) => element.id == sourceId);
	var sourceParents = getIncomers(sourceElement, splitElements(elements).nodes, edges);
	var sourceParentsArray = [];
	var sourceParent = null;
	while (sourceParents.length > 0) {
		if (sourceParents[0].type == "option") {
			sourceParents = [];
		} else {
			sourceParent = sourceParents[0];
			sourceParents = getIncomers(sourceParents[0], splitElements(elements).nodes, edges);
		}
	}

	if ((targetParent && sourceParent && targetParent.id == sourceParent.id) || (!targetParent && !sourceParent) || (sourceParent && (sourceParent.id == "root" || sourceParent.id == "error_handler") && !targetParent) || (sourceParent != targetParent && sourceParent != "root" && sourceParent != "error_handler")) {
		return true;
	} else {
		return false;
	}
}

export function checkErrorChildVariable(elements, selected) {
	var element = elements.find((element) => element.id == selected);
	var edges = splitElements(elements).edges;

	var parents = getIncomers(element, splitElements(elements).nodes, edges);
	var parentsArray = [];
	while (parents.length > 0) {
		parentsArray.push(parents[0]);
		parents = getIncomers(parents[0], splitElements(elements).nodes, edges);
	}
	var childOfError = false;
	parentsArray.forEach((parent) => {
		if (parent.type == "error") {
			childOfError = true;
		}
	});

	return childOfError;
}

export function checkErrorSuccessHandles(elements, sourceId, sourceHandleType) {
	var sourceElement = elements.find((element) => element.id == sourceId);
	var edges = splitElements(elements).edges;
	console.log(sourceElement, "TARGET ELEMENT");
	if (sourceElement.data?.data?.success_handles) {
		var connected_edges = getConnectedEdges([sourceElement], edges);
		console.log(connected_edges, sourceId, "CONNECTED EDGES");

		// Check if the source element is already connected to another action;
		var passed = true;
		for (var i = 0; i < connected_edges.length; i++) {
			console.log(connected_edges[i].sourceHandle, sourceHandleType, "HANDLE TYPE");
			if (connected_edges[i].sourceHandle === sourceHandleType && connected_edges[i].source == sourceId) {
				passed = false;
			}
		}

		return passed;

		// return passed;
	} else {
		return false;
	}
}

export function layoutElements(ele, cursor_position) {
	const nodeWidth = 400;
	const nodeHeight = 90;

	const dagreGraph = new dagre.graphlib.Graph();
	dagreGraph.setDefaultEdgeLabel(() => ({}));
	dagreGraph.setGraph({ rankdir: "TB" });
	var elements = [...ele];
	var optionsCheck = elements.filter((element) => element.type == "option");
	if (optionsCheck.length > 0) {
		elements.push({
			source: "option_7_c44a_0728",
			target: "error_handler",
			type: "step",
			animated: true,
			arrowHeadType: "arrowclosed",
			id: "error_handler_placeholder"
		});
	}
	elements.forEach((el) => {
		if (isNode(el)) {
			var docuElement = document.getElementById(el.id);
			if (!docuElement) {
				dagreGraph.setNode(el.id, { width: 400, height: 90 });
			} else if (el.type == "selectMenuOption" || el.type == "button") {
				dagreGraph.setNode(el.id, { width: docuElement.clientWidth, height: 52 });
			} else if (el.id != "root") {
				dagreGraph.setNode(el.id, { width: docuElement.clientWidth, height: 79 });
			} else {
				dagreGraph.setNode(el.id, { width: 600, height: 121 });
			}
		} else {
			dagreGraph.setEdge(el.source, el.target);
		}
	});

	dagre.layout(dagreGraph);

	var error_handler = elements.find((element) => element.id == "error_handler");

	// Add a Temp node to the graph to make sure the error handler is always at the bottom

	elements = elements.map((el, index) => {
		if (isNode(el)) {
			const nodeWithPosition = dagreGraph.node(el.id);

			el.targetPosition = "top";
			el.sourcePosition = "bottom";
			var docuElement = document.getElementById(el.id);
			var width = 400;
			if (docuElement) {
				width = docuElement.clientWidth;
			}
			// unfortunately we need this little hack to pass a slightly different position
			// to notify react flow about the change. Moreover we are shifting the dagre node position
			// (anchor=center center) to the top left so it matches the react flow node anchor point (top left).

			// If its the first element, set it to the cursor position and position exists;
			if (el.id != "root") {
				if (cursor_position) {
					el.position = {
						x: nodeWithPosition.x + cursor_position.x - width / 2 + Math.random() / 1000,
						y: nodeWithPosition.y + cursor_position.y - 79 / 2
					};
				} else {
					el.position = {
						x: nodeWithPosition.x - width / 2 + Math.random() / 1000,
						y: nodeWithPosition.y - 79 / 2
					};
				}
			} else if (el.id == "root") {
				el.position = {
					x: nodeWithPosition.x - 600 / 2 + Math.random() / 1000,
					y: nodeWithPosition.y - 165 / 2
				};
			}
		}

		return el;
	});

	// remove placeholder edge
	elements = elements.filter((element) => element.id != "error_handler_placeholder");
	return elements;
}
