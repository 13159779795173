
import update from 'immutability-helper';

import { CLEAN_BOT } from "../variables";

const INIT_STATE =
{
    selected: null,
    open: true,
    index: null,
    saveFailed: false,
    hidden: false,
    highlightAction: null,
    module_id: null,
    marketId: null,
    mode: "event",
    groupId: null,
    future: [],
    components: {
        Guild: true,
        Channel: true,
        User: true,
        Member: true,
        Interaction: true,
        VoiceChannel: true
    },
    elements: [
    ],
    slot_id: null
};



export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case "SET_ELEMENTS":
            return update(state, {
                elements: { $set: action.payload }
            });
        case "SET_GROUP_ID":
            return update(state, {
                groupId: { $set: action.payload }
            });
        case "SET_FUTURE":
            return update(state, {
                future: { $set: action.payload }
            });
        case "RESET_BUILDER":
            return update(state, {
                $set: INIT_STATE
            });
        case "SET_SELECTED":
            return update(state, {
                selected: { $set: action.payload }
            });
        case "ASIDE_VISIBLE_TOGGLE":
            return update(state, {
                hidden: { $set: action.payload }
            });
        case "SET_BUILDER_INDEX":
            return update(state, {
                index: { $set: action.payload },
            });
        case "SET_MODULE_ID":
            return update(state, {
                module_id: { $set: action.payload }
            });
        case "SET_SLOT_ID":
            return update(state, {
                slot_id: { $set: action.payload }
            });
        case "SET_MARKET_ID":
            return update(state, {
                marketId: { $set: action.payload }
            });
        case "SET_HIGHLIGHT_ACTION":
            return update(state, {
                highlightAction: { $set: action.payload }
            });
        case "UPDATE_NODE":
            return update(state, {
                elements: {
                    [state.elements.findIndex(element => element.id == action.payload.id)]: { $set: action.payload }
                }
            });
        case "SET_ROOT_DATA":
            return update(state, {
                elements: {
                    [state.elements.findIndex(element => element.id == "root")]: {
                        data: {
                            [action.payload.key]: { $set: action.payload.value }
                        }
                    }
                }
            });
        case "COMMAND_BUILDER_SAVE_FAILED":
            return update(state, {
                saveFailed: { $set: action.payload }
            });
        case "SET_BUILDER_MODE":
            return update(state, {
                mode: { $set: action.payload.mode },
                components: { $set: action.payload.components }
            });

        case "UPDATE_ELEMENT_DATA":
            // console.log('Updating data PAYLOAD', action.payload, state.elements.findIndex(element => element.id == action.payload.id));
            return update(state, {
                elements: {
                    [state.elements.findIndex(element => element.id == action.payload.id)]: {
                        data: {
                            data: { $set: action.payload.data }
                        }
                        // data:{
                        //     data:{$set:action.payload}
                        // }
                    }
                }
            });
        default:
            return state;
    }
};
