import { faBitcoin, faReddit, faThinkPeaks, faTwitch, faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";
import moment from "moment-timezone";
import {
	faRandom,
	faEdit,
	faAd,
	faAnchor,
	faBars,
	faBrain,
	faBullhorn,
	faCartArrowDown,
	faCartPlus,
	faChartLine,
	faCloud,
	faUserCog,
	faCogs,
	faCommentDots,
	faDog,
	faDollarSign,
	faEnvelope,
	faFileInvoiceDollar,
	faGamepad,
	faGift,
	faHammer,
	faHandScissors,
	faHatCowboy,
	faHeart,
	faHeartbeat,
	faHourglassHalf,
	faInfo,
	faLanguage,
	faLaughSquint,
	faLock,
	faLockOpen,
	faMedal,
	faMoneyBill,
	faMoneyBillAlt,
	faMusic,
	faPoll,
	faSearch,
	faShip,
	faSlash,
	faSmile,
	faSurprise,
	faTicketAlt,
	faTrash,
	faUsers,
	faUserSecret,
	faUserTag,
	faWallet,
	faFile,
	faDatabase,
	faEquals,
	faCalculator,
	faTimes,
	faCalendarPlus,
	faIdBadge,
	faMapPin,
	faTable,
	faExclamation,
	faFingerprint,
	faTrashAlt
} from "@fortawesome/free-solid-svg-icons";
import { faEnvelopeOpenText } from "@fortawesome/free-solid-svg-icons";
import { faHourglassEnd, faCopy, faFileImport, faCode, faCodeBranch, faEye, faHashtag, faMouse, faMousePointer, faPaperPlane, faReply, faReplyAll, faSortNumericDown, faTextHeight, faUser, faUserMinus, faUserPlus, faQuestion, faHourglass, faComment, faComments, faCommentSlash, faUserSlash, faUserTimes, faUserFriends, faUsersSlash, faStore } from "@fortawesome/free-solid-svg-icons";

export const AllModules = [
	{
		name: "Custom",
		modules: [
			{
				name: "Custom Commands",
				description: "Create complex slash commands to personalize your bot",
				icon: "slash.png",
				premium: false,
				updated: false,
				featured: true,
				menu_icon: faSlash
			},
			{
				name: "Custom Events",
				description: "Create complex events to personalize your bot",
				icon: "customevents.png",
				premium: false,
				updated: false,
				featured: true,

				menu_icon: faCalendarPlus
			},

			{
				name: "Timed Events",
				description: "Trigger custom events through schedules or intervals",
				icon: "timedevents.png",
				premium: false,
				updated: false,

				new: true,
				featured: true,
				menu_icon: faDatabase
			},
			{
				name: "Data Storage",
				description: "Create custom variables to store data for your bot",
				icon: "datastorage.png",
				premium: false,
				updated: false,
				featured: true,
				menu_icon: faDatabase
			},

			{
				name: "Webhooks",
				description: "Trigger custom events through webhooks",
				icon: "webhooks.png",
				premium: false,
				updated: false,

				featured: true,
				menu_icon: faDatabase
			},

			{
				name: "IFTTT",
				description: "Connect your bot to thousands of other apps and services",
				icon: "IFTTT.webp",
				premium: false,
				updated: false,
				featured: true,
				menu_icon: faDatabase
			},
			// {
			//   name: "Marketplace",
			//   description: "Share and find the perfect command for your server",
			//   icon: "market.png",
			//   premium: false,
			//   menu_icon: faStore,
			//   link: "https://botghost.com/market",
			//   beta: false,
			// },
			{
				name: "Text Commands",
				description: "Create simple text commands to personalize your bot",
				icon: "customcommands.png",
				premium: false,
				menu_icon: faCode
			}
		]
	},
	{
		name: "Server Management",
		modules: [
			{
				name: "Active Developer Badge",
				description: "Get the Active Developer Badge without coding",
				icon: "activedevbadge.png",
				premium: false,
				depreciated: true,
				// new: true,
				menu_icon: faIdBadge
			},
			{
				name: "Moderation",
				description: "Moderate your server with a set of powerful commands",
				icon: "moderation.png",
				premium: false,
				menu_icon: faHammer
			},
			{
				name: "Automod",
				description: "Automatically moderate your servers text channels. No humans required",
				icon: "automoderator.png",
				premium: false,
				menu_icon: faUserSecret
			},
			{
				name: "Auto-Role",
				description: "Automatically assign roles to users when they join your server",
				icon: "autorole.png",
				premium: false,
				depreciated: true,
				menu_icon: faUserTag
			},
			{
				name: "Logging",
				description: "Get detailed information and log all server events",
				icon: "logs.png",
				premium: true,
				menu_icon: faCogs
			},
			{
				name: "Usage",
				description: "Change how your bot operates",
				icon: "operation.png",
				premium: false,
				// updated: true,
				menu_icon: faCogs
			},
			// {
			//   name: "Tickets",
			//   description: "Automatically send timed messages to your server",
			//   icon: "messenger.png",
			//   premium: false,
			//   menu_icon: faHourglassHalf,
			// },
			{
				name: "Statistics Channels",
				description: "Create channels to track metrics of your server",
				icon: "statistics.png",
				premium: true,
				menu_icon: faChartLine,
				depreciated: true
			},

			{
				name: "Status",
				description: "Set a custom status for your bot",
				icon: "wrench.png",
				premium: true,
				depreciated: true,
				menu_icon: faCommentDots
			}
		]
	},
	{
		name: "Messages",
		modules: [
			{
				name: "Announcements",
				description: "Create automatic announcements for unique events in your server",
				icon: "announcements.png",
				premium: false,
				menu_icon: faBullhorn,
				depreciated: true
			},
			{
				name: "Embed Builder",
				description: "Create and send highly customized embeds to your server",
				icon: "embedbuilder.png",
				premium: false,
				menu_icon: faFile
			},
			{
				name: "Autoresponder",
				description: "Automatically respond to trigger words and phrases",
				icon: "autoresponder.png",
				premium: false,
				menu_icon: faEnvelopeOpenText,
				depreciated: true
			},
			{
				name: "Timed Messages",
				description: "Automatically send timed messages to your server",
				icon: "messenger.png",
				premium: false,
				menu_icon: faHourglassHalf,
				depreciated: true
			},
			{
				name: "Reaction Roles",
				description: "Setup a Reaction Role system",
				icon: "reactroles.png",
				premium: true,
				depreciated: true,
				menu_icon: faSurprise
			}
		]
	},
	{
		name: "AI",
		modules: []
	},

	{
		name: "Game Integrations",
		modules: []
	},
	{
		name: "Social Integrations",
		modules: [
			{
				name: "YouTube",
				description: "Receive notifications when you or someone else posts a new video",
				icon: "youtube.png",
				premium: true,
				menu_icon: faYoutube,
				depreciated: true
			},
			// {
			//   name: "Twitter",
			//   description: "Have new tweets automatically posted in your server",
			//   icon: "twitter.png",
			//   premium: true,
			//   menu_icon: faTwitter,
			// },
			// {
			// 	name: "Reddit",
			// 	description: "Get new post alerts directly in your discord server",
			// 	icon: "reddit.png",
			// 	premium: true,
			// 	menu_icon: faReddit
			// },
			{
				name: "Twitch",
				description: "Setup stream notifications so your members never miss when you go online",
				icon: "twitch.png",
				premium: true,
				menu_icon: faTwitch,
				depreciated: true
			}
		]
	},
	{
		name: "Community Engagement",
		modules: [
			{
				name: "Giveaways",
				description: "Run giveaways in your server",
				icon: "giveaways.png",
				premium: true,
				// updated: true,

				menu_icon: faGift
			},
			{
				name: "Tickets",
				description: "Manage support tickets through your server",
				icon: "tickets.png",
				premium: false,
				menu_icon: faTicketAlt
				// new: true,
			}
		]
	},
	{
		name: "Fun",
		modules: [
			// {
			// 	name: "Meme Creator",
			// 	description: "Create memes using templates from ImgFlip",
			// 	icon: "memecreator.png",
			// 	premium: false,
			// 	menu_icon: faLaughSquint
			// },
			{
				name: "Levels",
				description: "Create a leveling system and hosted leaderboard for your server",
				icon: "levels.png",
				premium: false,
				depreciated: true,
				menu_icon: faChartLine
			},
			{
				name: "Economy",
				description: "Set up a fully fledged server economy",
				icon: "economy.png",
				premium: false,
				depreciated: true,
				menu_icon: faDollarSign
			},
			// {
			//   name: "Music",
			//   description: "Listen to music directly in your discord server",
			//   icon: "music.png",
			//   premium: true,

			//   menu_icon: faMusic,
			// },

			{
				name: "Trivia",
				description: "Test your wits against fellow members",
				icon: "trivia.png",
				premium: true,
				menu_icon: faBrain
			}
		]
	},
	{
		name: "Utilities",
		modules: [
			{
				name: "Search",
				description: "Search the Internet for a variety of things",
				icon: "search.png",
				premium: false,
				menu_icon: faSearch
			},
			// {
			//   name: "Botlist",
			//   description: "Advertise your bot on BotGhost",
			//   icon: "botlist.png",
			//   premium: false,
			//   menu_icon: faAd,
			// },
			{
				name: "Weather",
				description: "Get the weather before heading outside",
				icon: "weather.png",
				premium: true,
				depreciated: true,
				menu_icon: faCloud
			},
			{
				name: "Translator",
				description: "Translate messages with built in Google Translator",
				icon: "translator.png",
				premium: true,
				depreciated: true,
				menu_icon: faLanguage
			}
		]
	}
	// {
	//   name: "Crypto & NFTs",
	//   modules: [
	//     {
	//       name: "Crypto",
	//       description: "Get coin information and prices",
	//       icon: "bitcoin.png",
	//       premium: false,

	//       menu_icon: faBitcoin,
	//     },

	//     {
	//       name: "NFTs",
	//       description: "Get NFT collection, item and market information",
	//       icon: "eth.png",
	//       premium: false,

	//       menu_icon: faMedal,
	//     },

	//     // {
	//     //   name: "OpenSea Alerts",
	//     //   description: "Recieve item sales and listings alerts",
	//     //   icon: "opensea.png",
	//     //   premium: false,
	//     //   menu_icon: faShip,
	//     // },
	//     // {
	//     //   name: "Wallet Collection",
	//     //   description: "Collect wallet addresses for giveaways and airdrops",
	//     //   icon: "metamask.webp",
	//     //   premium: false,
	//     //   new:true,
	//     //   menu_icon: faWallet,
	//     // },
	//     // {
	//     //   name: "NFT Role Verification",
	//     //   description: "Hand out roles to verified owners of a collection",
	//     //   icon: "nft_verification.png",
	//     //   premium: false,
	//     //   new:true,
	//     //   menu_icon: faWallet,
	//     // },
	//   ]
	// },
	// {
	//   name: "Game Integrations",
	//   modules: [
	//     {
	//       name: "Fortnite",
	//       description: "Get Fortnite stats directly from Fortnite Tracker",
	//       icon: "fortnite.png",
	//       premium: true,
	//       menu_icon: faGamepad,
	//     },
	//     {
	//       name: "Apex Legends",
	//       description: "Share your latest stats directly from Apex Tracker",
	//       icon: "apex.png",
	//       premium: true,
	//       menu_icon: faGamepad,
	//     },
	//   ],
	// },
];

export const TASKS = [
	{
		header: "Invite your Bot",
		description: <span>Invite your bot to a discord server. Click on the link icon in the side menu to get started.</span>,
		button_text: "Invite",
		slug: "invite",
		action: "invite"
	},
	{
		header: "Change your bots prefix",
		description: <span>Your bot can respond to any prefix. Go to settings and change your prefix.</span>,
		button_text: "Settings",
		slug: "prefix",
		action: "page",
		page: "settings"
	},
	{
		header: "Change your bots name",
		description: <span>Want to change your bots name? You can now do so through your BotGhost dashboard. Click here or go to settings and enter a new name for your bot. </span>,
		slug: "bot_name",
		button_text: "Settings",
		action: "page",
		page: "settings"
	},
	{
		header: "Change your bots avatar",
		description: <span>Give your bot a new display picture. Click here or go to settings and upload an image.</span>,
		slug: "bot_avatar",
		button_text: "Settings",
		action: "page",
		page: "settings"
	},
	{
		header: "Enable a module",
		description: (
			<span>
				Some modules must be first enabled. Enable a module by clicking on the <strong>Enable</strong> button in the top left of a page.
			</span>
		),
		slug: "enable_module",
		button_text: "Learn More",
		action: "page",
		page: "automod"
	},
	{
		header: "Edit a Command",
		description: <span>Everyone command in BotGhost can be edited. Click on a command on a module page to open the command editor.</span>,
		slug: "edit_command",
		button_text: "Learn More",
		action: "doc",
		page: "https://docs.botghost.com/editing-commands"
	},
	{
		header: "Turn your Bot On and Off",
		description: <span>Sometimes you may want to turn your bot on and off. Go to settings and turn your bot offline and then online.</span>,
		slug: "on_off",
		button_text: "Settings",
		action: "page",
		page: "settings"
	},
	{
		header: "Create a new Bot",
		description: (
			<span>
				With BotGhost you can create as many bots as you please. Click on the botswitcher and select <strong>New Bot</strong> to create an all new bot.
			</span>
		),
		slug: "new_bot",
		button_text: "Learn More",
		action: "doc",
		page: "https://docs.botghost.com/getting-started/creating-multiple-bots"
	},
	{
		header: "Change Bots",
		description: <span>With BotGhost you can create as many bots as you please. Click on the botswitcher to change bots.</span>,
		slug: "change_bot",
		button_text: "Learn More",
		action: "doc",
		page: "https://docs.botghost.com/getting-started/creating-multiple-bots"
	}
];

export const CHANNEL_PERMISSIONS = [
	"CREATE_INSTANT_INVITE",
	"MANAGE_CHANNELS",
	"ADD_REACTIONS",
	"PRIORITY_SPEAKER",
	"STREAM",
	"VIEW_CHANNEL",
	"SEND_MESSAGES",
	"SEND_TTS_MESSAGES",
	"MANAGE_MESSAGES",
	"EMBED_LINKS",
	"ATTACH_FILES",
	"READ_MESSAGE_HISTORY",
	"MENTION_EVERYONE",
	"USE_EXTERNAL_EMOJIS",
	"CONNECT",
	"SPEAK",
	"MUTE_MEMBERS",
	"DEAFEN_MEMBERS",
	"MOVE_MEMBERS",
	"USE_VAD",
	"MANAGE_ROLES",
	"MANAGE_WEBHOOKS",
	"USE_APPLICATION_COMMANDS",
	"REQUEST_TO_SPEAK",
	"MANAGE_EVENTS",
	"MANAGE_THREADS",
	"CREATE_PUBLIC_THREADS",
	"CREATE_PRIVATE_THREADS",
	"USE_EXTERNAL_STICKERS",
	"SEND_MESSAGES_IN_THREADS",
	"USE_SOUNDBOARD",
	"CREATE_EVENTS",
	"SEND_VOICE_MESSAGES"
].sort();

export const PERMISSIONS = [
	"CREATE_INSTANT_INVITE",
	"KICK_MEMBERS",
	"BAN_MEMBERS",
	"ADMINISTRATOR",
	"MANAGE_CHANNELS",
	"MANAGE_GUILD",
	"ADD_REACTIONS",
	"VIEW_AUDIT_LOG",
	"PRIORITY_SPEAKER",
	"STREAM",
	"VIEW_CHANNEL",
	"SEND_MESSAGES",
	"SEND_TTS_MESSAGES",
	"MANAGE_MESSAGES",
	"EMBED_LINKS",
	"ATTACH_FILES",
	"READ_MESSAGE_HISTORY",
	"MENTION_EVERYONE",
	"USE_EXTERNAL_EMOJIS",
	"VIEW_GUILD_INSIGHTS",
	"CONNECT",
	"SPEAK",
	"MUTE_MEMBERS",
	"DEAFEN_MEMBERS",
	"MOVE_MEMBERS",
	"USE_VAD",
	"CHANGE_NICKNAME",
	"MANAGE_NICKNAMES",
	"MANAGE_ROLES",
	"MANAGE_WEBHOOKS",
	"MANAGE_EMOJIS_AND_STICKERS",
	"USE_APPLICATION_COMMANDS",
	"REQUEST_TO_SPEAK",
	"MANAGE_EVENTS",
	"MANAGE_THREADS",
	"USE_PUBLIC_THREADS",
	"CREATE_PUBLIC_THREADS",
	"USE_PRIVATE_THREADS",
	"CREATE_PRIVATE_THREADS",
	"USE_EXTERNAL_STICKERS",
	"SEND_MESSAGES_IN_THREADS",
	"START_EMBEDDED_ACTIVITIES",
	"MODERATE_MEMBERS",
	"VIEW_CREATOR_MONETIZATION_ANALYTICS",
	"USE_SOUNDBOARD",
	"SEND_VOICE_MESSAGES"
].sort();

var timezone = moment.tz.guess();
export const CLEAN_BOT = {
	name: "",
	img: "",
	prefix: ".",
	id: 0,
	status: "",
	statusopt: {
		type: "LISTENING"
	},
	token: "",
	validated: false,
	servers: [],
	subscription: { id: 0, sub_type: "trial", end: null, trial_end: false },
	extended: false,
	lang: "en",
	presence_intent: "ALWAYS",
	message_intent: "ALWAYS",
	server_members_intent: "ALWAYS",
	commands: {
		moduleSettings: {},
		webhooks: {
			enabled: false,
			webhooks: []
		},
		settings: {
			delete: {
				enabled: false
			},
			mention: {
				enabled: false
			},
			errorlogs: {
				enabled: false,
				channel_name: "System Errors"
			},
			embeds: {
				enabled: false,
				colors: {
					main: "#00ffff",
					modlogs: "#00ffff",
					warning: "#ff00c8",
					music: "#00ffff",
					games: "#00ffff"
				}
			}
		},
		variables: {
			variables: []
		},
		custom_events: {
			events: []
		},
		timed_events: {
			timezone: timezone,
			timezone_default: true,
			events: [
				// {
				// 	id: "123456",
				// 	name: "Test Event",
				// 	type: "schedule",
				// 	time: {
				// 		schedules: ["14:15:00", "14:16:00", "14:17:00", "14:31:00"]
				// 	},
				// 	days: {
				// 		mon: true,
				// 		tue: true,
				// 		wed: true,
				// 		thu: true,
				// 		fri: true,
				// 		sat: true,
				// 		sun: true
				// 	}
				// }
			]
		},
		moderation: {
			ban: {
				enabled: false,
				permissions: {
					allowed_roles: [],
					banned_roles: [],
					banned_channels: [],
					banned_users: [],
					required_permissions: ["ADMINISTRATOR"]
				}
			},
			unban: {
				enabled: false,
				permissions: {
					allowed_roles: [],
					banned_roles: [],
					banned_channels: [],
					banned_users: [],
					required_permissions: ["ADMINISTRATOR"]
				}
			},
			kick: {
				enabled: false,
				permissions: {
					allowed_roles: [],
					banned_roles: [],
					banned_channels: [],
					banned_users: [],
					required_permissions: ["ADMINISTRATOR"]
				}
			},
			timeout: {
				enabled: false,
				permissions: {
					allowed_roles: [],
					banned_roles: [],
					banned_channels: [],
					banned_users: [],
					required_permissions: ["ADMINISTRATOR"]
				}
			},
			removetimeout: {
				enabled: false,
				permissions: {
					allowed_roles: [],
					banned_roles: [],
					banned_channels: [],
					banned_users: [],
					required_permissions: ["ADMINISTRATOR"]
				}
			},
			warn: {
				enabled: false,
				permissions: {
					allowed_roles: [],
					banned_roles: [],
					banned_channels: [],
					banned_users: [],
					required_permissions: ["ADMINISTRATOR"]
				}
			},
			warnings: {
				enabled: false,
				permissions: {
					allowed_roles: [{ name: "@everyone", id: "everyone" }],
					banned_roles: [],
					banned_users: [],
					banned_channels: [],
					required_permissions: []
				}
			},
			clearwarnings: {
				enabled: false,
				permissions: {
					allowed_roles: [],
					banned_roles: [],
					banned_channels: [],
					banned_users: [],
					required_permissions: ["ADMINISTRATOR"]
				}
			},
			say: {
				enabled: false,
				permissions: {
					allowed_roles: [],
					banned_roles: [],
					banned_users: [],
					banned_channels: [],
					required_permissions: ["ADMINISTRATOR"]
				}
			},
			addrole: {
				enabled: false,
				permissions: {
					allowed_roles: [],
					banned_roles: [],
					banned_channels: [],
					banned_users: [],
					required_permissions: ["ADMINISTRATOR"]
				}
			},
			removerole: {
				enabled: false,
				permissions: {
					allowed_roles: [],
					banned_roles: [],
					banned_channels: [],
					banned_users: [],
					required_permissions: ["ADMINISTRATOR"]
				}
			},
			mute: {
				enabled: false,
				permissions: {
					allowed_roles: [],
					banned_roles: [],
					banned_channels: [],
					banned_users: [],
					required_permissions: ["ADMINISTRATOR"]
				}
			},
			unmute: {
				enabled: false,
				permissions: {
					allowed_roles: [],
					banned_roles: [],
					banned_channels: [],
					banned_users: [],
					required_permissions: ["ADMINISTRATOR"]
				}
			},
			softban: {
				enabled: false,
				permissions: {
					allowed_roles: [],
					banned_roles: [],
					banned_channels: [],
					banned_users: [],
					required_permissions: ["ADMINISTRATOR"]
				}
			},
			purge: {
				enabled: false,
				permissions: {
					allowed_roles: [],
					banned_roles: [],
					banned_channels: [],
					banned_users: [],
					required_permissions: ["ADMINISTRATOR"]
				}
			},
			ping: {
				enabled: false,
				permissions: {
					allowed_roles: [{ name: "@everyone", id: "everyone" }],
					banned_users: [],
					banned_roles: [],
					banned_channels: [],
					required_permissions: []
				}
			},
			invite: {
				enabled: false,
				permissions: {
					allowed_roles: [{ name: "@everyone", id: "everyone" }],
					banned_users: [],
					banned_roles: [],
					banned_channels: [],
					required_permissions: []
				}
			},
			help: {
				enabled: false,
				permissions: {
					allowed_roles: [{ name: "@everyone", id: "everyone" }],
					banned_users: [],
					banned_roles: [],
					banned_channels: [],
					required_permissions: []
				}
			},
			afk: {
				enabled: false,
				permissions: {
					allowed_roles: [{ name: "@everyone", id: "everyone" }],
					banned_users: [],
					banned_roles: [],
					banned_channels: [],
					required_permissions: []
				}
			},
			suggest: {
				enabled: false,
				permissions: {
					allowed_roles: [{ name: "@everyone", id: "everyone" }],
					banned_users: [],
					banned_users: [],
					banned_roles: [],
					banned_channels: [],
					required_permissions: []
				}
			},
			status: {
				enabled: false,
				permissions: {
					allowed_roles: [{ name: "@everyone", id: "everyone" }],
					banned_users: [],
					banned_roles: [],
					banned_channels: [],
					required_permissions: []
				}
			},
			report: {
				enabled: false,
				permissions: {
					allowed_roles: [{ name: "@everyone", id: "everyone" }],
					banned_users: [],
					banned_roles: [],
					banned_channels: [],
					required_permissions: []
				}
			},

			avatar: {
				enabled: false,
				permissions: {
					allowed_roles: [{ name: "@everyone", id: "everyone" }],
					banned_roles: [],
					banned_channels: [],
					banned_users: [],
					required_permissions: ["ADMINISTRATOR"]
				}
			},

			deafen: {
				enabled: false,
				permissions: {
					allowed_roles: [{ name: "@everyone", id: "everyone" }],
					banned_roles: [],
					banned_channels: [],
					banned_users: [],
					required_permissions: ["ADMINISTRATOR"]
				}
			},

			move: {
				enabled: false,
				permissions: {
					allowed_roles: [{ name: "@everyone", id: "everyone" }],
					banned_roles: [],
					banned_channels: [],
					banned_users: [],
					required_permissions: ["ADMINISTRATOR"]
				}
			},

			slowmode: {
				enabled: false,
				permissions: {
					allowed_roles: [{ name: "@everyone", id: "everyone" }],
					banned_roles: [],
					banned_channels: [],
					banned_users: [],
					required_permissions: ["ADMINISTRATOR"]
				}
			},

			undeafen: {
				enabled: false,
				permissions: {
					allowed_roles: [{ name: "@everyone", id: "everyone" }],
					banned_roles: [],
					banned_channels: [],
					banned_users: [],
					required_permissions: ["ADMINISTRATOR"]
				}
			},

			vkick: {
				enabled: false,
				permissions: {
					allowed_roles: [{ name: "@everyone", id: "everyone" }],
					banned_roles: [],
					banned_channels: [],
					banned_users: [],
					required_permissions: ["ADMINISTRATOR"]
				}
			},

			vmute: {
				enabled: false,
				permissions: {
					allowed_roles: [{ name: "@everyone", id: "everyone" }],
					banned_roles: [],
					banned_channels: [],
					banned_users: [],
					required_permissions: ["ADMINISTRATOR"]
				}
			},

			vunmute: {
				enabled: false,
				permissions: {
					allowed_roles: [{ name: "@everyone", id: "everyone" }],
					banned_roles: [],
					banned_channels: [],
					banned_users: [],
					required_permissions: ["ADMINISTRATOR"]
				}
			}
		},
		autoresponder: [],
		crypto: {
			enabled: false,
			price: {
				enabled: false,
				permissions: { allowed_roles: [{ name: "@everyone", id: "everyone" }], banned_roles: [], banned_channels: [], banned_users: [], required_permissions: [] }
			}
		},
		opensea_alerts: {
			enabled: false,
			alerts: []
		},
		opensea: {
			enabled: false,
			collection_slug: "",
			opensea: {
				trigger: "opensea"
			},
			item: {
				enabled: false,
				permissions: { allowed_roles: [{ name: "@everyone", id: "everyone" }], banned_roles: [], banned_channels: [], banned_users: [], required_permissions: [] }
			},
			collection: {
				enabled: false,
				permissions: { allowed_roles: [{ name: "@everyone", id: "everyone" }], banned_roles: [], banned_channels: [], banned_users: [], required_permissions: [] }
			},
			floor: {
				enabled: false,
				permissions: { allowed_roles: [{ name: "@everyone", id: "everyone" }], banned_roles: [], banned_channels: [], banned_users: [], required_permissions: [] }
			}
		},
		tickets: {
			enabled: false,
			trigger: "tickets", // trigger for the main command
			adminTrigger: "tickets-admin",
			options: {
				// holds all the options for the ticket system, https://sherlock.sloththe.dev/files/MongoDBCompass_pocs89NyDC.png
				pingRoles: false, // whether to ping roles or not upon ticket creation
				roles: [], // roles you want to be given access to the ticket
				// {
				// 	name: "",
				// 	id: ""
				// }
				created_message: "Thank you for creating a ticket!",
				category: {
					// category_id:"998128950721126420"
				}, // category
				// {
				// 	name: "",
				// 	category_id: "",
				// 	open_message: ""
				// }
				logEvents: false, // log events to ticket log channel
				logChannel: {},
				transcript: true,
				categories: [
					// {
					//     name:"Sales",
					//     category_id:"998229212018253825",
					//     roles:[]
					// }
				],
				// {
				// 	name: "Sales",
				// 	category_id: "",
				//	roles: []
				// }
				modal: {
					title: "Log a Ticket",
					description: "Why are you logging a ticket"
				},
				blacklistUsers: [],
				blacklistRoles: []
			},
			create: {
				enabled: false,
				permissions: { allowed_roles: [{ name: "@everyone", id: "everyone" }], banned_roles: [], banned_channels: [], banned_users: [], required_permissions: [] }
			},
			close: {
				enabled: false,
				permissions: { allowed_roles: [{ name: "@everyone", id: "everyone" }], banned_roles: [], banned_channels: [], banned_users: [], required_permissions: [] }
			},
			addUser: {
				enabled: false,
				permissions: { allowed_roles: [{ name: "@everyone", id: "everyone" }], banned_roles: [], banned_channels: [], banned_users: [], required_permissions: [] }
			},
			removeUser: {
				enabled: false,
				permissions: { allowed_roles: [{ name: "@everyone", id: "everyone" }], banned_roles: [], banned_channels: [], banned_users: [], required_permissions: [] }
			},
			createPanel: {
				enabled: false,
				permissions: { allowed_roles: [{ name: "@everyone", id: "everyone" }], banned_roles: [], banned_channels: [], banned_users: [], required_permissions: ["ADMINISTRATOR"] }
			},
			addBlacklist: {
				enabled: false,
				permissions: { allowed_roles: [{ name: "@everyone", id: "everyone" }], banned_roles: [], banned_channels: [], banned_users: [], required_permissions: ["ADMINISTRATOR"] }
			},
			removeBlacklist: {
				enabled: false,
				permissions: { allowed_roles: [{ name: "@everyone", id: "everyone" }], banned_roles: [], banned_channels: [], banned_users: [], required_permissions: ["ADMINISTRATOR"] }
			}
		},
		giveaways: {
			enabled: false,
			buttons: false,
			buttonText: "",
			giveaway: {
				permissions: {
					allowed_roles: [
						{
							name: "@everyone",
							id: "everyone"
						}
					],
					banned_users: [],
					banned_roles: [],
					banned_channels: [],
					required_permissions: ["ADMINISTRATOR"]
				}
			}
		},
		responder: {
			enabled: false,
			cooldown: 30,
			messages: [],
			autoresponder: {
				enabled: false,
				permissions: {
					allowed_roles: [],
					banned_roles: [],
					banned_channels: [],
					banned_users: [],
					required_permissions: ["ADMINISTRATOR"]
				}
			}
		},
		status: {
			enabled: false,
			duration: 10,
			statuses: []
		},
		automod: {
			enabled: false,
			automod: {
				enabled: false,
				logging: {
					enabled: false,
					channel: "automod-logs"
				},
				options: {
					blacklist: {
						words: [],
						action: "disabled",
						ignored_channels: [],
						ignored_roles: []
					},
					whitelist: {
						words: []
					},
					links: {
						action: "disabled",
						ignored_channels: [],
						ignored_roles: []
					},
					caps: {
						action: "disabled",
						ignored_channels: [],
						ignored_roles: []
					},
					emoji: {
						allowed: 8,
						action: "disabled",
						ignored_channels: [],
						ignored_roles: []
					},
					server_invites: {
						allowed: 5,
						action: "disabled",
						ignored_channels: [],
						ignored_roles: []
					},
					mentions: {
						allowed: 5,
						action: "disabled",
						ignored_channels: [],
						ignored_roles: []
					},
					ignore_bots: true,
					ignore_admins: true,
					dm: false,
					empty: "false"
				}
			},
			"white-list": {
				allowed_roles: [],
				banned_roles: [],
				banned_channels: [],
				banned_users: [],
				required_permissions: ["ADMINISTRATOR"]
			},
			"black-list": {
				allowed_roles: [],
				banned_roles: [],
				banned_channels: [],
				banned_users: [],
				required_permissions: ["ADMINISTRATOR"]
			}
		},
		announcements: {
			join: [],
			ban: [],
			leave: []
		},
		logging: {
			mod: false,
			messageDelete: {
				enabled: false,
				channel: ""
			},
			messageUpdate: {
				enabled: false,
				channel: ""
			},
			messagePinned: {
				enabled: false,
				channel: ""
			},
			guildUpdate: {
				enabled: false,
				channel: ""
			},
			guildMemberJoin: {
				enabled: false,
				channel: ""
			},
			guildMemberRemove: {
				enabled: false,
				channel: ""
			},
			guildMemberUpdate: {
				enabled: false,
				channel: ""
			},
			guildEventCreate: {
				enabled: false,
				channel: ""
			},
			guildEventDelete: {
				enabled: false,
				channel: ""
			},
			guildEventUpdate: {
				enabled: false,
				channel: ""
			},
			memberRoleAdd: {
				enabled: false,
				channel: ""
			},
			memberRoleRemove: {
				enabled: false,
				channel: ""
			},
			memberNicknameUpdate: {
				enabled: false,
				channel: ""
			},
			userAvatarUpdate: {
				enabled: false,
				channel: ""
			},
			userUsernameUpdate: {
				enabled: false,
				channel: ""
			},
			userDiscriminatorUpdate: {
				enabled: false,
				channel: ""
			},
			channelCreate: {
				enabled: false,
				channel: ""
			},
			channelRemove: {
				enabled: false,
				channel: ""
			},
			channelUpdate: {
				enabled: false,
				channel: ""
			},
			roleCreate: {
				enabled: false,
				channel: ""
			},
			roleDelete: {
				enabled: false,
				channel: ""
			},
			roleUpdate: {
				enabled: false,
				channel: ""
			},
			emojiCreate: {
				enabled: false,
				channel: ""
			},
			emojiDelete: {
				enabled: false,
				channel: ""
			},
			emojiUpdate: {
				enabled: false,
				channel: ""
			},
			threadCreate: {
				enabled: false,
				channel: ""
			},
			threadDelete: {
				enabled: false,
				channel: ""
			},
			threadUpdate: {
				enabled: false,
				channel: ""
			},
			vcJoin: {
				enabled: false,
				channel: ""
			},
			vcLeave: {
				enabled: false,
				channel: ""
			},
			vcSwitch: {
				enabled: false,
				channel: ""
			},
			vcMute: {
				enabled: false,
				channel: ""
			},
			vcUnMute: {
				enabled: false,
				channel: ""
			},
			vcDeafen: {
				enabled: false,
				channel: ""
			},
			vcUnDeafen: {
				enabled: false,
				channel: ""
			},
			guildBoostLevelUp: {
				enabled: false,
				channel: ""
			},
			guildBoostLevelDown: {
				enabled: false,
				channel: ""
			},
			guildPartnerAdd: {
				enabled: false,
				channel: ""
			},
			guildPartnerRemove: {
				enabled: false,
				channel: ""
			},
			guildMemberBoost: {
				enabled: false,
				channel: ""
			},
			guildMemberUnBoost: {
				enabled: false,
				channel: ""
			},
			guildBanAdd: {
				enabled: false,
				channel: ""
			}
		},
		statsChannels: {
			enabled: false,
			channels: {
				textChannel: {
					enabled: false
				},
				voiceChannel: {
					enabled: false
				},
				//   "humanChannel": {
				//       "enabled": false,
				//   },
				//   "botChannel": {
				//       "enabled": false,
				//   },
				membersChannel: {
					enabled: false
				},
				roleChannel: {
					enabled: false
				},
				emojiChannel: {
					enabled: false
				},
				categoryChannel: {
					enabled: false
				}
			},
			category_name: "Stats Channels"
		},
		music: {
			enabled: false,
			key: "",
			play: {
				permissions: {
					allowed_roles: [{ name: "@everyone", id: "everyone" }],
					banned_users: [],
					banned_roles: [],
					banned_channels: [],
					required_permissions: []
				}
			},
			pause: {
				permissions: {
					allowed_roles: [{ name: "@everyone", id: "everyone" }],
					banned_users: [],
					banned_roles: [],
					banned_channels: [],
					required_permissions: []
				}
			},
			skip: {
				permissions: {
					allowed_roles: [{ name: "@everyone", id: "everyone" }],
					banned_users: [],
					banned_roles: [],
					banned_channels: [],
					required_permissions: []
				}
			},
			stop: {
				permissions: {
					allowed_roles: [{ name: "@everyone", id: "everyone" }],
					banned_users: [],
					banned_roles: [],
					banned_channels: [],
					required_permissions: []
				}
			},
			np: {
				permissions: {
					allowed_roles: [{ name: "@everyone", id: "everyone" }],
					banned_users: [],
					banned_roles: [],
					banned_channels: [],
					required_permissions: []
				}
			},
			queue: {
				permissions: {
					allowed_roles: [{ name: "@everyone", id: "everyone" }],
					banned_users: [],
					banned_roles: [],
					banned_channels: [],
					required_permissions: []
				}
			},
			resume: {
				permissions: {
					allowed_roles: [{ name: "@everyone", id: "everyone" }],
					banned_users: [],
					banned_roles: [],
					banned_channels: [],
					required_permissions: []
				}
			},
			volume: {
				permissions: {
					allowed_roles: [{ name: "@everyone", id: "everyone" }],
					banned_users: [],
					banned_roles: [],
					banned_channels: [],
					required_permissions: []
				}
			},
			wipequeue: {
				permissions: {
					allowed_roles: [
						{
							name: "@everyone",
							id: "everyone"
						}
					],
					banned_users: [],
					banned_roles: [],
					banned_channels: [],
					required_permissions: []
				}
			},
			connect: {
				premissions: {
					allowed_roles: [
						{
							name: "@everyone",
							id: "everyone"
						}
					],
					banned_users: [],
					banned_roles: [],
					banned_channels: [],
					required_permissions: []
				}
			},
			disconnect: {
				permissions: {
					allowed_roles: [
						{
							name: "@everyone",
							id: "everyone"
						}
					],
					banned_users: [],
					banned_roles: [],
					banned_channels: [],
					required_permissions: []
				}
			},
			loop: {
				permissions: {
					allowed_roles: [
						{
							name: "@everyone",
							id: "everyone"
						}
					],
					banned_users: [],
					banned_roles: [],
					banned_channels: [],
					required_permissions: []
				}
			},
			remove: {
				premissions: {
					allowed_roles: [
						{
							name: "@everyone",
							id: "everyone"
						}
					],
					banned_users: [],
					banned_roles: [],
					banned_channels: [],
					required_permissions: []
				}
			},
			seek: {
				premissions: {
					allowed_roles: [
						{
							name: "@everyone",
							id: "everyone"
						}
					],
					banned_users: [],
					banned_roles: [],
					banned_channels: [],
					required_permissions: []
				}
			},
			forward: {
				permissions: {
					allowed_roles: [
						{
							name: "@everyone",
							id: "everyone"
						}
					],
					banned_users: [],
					banned_roles: [],
					banned_channels: [],
					required_permissions: []
				}
			},
			shuffle: {
				permissions: {
					allowed_roles: [
						{
							name: "@everyone",
							id: "everyone"
						}
					],
					banned_users: [],
					banned_roles: [],
					banned_channels: [],
					required_permissions: []
				}
			},
			dmsong: {
				permissions: {
					allowed_roles: [
						{
							name: "@everyone",
							id: "everyone"
						}
					],
					banned_users: [],
					banned_roles: [],
					banned_channels: [],
					required_permissions: []
				}
			},
			filter: {
				permissions: {
					allowed_roles: [
						{
							name: "@everyone",
							id: "everyone"
						}
					],
					banned_users: [],
					banned_roles: [],
					banned_channels: [],
					required_permissions: []
				}
			}
		},
		twitch: [],
		games: {
			fortnite: {
				enabled: false,
				key: "",
				permissions: {
					allowed_roles: [{ name: "@everyone", id: "everyone" }],
					banned_users: [],
					banned_roles: [],
					banned_channels: [],
					required_permissions: []
				}
			},
			apex: {
				enabled: false,
				key: "",
				permissions: {
					allowed_roles: [{ name: "@everyone", id: "everyone" }],
					banned_users: [],
					banned_roles: [],
					banned_channels: [],
					required_permissions: []
				}
			}
		},
		utils: {
			weather: {
				enabled: false,
				permissions: {
					allowed_roles: [{ name: "@everyone", id: "everyone" }],
					banned_users: [],
					banned_roles: [],
					banned_channels: [],
					required_permissions: []
				}
			},
			translate: {
				enabled: false,
				permissions: {
					allowed_roles: [{ name: "@everyone", id: "everyone" }],
					banned_users: [],
					banned_roles: [],
					banned_channels: [],
					required_permissions: []
				}
			}
		},
		economy: {
			enabled: false,
			currency_prefix: "$",
			currency_name: "Dollars",
			points_timeout: 2,
			points_per_chat: 100,
			roles: [],
			daily_points: 100,
			shop: {
				items: [
					{
						name: "Apple Pie",
						description: "A delicious apple pie!",
						id: "kyzf5uypz70npq61z2r",
						img: "https://ik.imagekit.io/botghost/kyzf5h3gwzcdgriaqdq_0Mq7RfaZa.png",
						type: "static",
						price: 50,
						multiple: true,
						useOnBuy: false,
						destroyOnUse: false,
						buyMessage: {
							type: "text",
							content: "You just bought some apple pie!"
						},
						responses: [
							{
								type: "text",
								content: ""
							}
						],
						roles: []
					},
					{
						name: "Burger",
						description: "Nothing better than a juicy burger.",
						id: "kyzf8n0zqq36h4usxil",
						img: "https://ik.imagekit.io/botghost/kyzf8tjpn9h1mukftp_KLXWL-QoL.png",
						type: "static",
						price: "200",
						multiple: true,
						useOnBuy: false,
						destroyOnUse: false,
						buyMessage: {
							type: "text",
							content: "You just bought a burger!"
						},
						responses: [
							{
								type: "text",
								content: ""
							}
						],
						roles: []
					},
					{
						name: "Giant Gummy Bear",
						description: "Why is it so big?",
						id: "kyzfann7hylked6za27",
						img: "https://ik.imagekit.io/botghost/kyzfakcodamgo8q63a6_j9Jzu5TvG.png",
						type: "static",
						price: "500",
						multiple: false,
						useOnBuy: false,
						destroyOnUse: false,
						buyMessage: {
							type: "text",
							content: "Lets hope this fits in your inventory."
						},
						responses: [
							{
								type: "text",
								content: ""
							}
						],
						roles: []
					},
					{
						name: "Waffles",
						description: "Waffles!",
						id: "kyzfbhi96oi24tw5afq",
						img: "https://ik.imagekit.io/botghost/kyzfbbpw74gtcmj5xlb_eM-ptgvjZ.png",
						type: "static",
						price: "1000",
						multiple: true,
						useOnBuy: false,
						destroyOnUse: false,
						buyMessage: {
							type: "text",
							content: "Waffles!"
						},
						responses: [
							{
								type: "text",
								content: ""
							}
						],
						roles: []
					},
					{
						name: "Icecream",
						description: "ICECREAM",
						id: "kyzfki0ehsm81mbskda",
						img: "https://ik.imagekit.io/botghost/kyzfkaquw9l0zztu8rb_6Uy2VsD7I.png",
						type: "static",
						price: "5000",
						multiple: true,
						useOnBuy: false,
						destroyOnUse: false,
						buyMessage: {
							type: "text",
							content: "ICECREAM"
						},
						responses: [
							{
								type: "text",
								content: ""
							}
						],
						roles: []
					}
				]
			},
			give: {
				permissions: {
					allowed_roles: [{ name: "@everyone", id: "everyone" }],
					banned_roles: [],
					banned_channels: [],
					banned_users: [],
					required_permissions: []
				}
			},
			"add-money": {
				permissions: {
					allowed_roles: [],
					banned_roles: [],
					banned_channels: [],
					banned_users: [],
					required_permissions: ["ADMINISTRATOR"]
				}
			},
			"remove-money": {
				permissions: {
					allowed_roles: [],
					banned_roles: [],
					banned_channels: [],
					banned_users: [],
					required_permissions: ["ADMINISTRATOR"]
				}
			},
			"check-balance": {
				permissions: {
					allowed_roles: [{ name: "@everyone", id: "everyone" }],
					banned_roles: [],
					banned_channels: [],
					banned_users: [],
					required_permissions: []
				}
			},
			daily: {
				permissions: {
					allowed_roles: [{ name: "@everyone", id: "everyone" }],
					banned_roles: [],
					banned_channels: [],
					banned_users: [],
					required_permissions: []
				}
			},
			leaderboard: {
				permissions: {
					allowed_roles: [{ name: "@everyone", id: "everyone" }],
					banned_roles: [],
					banned_channels: [],
					banned_users: [],
					required_permissions: []
				}
			},
			slotmachine: {
				permissions: {
					allowed_roles: [{ name: "@everyone", id: "everyone" }],
					banned_roles: [],
					banned_channels: [],
					banned_users: [],
					required_permissions: []
				}
			},
			viewshop: {
				enabled: false,
				permissions: {
					allowed_roles: [],
					banned_roles: [],
					banned_channels: [],
					banned_users: [],
					required_permissions: []
				}
			},
			buy: {
				enabled: false,
				permissions: {
					allowed_roles: [],
					banned_roles: [],
					banned_channels: [],
					banned_users: [],
					required_permissions: []
				}
			},
			inventory: {
				enabled: false,
				permissions: {
					allowed_roles: [],
					banned_roles: [],
					banned_channels: [],
					banned_users: [],
					required_permissions: []
				}
			},
			use: {
				enabled: false,
				permissions: {
					allowed_roles: [],
					banned_roles: [],
					banned_channels: [],
					banned_users: [],
					required_permissions: []
				}
			},
			giveitem: {
				enabled: false,
				permissions: {
					allowed_roles: [],
					banned_roles: [],
					banned_channels: [],
					banned_users: [],
					required_permissions: []
				}
			},
			econadmin: {
				enabled: false,
				permissions: {
					allowed_roles: [],
					banned_roles: [],
					banned_channels: [],
					banned_users: [],
					required_permissions: ["ADMINISTRATOR"]
				}
			}
		},
		reddit: {
			enabled: false,
			subreddits: []
		},
		twitter: {
			enabled: false,
			tracking: []
		},
		fun: {
			trivia: { enabled: false }
		},
		youtube: {
			enabled: false,
			tracking: []
		},
		autorole: {
			enabled: false,
			roles: []
		},
		membershipScreening: {
			enabled: false,
			roles: []
		},
		messenger: {
			messages: [],
			enabled: false
		},
		activeDevBadge: {
			enabled: false
		},
		slash: {
			commands: []
		},
		search: {
			youtube: { enabled: false },
			twitch: { enabled: false },
			google: { enabled: false },
			imgur: { enabled: false },
			urban: { enabled: false },
			giphy: { enabled: false }
		},
		reaction_roles: {
			enabled: false,
			roles: []
		},
		levels: {
			enabled: false,
			levels: {
				enabled: false,
				permissions: {
					allowed_roles: [],
					banned_roles: [],
					banned_channels: [],
					banned_users: [],
					required_permissions: []
				}
			},
			level: {
				enabled: false,
				permissions: {
					allowed_roles: [],
					banned_roles: [],
					banned_channels: [],
					banned_users: [],
					required_permissions: ["ADMINISTRATOR"]
				}
			},
			options: {
				exp_rate: 1,
				level_up: {
					type: "current_channel",
					messages: []
				},
				no_exp_channels: [],
				no_exp_roles: [],
				roles: []
			}
		},
		cocreate: {
			enabled: false,
			cocreators: [],
			invite: ""
		},
		advertise: {
			enabled: false,
			short_description: "",
			long_description: "",
			extra_tags: [],
			support_server: ""
		},
		meme_creator: {
			enabled: false,
			memes: {
				permissions: {
					allowed_roles: [{ name: "@everyone", id: "everyone" }],
					banned_roles: [],
					banned_channels: [],
					banned_users: [],
					required_permissions: []
				}
			},
			create_meme: {
				permissions: {
					allowed_roles: [{ name: "@everyone", id: "everyone" }],
					banned_roles: [],
					banned_channels: [],
					banned_users: [],
					required_permissions: []
				}
			}
		}
	},
	premium: false,
	expired: false,
	premium_count: 0,
	online: false,
	alerts: [],
	trial: false
};

export const CUSTOM_EVENT_CATEGORIES = [
	{
		value: "member",
		label: "Member Events",
		children: []
	},
	{
		value: "channel",
		label: "Channel Events",
		children: []
	},
	{
		value: "threads",
		label: "Thread Events",
		children: []
	},
	{
		value: "events",
		label: "Scheduled Events Events",
		children: []
	},
	{
		value: "message",
		label: "Message Events",
		children: []
	},
	{
		value: "role",
		label: "Role Events",
		children: []
	},
	{
		value: "voice",
		label: "Voice Events",
		children: []
	},
	{
		value: "reaction",
		label: "Reaction Events",
		children: []
	},
	{
		value: "invite",
		label: "Invite Events",
		children: []
	},
	{
		value: "boost",
		label: "Boost Events",
		children: []
	},
	{
		value: "poll",
		label: "Poll Events",
		children: []
	},
	{
		value: "bot",
		label: "Bot Events",
		children: []
	},
	{
		value: "ifttt",
		label: "IFTTT Action Events",
		children: []
	}
];

export const CUSTOM_EVENTS = [
	{
		value: "IFTTTActionEvent",
		label: "When an IFTTT Action is executed",
		event_category: "ifttt",
		show: false,
		server_required: true,
		components: {
			Guild: true,
			User: false,
			Member: false,
			variables: [
				{
					variable: "{ifttt_variable1}",
					name: "IFTTT Event Variable 1"
				},
				{
					variable: "{ifttt_variable2}",
					name: "IFTTT Event Variable 2"
				},
				{
					variable: "{ifttt_variable3}",
					name: "IFTTT Event Variable 3"
				},
				{
					variable: "{ifttt_variable4}",
					name: "IFTTT Event Variable 4"
				},
				{
					variable: "{ifttt_variable5}",
					name: "IFTTT Event Variable 5"
				},
				{
					variable: "{ifttt_variable6}",
					name: "IFTTT Event Variable 6"
				},
				{
					variable: "{ifttt_variable7}",
					name: "IFTTT Event Variable 7"
				},
				{
					variable: "{ifttt_variable8}",
					name: "IFTTT Event Variable 8"
				},
				{
					variable: "{ifttt_variable9}",
					name: "IFTTT Event Variable 9"
				},
				{
					variable: "{ifttt_variable10}",
					name: "IFTTT Event Variable 10"
				}
			]
		}
	},
	{
		value: "webhookExecuted",
		label: "When a webhook is executed",
		event_category: "webhook",
		show: false,
		server_required: true,
		components: {
			Guild: true,
			User: false,
			Member: false,
			variables: []
		}
	},
	{
		value: "timedEvent",
		label: "When a timed event is executed",
		event_category: "timed_event",
		show: false,
		server_required: true,
		components: {
			Guild: true,
			User: false,
			Member: false,
			variables: []
		}
	},
	{
		value: "serverJoin",
		label: "When the bot joins a server",
		event_category: "bot",

		components: {
			Guild: true,
			User: false,
			Member: false,
			variables: []
		}
	},
	{
		value: "botRestart",
		label: "When the bot starts or is restarted",
		event_category: "bot",

		server_required: true,
		components: {
			Guild: true,
			User: false,
			Member: false,
			variables: []
		}
	},
	{
		value: "customVariableChange",
		label: "When a custom variable's value changes",

		event_category: "bot",
		components: {
			Guild: true,
			User: false,
			Member: false,
			variables: [
				{
					variable: "{event_variable_old_value}",
					name: "variable old value"
				},
				{
					variable: "{event_variable_new_value}",
					name: "variable new value"
				},
				{
					variable: "{event_variable_name}",
					name: "variable name"
				},
				{
					variable: "{event_variable_id}",
					name: "variable specific id"
				},
				{
					variable: "{event_variable_reference}",
					name: "variable reference"
				}
			]
		}
	},
	{
		value: "inviteCreate",
		label: "When an invite is created",

		event_category: "invite",
		components: {
			Guild: true,
			User: true,
			Channel: true,
			variables: [
				{
					variable: "{invite_code}",
					name: "invite code"
				},
				{
					variable: "{invite_url}",
					name: "invite url"
				},
				{
					variable: "{invite_max_age}",
					name: "invite max age"
				},
				{
					variable: "{invite_max_uses}",
					name: "invite max uses"
				},
				{
					variable: "{invite_created_at}",
					name: "invite created at"
				},

				{
					variable: "{invite_uses}",
					name: "invite uses"
				},
				{
					variable: "{invite_expires_at}",
					name: "invite expires at"
				}
			]
		}
	},
	{
		value: "messagePollVoteAdd",
		label: "When a user votes on a poll",

		event_category: "poll",
		components: {
			Guild: false,
			User: true,
			Channel: false,
			variables: [
				{
					variable: "{event_poll_answer_id}",
					name: "poll answer id"
				},
				{
					variable: "{event_user_id}",
					name: "user id"
				},
				{
					variable: "{event_poll_answer_emoji}",
					name: "poll answer emoji"
				},
				{
					variable: "{event_poll_answer_text}",
					name: "poll answer text"
				},
				{
					variable: "{event_poll_answer_vote_count}",
					name: "poll answer vote count"
				}
			]
		}
	},
	{
		value: "messagePollVoteRemove",
		label: "When a user removes their vote on a poll",

		event_category: "poll",
		components: {
			Guild: false,
			User: true,
			Channel: false,
			variables: [
				{
					variable: "{event_poll_answer_id}",
					name: "poll answer id"
				},
				{
					variable: "{event_user_id}",
					name: "user id"
				},
				{
					variable: "{event_poll_answer_emoji}",
					name: "poll answer emoji"
				},
				{
					variable: "{event_poll_answer_text}",
					name: "poll answer text"
				},
				{
					variable: "{event_poll_answer_vote_count}",
					name: "poll answer vote count"
				}
			]
		}
	},
	{
		value: "inviteDelete",
		label: "When an invite is deleted",

		event_category: "invite",
		components: {
			Guild: true,
			User: true,
			Channel: true,
			variables: [
				{
					variable: "{invite_code}",
					name: "invite code"
				},
				{
					variable: "{invite_url}",
					name: "invite url"
				},
				{
					variable: "{invite_max_age}",
					name: "invite max age"
				},
				{
					variable: "{invite_max_uses}",
					name: "invite max uses"
				},
				{
					variable: "{invite_created_at}",
					name: "invite created at"
				},

				{
					variable: "{invite_uses}",
					name: "invite uses"
				},
				{
					variable: "{invite_expires_at}",
					name: "invite expires at"
				}
			]
		}
	},
	{
		value: "botUpdate",
		label: "When the bot is updated through the dashboard",
		event_category: "bot",
		server_required: true,
		components: {
			Guild: true,
			User: false,
			Member: false,
			variables: []
		}
	},
	{
		value: "guildScheduledEventCreate",
		label: "When a scheduled event is created",
		event_category: "events",
		server_required: true,
		components: {
			Guild: true,
			User: true,
			Member: false,
			variables: [
				{
					variable: "{event_id}",
					name: "event id"
				},
				{
					variable: "{event_name}",
					name: "event name"
				},
				{
					variable: "{event_description}",
					name: "event description"
				},
				{
					variable: "{event_url}",
					name: "event url"
				},
				{
					variable: "{event_status}",
					name: "event status"
				},
				{
					variable: "{event_createdAt}",
					name: "event created at"
				},
				{
					variable: "{event_createdTimestamp}",
					name: "event created timestamp"
				},
				{
					variable: "{event_scheduledEndTimestamp}",
					name: "event scheduled end timestamp"
				},
				{
					variable: "{event_image}",
					name: "event image"
				}
			]
		}
	},
	{
		value: "guildScheduledEventUpdate",
		label: "When a scheduled event is updated",
		event_category: "events",
		server_required: true,
		components: {
			Guild: true,
			User: true,
			Member: false,
			variables: [
				{
					variable: "{event_id}",
					name: "event id"
				},
				{
					variable: "{event_name}",
					name: "event name"
				},
				{
					variable: "{event_description}",
					name: "event description"
				},
				{
					variable: "{event_url}",
					name: "event url"
				},
				{
					variable: "{event_status}",
					name: "event status"
				},
				{
					variable: "{event_createdAt}",
					name: "event created at"
				},
				{
					variable: "{event_createdTimestamp}",
					name: "event created timestamp"
				},
				{
					variable: "{event_scheduledEndTimestamp}",
					name: "event scheduled end timestamp"
				},
				{
					variable: "{event_image}",
					name: "event image"
				}
			]
		}
	},
	{
		value: "guildScheduledEventDelete",
		label: "When a scheduled event is deleted",
		event_category: "events",
		server_required: true,
		components: {
			Guild: true,
			User: true,
			Member: false,
			variables: [
				{
					variable: "{event_id}",
					name: "event id"
				},
				{
					variable: "{event_name}",
					name: "event name"
				},
				{
					variable: "{event_description}",
					name: "event description"
				},
				{
					variable: "{event_url}",
					name: "event url"
				},
				{
					variable: "{event_status}",
					name: "event status"
				},
				{
					variable: "{event_createdAt}",
					name: "event created at"
				},
				{
					variable: "{event_createdTimestamp}",
					name: "event created timestamp"
				},
				{
					variable: "{event_scheduledEndTimestamp}",
					name: "event scheduled end timestamp"
				},
				{
					variable: "{event_image}",
					name: "event image"
				}
			]
		}
	},
	{
		value: "guildScheduledEventUserAdd",
		label: "When a user joins a scheduled event",
		event_category: "events",
		server_required: true,
		components: {
			Guild: true,
			User: true,
			Member: false,
			variables: [
				{
					variable: "{event_id}",
					name: "event id"
				},
				{
					variable: "{event_name}",
					name: "event name"
				},
				{
					variable: "{event_description}",
					name: "event description"
				},
				{
					variable: "{event_url}",
					name: "event url"
				},
				{
					variable: "{event_status}",
					name: "event status"
				},
				{
					variable: "{event_createdAt}",
					name: "event created at"
				},
				{
					variable: "{event_createdTimestamp}",
					name: "event created timestamp"
				},
				{
					variable: "{event_scheduledEndTimestamp}",
					name: "event scheduled end timestamp"
				},
				{
					variable: "{event_image}",
					name: "event image"
				}
			]
		}
	},
	{
		value: "guildScheduledEventUserRemove",
		label: "When a user leaves a scheduled event",
		event_category: "events",
		server_required: true,
		components: {
			Guild: true,
			User: true,
			Member: false,
			variables: [
				{
					variable: "{event_id}",
					name: "event id"
				},
				{
					variable: "{event_name}",
					name: "event name"
				},
				{
					variable: "{event_description}",
					name: "event description"
				},
				{
					variable: "{event_url}",
					name: "event url"
				},
				{
					variable: "{event_status}",
					name: "event status"
				},
				{
					variable: "{event_createdAt}",
					name: "event created at"
				},
				{
					variable: "{event_createdTimestamp}",
					name: "event created timestamp"
				},
				{
					variable: "{event_scheduledEndTimestamp}",
					name: "event scheduled end timestamp"
				},
				{
					variable: "{event_image}",
					name: "event image"
				}
			]
		}
	},
	{
		value: "guildMemberBoost",
		label: "When a member boosts the server for the first time",
		event_category: "boost",
		components: {
			Guild: true,
			User: true,
			Member: true,
			variables: [
				{
					variable: "{event_user_name}",
					name: "user name"
				},
				{
					variable: "{event_user_id}",
					name: "user id"
				},
				{
					variable: "{event_user_tag}",
					name: "user tag"
				},
				{
					variable: "{event_user_avatar}",
					name: "user avatar"
				},
				{
					variable: "{event_user_display_name}",
					name: "user display name"
				}
			]
		}
	},

	{
		value: "voiceChannelJoin",

		label: "When a member joins a voice channel",
		event_category: "voice",
		components: {
			Guild: true,
			User: true,
			Member: true,
			Channel: true,
			variables: [
				{
					variable: "{event_user_name}",
					name: "user name"
				},
				{
					variable: "{event_user_id}",
					name: "user id"
				},
				{
					variable: "{event_user_display_name}",
					name: "user display name"
				},
				{
					variable: "{event_channel_name}",
					name: "channel name"
				},
				{
					variable: "{event_channel_id}",
					name: "channel id"
				},
				{
					variable: "{event_channel_parent_id}",
					name: "channel parent id"
				},
				{
					variable: "{event_channel_user_limit}",
					name: "channel user limit"
				},
				{
					variable: "{event_channel_full}",
					name: "channel full"
				}
			]
		}
	},

	{
		value: "voiceChannelSwitch",

		label: "When a member switches voice channels",
		event_category: "voice",
		components: {
			Guild: true,
			User: true,
			Member: true,
			Channel: true,
			variables: [
				{
					variable: "{event_user_name}",
					name: "user name"
				},
				{
					variable: "{event_user_id}",
					name: "user id"
				},
				{
					variable: "{event_user_display_name}",
					name: "user display name"
				},
				{
					variable: "{event_old_channel_id}",
					name: "old channel id"
				},
				{
					variable: "{event_old_channel_name}",
					name: "old channel name"
				},
				{
					variable: "{event_old_channel_parent_id}",
					name: "old channel parent id"
				},
				{
					variable: "{event_old_channel_user_limit}",
					name: "old channel user limit"
				},
				{
					variable: "{event_old_channel_full}",
					name: "old channel full"
				},
				{
					variable: "{event_new_channel_id}",
					name: "new channel id"
				},
				{
					variable: "{event_new_channel_name}",
					name: "new channel name"
				},
				{
					variable: "{event_new_channel_parent_id}",
					name: "new channel parent id"
				},
				{
					variable: "{event_new_channel_user_limit}",
					name: "new channel user limit"
				},
				{
					variable: "{event_new_channel_full}",
					name: "new channel full"
				}
			]
		}
	},

	{
		value: "voiceChannelLeave",

		label: "When a member leaves a voice channel",
		event_category: "voice",
		components: {
			Guild: true,
			User: true,
			Member: true,
			Channel: true,
			variables: [
				{
					variable: "{event_user_name}",
					name: "user name"
				},
				{
					variable: "{event_user_id}",
					name: "user id"
				},
				{
					variable: "{event_user_display_name}",
					name: "user display name"
				},
				{
					variable: "{event_channel_name}",
					name: "channel name"
				},
				{
					variable: "{event_channel_id}",
					name: "channel id"
				},
				{
					variable: "{event_channel_parent_id}",
					name: "channel parent id"
				},
				{
					variable: "{event_channel_user_limit}",
					name: "channel user limit"
				},
				{
					variable: "{event_channel_full}",
					name: "channel full"
				}
			]
		}
	},
	{
		value: "serverMute",
		label: "When a member is server muted",
		event_category: "voice",
		components: {
			Guild: true,
			User: true,
			Member: true,
			Channel: false,
			variables: [
				{
					variable: "{event_user_name}",
					name: "user name"
				},
				{
					variable: "{event_user_id}",
					name: "user id"
				},
				{
					variable: "{event_user_display_name}",
					name: "user display name"
				}
			]
		}
	},
	{
		value: "selfMute",
		label: "When a member self mutes",
		event_category: "voice",
		components: {
			Guild: true,
			User: true,
			Member: true,
			Channel: false,
			variables: [
				{
					variable: "{event_user_name}",
					name: "user name"
				},
				{
					variable: "{event_user_id}",
					name: "user id"
				},
				{
					variable: "{event_user_display_name}",
					name: "user display name"
				}
			]
		}
	},

	{
		value: "serverUnMute",
		label: "When a member is server unmuted",
		event_category: "voice",
		components: {
			Guild: true,
			User: true,
			Member: true,
			Channel: false,
			variables: [
				{
					variable: "{event_user_name}",
					name: "user name"
				},
				{
					variable: "{event_user_id}",
					name: "user id"
				},
				{
					variable: "{event_user_display_name}",
					name: "user display name"
				}
			]
		}
	},
	{
		value: "selfUnMute",
		label: "When a member self unmutes",
		event_category: "voice",
		components: {
			Guild: true,
			User: true,
			Member: true,
			Channel: false,
			variables: [
				{
					variable: "{event_user_name}",
					name: "user name"
				},
				{
					variable: "{event_user_id}",
					name: "user id"
				},
				{
					variable: "{event_user_display_name}",
					name: "user display name"
				}
			]
		}
	},

	{
		value: "serverDeaf",
		label: "When a member is server deafened",
		event_category: "voice",
		components: {
			Guild: true,
			User: true,
			Member: true,
			Channel: false,
			variables: [
				{
					variable: "{event_user_name}",
					name: "user name"
				},
				{
					variable: "{event_user_id}",
					name: "user id"
				},
				{
					variable: "{event_user_display_name}",
					name: "user display name"
				}
			]
		}
	},
	{
		value: "selfDeaf",
		label: "When a member self deafens",
		event_category: "voice",
		components: {
			Guild: true,
			User: true,
			Member: true,
			Channel: false,
			variables: [
				{
					variable: "{event_user_name}",
					name: "user name"
				},
				{
					variable: "{event_user_id}",
					name: "user id"
				},
				{
					variable: "{event_user_display_name}",
					name: "user display name"
				}
			]
		}
	},

	{
		value: "serverUnDeaf",
		label: "When a member is server undeafened",
		event_category: "voice",
		components: {
			Guild: true,
			User: true,
			Member: true,
			Channel: false,
			variables: [
				{
					variable: "{event_user_name}",
					name: "user name"
				},
				{
					variable: "{event_user_id}",
					name: "user id"
				},
				{
					variable: "{event_user_display_name}",
					name: "user display name"
				}
			]
		}
	},
	{
		value: "selfUnDeaf",
		label: "When a member self undeafens",
		event_category: "voice",
		components: {
			Guild: true,
			User: true,
			Member: true,
			Channel: false,
			variables: [
				{
					variable: "{event_user_name}",
					name: "user name"
				},
				{
					variable: "{event_user_id}",
					name: "user id"
				},
				{
					variable: "{event_user_display_name}",
					name: "user display name"
				}
			]
		}
	},
	{
		value: "guildMemberUnBoost",
		label: "When a member unboosts the server",
		event_category: "boost",
		components: {
			Guild: true,
			User: true,
			Member: true,
			variables: [
				{
					variable: "{event_user_name}",
					name: "user name"
				},
				{
					variable: "{event_user_id}",
					name: "user id"
				},
				{
					variable: "{event_user_tag}",
					name: "user tag"
				},
				{
					variable: "{event_user_avatar}",
					name: "user avatar"
				},
				{
					variable: "{event_user_display_name}",
					name: "user display name"
				}
			]
		}
	},

	{
		value: "guildBoostLevelUp",
		label: "When the server boost level increases",
		event_category: "boost",
		components: {
			Guild: true,
			User: false,
			Member: false,
			variables: [
				// {
				// 	variable: "{event_oldLevel}",
				// 	value: oldLevel
				// },
				// {
				// 	variable: "{event_newLevel}",
				// 	value: newLevel
				// }
				{
					variable: "{event_oldLevel}",
					name: "old level"
				},
				{
					variable: "{event_newLevel}",
					name: "new level"
				}
			]
		}
	},

	{
		value: "guildBoostLevelDown",
		label: "When the server boost level decreases",
		event_category: "boost",
		components: {
			Guild: true,
			User: false,
			Member: false,
			variables: [
				// {
				// 	variable: "{event_oldLevel}",
				// 	value: oldLevel
				// },
				// {
				// 	variable: "{event_newLevel}",
				// 	value: newLevel
				// }
				{
					variable: "{event_oldLevel}",
					name: "old level"
				},
				{
					variable: "{event_newLevel}",
					name: "new level"
				}
			]
		}
	},
	{
		value: "messageCreate",
		label: "When a new message is sent",
		event_category: "message",
		components: {
			Guild: true,
			Channel: true,
			User: true,
			Member: true,
			Message: true,
			variables: [
				{
					variable: "{event_user_name}",
					name: "user name"
				},
				{
					variable: "{event_user_id}",
					name: "user id"
				},
				{
					variable: "{event_user_tag}",
					name: "user tag"
				},
				{
					variable: "{event_user_avatar}",
					name: "user avatar"
				},
				{
					variable: "{event_message_content}",
					name: "message content"
				},
				{
					variable: "{event_message_id}",
					name: "message id"
				},
				{
					variable: "{event_message_channel}",
					name: "message channel"
				},
				{
					variable: "{event_message_channel_type}",
					name: "message channel type"
				},
				{
					variable: "{event_message_channel_id}",
					name: "message channel id"
				},
				{
					variable: "{event_message_createdAt}",
					name: "message created at"
				},
				{
					variable: "{event_message_attachments}",
					name: "message attachments"
				},
				{
					variable: "{event_message_attachments_count}",
					name: "message attachments count"
				},

				{
					variable: "{event_message_has_attachments}",
					name: "message has attachments"
				},
				{
					variable: "{event_message_attachment_1}",
					name: "message attachment 1"
				},
				{
					variable: "{event_message_attachment_n}",
					name: "message attachment n"
				}
			]
		}
	},
	{
		value: "messageUpdate",
		label: "When a message is updated or edited",
		event_category: "message",
		components: {
			Guild: true,
			Channel: true,
			User: true,
			Member: true,
			Message: true,
			variables: [
				{
					variable: "{event_user_name}",
					name: "user name"
				},
				{
					variable: "{event_user_id}",
					name: "user id"
				},
				{
					variable: "{event_user_tag}",
					name: "user tag"
				},
				{
					variable: "{event_user_avatar}",
					name: "user avatar"
				},
				{
					variable: "{event_oldMessage_content}",
					name: "old message content"
				},
				{
					variable: "{event_newMessage_content}",
					name: "new message content"
				},
				{
					variable: "{event_message_id}",
					name: "message id"
				},
				{
					variable: "{event_message_channel}",
					name: "message channel"
				},
				{
					variable: "{event_message_channel_id}",
					name: "message channel id"
				},
				{
					variable: "{event_message_createdAt}",
					name: "message created at"
				},
				{
					variable: "{event_message_has_attachments}",
					name: "message has attachments"
				},
				{
					variable: "{event_message_attachment_1}",
					name: "message attachment 1"
				},
				{
					variable: "{event_message_attachment_n}",
					name: "message attachment n"
				},
				{
					variable: "{event_message_attachments_count}",
					name: "message attachments count"
				}
			]
		}
	},
	{
		value: "messageDelete",
		label: "When a message is deleted",
		event_category: "message",
		components: {
			Guild: true,
			Channel: true,
			User: true,
			Member: true,
			Message: true,
			variables: [
				{
					variable: "{event_user_name}",
					name: "user name"
				},
				{
					variable: "{event_user_id}",
					name: "user id"
				},
				{
					variable: "{event_user_tag}",
					name: "user tag"
				},
				{
					variable: "{event_user_avatar}",
					name: "user avatar"
				},
				{
					variable: "{event_message_content}",
					name: "message content"
				},
				{
					variable: "{event_message_id}",
					name: "message id"
				},
				{
					variable: "{event_message_channel}",
					name: "message channel"
				},
				{
					variable: "{event_message_channel_id}",
					name: "message channel id"
				},
				{
					variable: "{event_message_createdAt}",
					name: "message created at"
				},
				{
					variable: "{event_message_attachment}",
					name: "message attachment"
				}
			]
		}
	},
	{
		value: "messagePinned",
		label: "When a message is pinned",
		event_category: "message",
		components: {
			Guild: true,
			Channel: true,
			User: true,
			Member: true,
			Message: true,
			variables: []
		}
	},

	{
		value: "threadCreate",
		label: "When a thread is created",
		event_category: "threads",

		components: {
			Guild: true,
			Channel: true,
			variables: [
				{
					variable: "{new_thread}",
					name: "is the thread new? (true/false)"
				},
				{
					variable: "{event_thread_id}",
					name: "thread id"
				},
				{
					variable: "{event_thread_name}",
					name: "thread name"
				},
				{
					variable: "{event_created_at}",
					name: "thread created at"
				},
				{
					variable: "{event_thread_createdTimestamp}",
					name: "thread created timestamp"
				},
				{
					variable: "{event_creator_id}",
					name: "thread creator id"
				},
				{
					variable: "{event_parent_channel_id}",
					name: "thread parent channel id"
				}
			]
		},
		options: {
			channel: "thread that was created."
		}
	},
	{
		value: "threadUpdate",
		label: "When a thread is updated",
		event_category: "threads",

		components: {
			Guild: true,
			Channel: true,
			variables: [
				{
					variable: "{event_thread_id}",
					name: "thread id"
				},
				{
					variable: "{old_event_thread_name}",
					name: "old thread name"
				},
				{
					variable: "{new_event_thread_name}",
					name: "new thread name"
				},
				{
					variable: "{old_thread_archived}",
					name: "old thread archived"
				},
				{
					variable: "{new_thread_archived}",
					name: "new thread archived"
				},
				{
					variable: "{old_thread_locked}",
					name: "old thread locked"
				},
				{
					variable: "{new_thread_locked}",
					name: "new thread locked"
				},
				{
					variable: "{event_created_at}",
					name: "thread created at"
				},
				{
					variable: "{event_thread_createdTimestamp}",
					name: "thread created timestamp"
				},
				{
					variable: "{event_creator_id}",
					name: "thread creator id"
				},
				{
					variable: "{event_parent_channel_id}",
					name: "thread parent channel id"
				}
			]
		},
		options: {
			channel: "thread that was created."
		}
	},
	{
		value: "threadDelete",
		label: "When a thread is deleted",
		event_category: "threads",

		components: {
			Guild: true,
			variables: [
				{
					variable: "{event_thread_id}",
					name: "thread id"
				},
				{
					variable: "{event_thread_name}",
					name: "thread name"
				},
				{
					variable: "{event_created_at}",
					name: "thread created at"
				},
				{
					variable: "{event_thread_createdTimestamp}",
					name: "thread created timestamp"
				},
				{
					variable: "{event_creator_id}",
					name: "thread creator id"
				},
				{
					variable: "{event_parent_channel_id}",
					name: "thread parent channel id"
				}
			]
		},
		options: {
			channel: "thread that was created."
		}
	},
	{
		value: "channelCreate",
		label: "When a channel is created",
		event_category: "channel",
		components: {
			Guild: true,
			Channel: true,
			variables: [
				{
					name: "channel name",
					variable: "{event_channel_name}"
				},
				{
					name: "channel id",
					variable: "{event_channel_id}"
				},
				{
					variable: "{event_channel_category_id}",
					name: "channel category id"
				},
				{
					variable: "{event_channel_type}",
					name: "channel type"
				},
				{
					variable: "{event_channel_createdAt}",
					name: "channel created at"
				},
				// nsfw,
				{
					variable: "{event_channel_nsfw}",
					name: "channel nsfw"
				}
			]
		},
		options: {
			channel: "channel that was created."
		}
	},
	{
		value: "channelUpdate",
		label: "When a channel is updated",
		event_category: "channel",
		components: {
			Guild: true,
			Channel: true,
			variables: [
				{
					variable: "{event_oldChannel_name}",
					name: "old channel name"
				},
				{
					variable: "{event_oldChannel_id}",
					name: "old channel id"
				},
				{
					variable: "{event_oldChannel_category_id}",
					name: "old channel category id"
				},
				{
					variable: "{event_oldChannel_type}",
					name: "old channel type"
				},
				{
					variable: "{event_oldChannel_createdAt}",
					name: "old channel created at"
				},
				{
					variable: "{event_newChannel_name}",
					name: "new channel name"
				},
				{
					variable: "{event_newChannel_id}",
					name: "new channel id"
				},
				{
					variable: "{event_newChannel_category_id}",
					name: "new channel category id"
				},
				{
					variable: "{event_newChannel_type}",
					name: "new channel type"
				},
				{
					variable: "{event_newChannel_createdAt}",
					name: "new channel created at"
				},
				// nsfw,
				{
					variable: "{event_oldChannel_nsfw}",
					name: "old channel nsfw"
				},
				{
					variable: "{event_newChannel_nsfw}",
					name: "new channel nsfw"
				}
			]
		},
		options: {
			channel: "channel that was created."
		}
	},
	{
		value: "channelDelete",
		label: "When a channel is deleted",
		event_category: "channel",
		components: {
			Guild: true,
			Channel: true,
			variables: [
				{
					name: "channel name",
					variable: "{event_channel_name}"
				},
				{
					name: "channel id",
					variable: "{event_channel_id}"
				},
				{
					variable: "{event_channel_category_id}",
					name: "channel category id"
				},
				{
					variable: "{event_channel_type}",
					name: "channel type"
				},
				{
					variable: "{event_channel_createdAt}",
					name: "channel created at"
				},
				// nsfw,
				{
					variable: "{event_channel_nsfw}",
					name: "channel nsfw"
				}
			]
		},
		options: {
			channel: "channel that was deleted."
		}
	},

	{
		value: "guildChannelPermissionsUpdate",
		label: "When a channel's permissions are updated",
		event_category: "channel",
		components: {
			Guild: true,
			Channel: true,
			variables: [
				// {
				// 	variable: "{event_oldPermissions}",
				// 	value: oldPermissions
				// },
				// {
				// 	variable: "{event_newPermissions}",
				// 	value: newPermissions
				// }
				// {
				// 	variable: "{event_oldPermissions}",
				// 	name: "old permissions"
				// },
				// {
				// 	variable: "{event_newPermissions}",
				// 	name: "new permissions"
				// }
			]
		},
		options: {
			channel: "channel that was deleted."
		}
	},
	{
		value: "guildMemberAdd",
		label: "When a new user joins the server",
		event_category: "member",
		components: {
			Guild: true,
			User: true,
			Member: true,
			variables: [
				{
					variable: "{event_user_name}",
					name: "user name"
				},
				{
					variable: "{event_user_id}",
					name: "user id"
				},
				{
					variable: "{event_user_display_name}",
					name: "user display name"
				},
				{
					variable: "{event_user_tag}",
					name: "user tag"
				},
				{
					variable: "{event_user_avatar}",
					name: "user avatar"
				}
			]
		}
	},
	{
		value: "guildBanAdd",
		label: "When a user is banned from the server",
		event_category: "member",
		components: {
			Guild: true,
			User: true,
			variables: [
				{
					variable: "{event_reason}",
					name: "reason"
				},
				{
					variable: "{event_user_name}",
					name: "user name"
				},
				{
					variable: "{event_user_id}",
					name: "user id"
				},
				{
					variable: "{event_user_tag}",
					name: "user tag"
				},
				{
					variable: "{event_user_avatar}",
					name: "user avatar"
				}
			]
		}
	},
	{
		value: "guildBanRemove",
		label: "When a user is unbanned from the server",
		event_category: "member",
		components: {
			Guild: true,
			User: true,
			variables: [
				{
					variable: "{event_reason}",
					name: "reason"
				}
			]
		}
	},
	{
		value: "guildMemberRemove",
		label: "When a user leaves or is kicked from the server",
		event_category: "member",
		components: {
			Guild: true,
			User: true,
			Member: true,
			variables: [
				{
					variable: "{event_user_name}",
					name: "user name"
				},
				{
					variable: "{event_user_id}",
					name: "user id"
				},
				{
					variable: "{event_user_display_name}",
					name: "user display name"
				},
				{
					variable: "{event_user_tag}",
					name: "user tag"
				},
				{
					variable: "{event_user_avatar}",
					name: "user avatar"
				}
			]
		}
	},
	{
		value: "guildMemberRoleAdd",
		label: "When a role is added to a user",
		event_category: "member",
		components: {
			Guild: true,
			User: true,
			Member: true,
			Role: true,
			variables: [
				{
					variable: "{event_role_id}",
					name: "role id"
				},
				{
					variable: "{event_role_name}",
					name: "role name"
				},
				{
					variable: "{event_role}",
					name: "role"
				},
				{
					variable: "{event_user_name}",
					name: "user name"
				},
				{
					variable: "{event_user_id}",
					name: "user id"
				},
				{
					variable: "{event_user_display_name}",
					name: "user display name"
				},
				{
					variable: "{event_user_tag}",
					name: "user tag"
				},
				{
					variable: "{event_user_avatar}",
					name: "user avatar"
				}
			]
		}
	},
	{
		value: "guildMemberRoleRemove",
		label: "When a role is removed from a user",
		event_category: "member",
		components: {
			Guild: true,
			User: true,
			Member: true,
			Role: true,
			variables: [
				{
					variable: "{event_role_id}",
					name: "role id"
				},
				{
					variable: "{event_role_name}",
					name: "role name"
				},
				{
					variable: "{event_role}",
					name: "role"
				},
				{
					variable: "{event_user_name}",
					name: "user name"
				},
				{
					variable: "{event_user_id}",
					name: "user id"
				},
				{
					variable: "{event_user_display_name}",
					name: "user display name"
				},
				{
					variable: "{event_user_tag}",
					name: "user tag"
				},
				{
					variable: "{event_user_avatar}",
					name: "user avatar"
				}
			]
		}
	},
	{
		value: "guildMemberUpdate",
		label: "When a guild member is updated",
		event_category: "member",
		components: {
			Guild: true,
			User: true,
			Member: true,
			variables: [
				{
					variable: "{event_user_id}",
					name: "user id"
				},
				{
					variable: "{event_user_tag}",
					name: "user tag"
				},
				{
					variable: "{event_user_avatar}",
					name: "user avatar"
				},
				{
					variable: "{event_old_nickname}",
					name: "old nickname"
				},
				{
					variable: "{event_new_nickname}",
					name: "new nickname"
				},
				{
					variable: "{event_old_discriminator}",
					name: "old discriminator"
				},
				{
					variable: "{event_new_discriminator}",
					name: "new discriminator"
				},
				{
					variable: "{event_old_roles}",
					name: "old roles"
				},
				{
					variable: "{event_new_roles}",
					name: "new roles"
				},
				{
					variable: "{event_member_verified_update}",
					name: "member verified update"
				}
			]
		}
	},
	{
		value: "guildMemberNicknameUpdate",
		label: "When a user changes their nickname",
		event_category: "member",
		components: {
			Guild: true,
			User: true,
			Member: true,
			variables: [
				{
					variable: "{event_user_name}",
					name: "user name"
				},
				{
					variable: "{event_user_id}",
					name: "user id"
				},
				{
					variable: "{event_user_display_name}",
					name: "user display name"
				},
				{
					variable: "{event_user_tag}",
					name: "user tag"
				},
				{
					variable: "{event_user_avatar}",
					name: "user avatar"
				},
				{
					variable: "{event_old_nickname}",
					name: "old nickname"
				},
				{
					variable: "{event_new_nickname}",
					name: "new nickname"
				}
			]
		}
	},
	{
		value: "presenceUpdate",
		label: "When a user's status changes",
		event_category: "member",

		components: {
			Guild: true,
			User: true,
			Member: true,
			variables: [
				{
					variable: "{event_old_status}",
					name: "old status"
				},
				{
					variable: "{event_new_status}",
					name: "new status"
				},
				{
					variable: "{event_user_id}",
					name: "user id"
				},
				{
					variable: "{event_user_name}",
					name: "user username"
				},
				{
					variable: "{event_user_display_name}",
					name: "user display name"
				}
			]
		}
	},
	{
		value: "roleCreate",
		label: "When a role is created",
		event_category: "role",
		components: {
			Guild: true,
			User: false,
			Member: false,
			Role: true,
			variables: [
				{
					variable: "{event_role_id}",
					name: "role id"
				},
				{
					variable: "{event_role_name}",
					name: "role name"
				},
				{
					variable: "{event_role}",
					name: "role"
				},
				{
					variable: "{event_role_hex_color}",
					name: "role hex color"
				},
				{
					variable: "{event_role_iconURL}",
					name: "role icon url"
				},
				{
					variable: "{event_role_hoisted}",
					name: "role hoisted"
				},
				// position
				{
					variable: "{event_role_position}",
					name: "role position"
				}
			]
		}
	},

	{
		value: "roleUpdate",
		label: "When a role is updated",
		event_category: "role",
		components: {
			Guild: true,
			User: false,
			Member: false,
			Role: true,
			variables: [
				{
					variable: "{event_role_id}",
					name: "role id"
				},
				{
					variable: "{event_role_newName}",
					name: "new role name"
				},
				{
					variable: "{event_role_oldName}",
					name: "old role name"
				},
				{
					variable: "{event_role_newColor}",
					name: "new role color"
				},
				{
					variable: "{event_role_oldColor}",
					name: "old role color"
				},
				{
					variable: "{event_role}",
					name: "role"
				},
				{
					variable: "{event_role_newHoisted}",
					name: "new role hoisted"
				},
				{
					variable: "{event_role_oldHoisted}",
					name: "old role hoisted"
				},
				{
					variable: "{event_role_newIconURL}",
					name: "new role icon url"
				},
				{
					variable: "{event_role_oldIconURL}",
					name: "old role icon url"
				},
				{
					variable: "{event_role_newPosition}",
					name: "new role position"
				},
				{
					variable: "{event_role_oldPosition}",
					name: "old role position"
				}
			]
		}
	},
	{
		value: "roleDelete",
		label: "When a role is deleted",
		event_category: "role",
		components: {
			Guild: true,
			User: false,
			Member: false,
			Role: true,
			variables: [
				{
					variable: "{event_role_id}",
					name: "role id"
				},
				{
					variable: "{event_role_name}",
					name: "role name"
				},
				{
					variable: "{event_role}",
					name: "role"
				},
				{
					variable: "{event_role_hex_color}",
					name: "role hex color"
				},
				{
					variable: "{event_role_iconURL}",
					name: "role icon url"
				},
				{
					variable: "{event_role_hoisted}",
					name: "role hoisted"
				},
				// position
				{
					variable: "{event_role_position}",
					name: "role position"
				}
			]
		}
	},
	{
		value: "messageReactionAdd",
		label: "When a reaction is added to a message",
		event_category: "reaction",
		components: {
			Guild: true,
			User: true,
			Channel: true,
			Member: true,
			Message: true,
			variables: [
				{
					variable: "{event_user_name}",
					name: "user name"
				},
				{
					variable: "{event_user_id}",
					name: "user id"
				},
				{
					variable: "{event_user_tag}",
					name: "user tag"
				},
				{
					variable: "{event_user_avatar}",
					name: "user avatar"
				},
				{
					variable: "{event_reaction_count}",
					name: "reaction count"
				},
				{
					variable: "{event_reaction_emoji}",
					name: "reaction emoji"
				},
				{
					variable: "{event_reaction_emoji_name}",
					name: "reaction emoji name"
				},
				{
					variable: "{event_reaction_emoji_id}",
					name: "reaction emoji id"
				},
				{
					variable: "{event_message_content}",
					name: "message content"
				},
				{
					variable: "{event_message_id}",
					name: "message id"
				},
				{
					variable: "{event_message_channel}",
					name: "message channel"
				},
				{
					variable: "{event_message_channel_id}",
					name: "message channel id"
				},
				{
					variable: "{event_message_createdAt}",
					name: "message created at"
				}
			]
		}
	},
	{
		value: "messageReactionRemove",
		label: "When a reaction is removed from a message",
		event_category: "reaction",
		components: {
			Guild: true,
			User: true,
			Channel: true,
			Member: true,
			Message: true,
			variables: [
				{
					variable: "{event_user_name}",
					name: "user name"
				},
				{
					variable: "{event_user_id}",
					name: "user id"
				},
				{
					variable: "{event_user_tag}",
					name: "user tag"
				},
				{
					variable: "{event_user_avatar}",
					name: "user avatar"
				},
				{
					variable: "{event_reaction_count}",
					name: "reaction count"
				},
				{
					variable: "{event_reaction_emoji}",
					name: "reaction emoji"
				},
				{
					variable: "{event_reaction_emoji_name}",
					name: "reaction emoji name"
				},
				{
					variable: "{event_reaction_emoji_id}",
					name: "reaction emoji id"
				},
				{
					variable: "{event_message_content}",
					name: "message content"
				},
				{
					variable: "{event_message_id}",
					name: "message id"
				},
				{
					variable: "{event_message_channel}",
					name: "message channel"
				},
				{
					variable: "{event_message_channel_id}",
					name: "message channel id"
				},
				{
					variable: "{event_message_createdAt}",
					name: "message created at"
				}
			]
		}
	}
];

export const ACTION_TYPES = [
	{ icon: faHourglassEnd, title: "Run a Loop", beta: true, value: "loop", label: "Loop through actions and conditions a set amount of times" },
	{ icon: faHourglassHalf, title: "Stop a Loop", value: "break_loop", label: "Break out of and stop any active loop" },

	{
		icon: faReply,
		value: "plain_text",
		title: "Plain Text Reply",
		label: "Bot replies with a plain text response",
		requriements: [["Interaction"], ["Channel"]]
	},
	{
		icon: faCodeBranch,
		title: "Embed Reply",
		value: "embed",
		label: "Bot replies with an embed response",
		requriements: [["Interaction"], ["Channel"]]
	},
	{ icon: faTrashAlt, value: "purge_messages", title: "Purge Messages", label: "Purge messages in a channel", requirements: [] },
	{
		icon: faFingerprint,
		title: "Set a unique variable",
		value: "set_unique_variable",
		label: "Set a unique variable for every execution of this command or event.",
		requriements: []
	},
	{ icon: faBullhorn, title: "Change the Bot's Status", value: "status_change", label: "Change the bot's status or presence", requirements: [] },

	{
		icon: faReplyAll,
		title: "Random Reply",
		value: "random_response",
		label: "Bot responds with a random response",
		requriements: [["Interaction"], ["Channel"]]
	},
	{
		icon: faPaperPlane,
		title: "Send a message to a Channel",
		value: "targeted_response",
		label: "Bot sends a message to a specific channel",
		disabled: false
	},
	{
		icon: faEnvelope,
		title: "Direct Message a User",
		value: "dm_response",
		label: "Bot sends a direct message to a member",
		disabled: false
	},
	{
		icon: faMousePointer,
		title: "Send a Message with attached Buttons",
		value: "button_response",
		label: "Add buttons and button actions",
		button: false
	},
	{
		icon: faUserPlus,
		title: "Add Roles",
		value: "role_add",
		label: "Adds one or more roles to a user",
		requirements: ["Member"]
	},
	{
		icon: faUserMinus,
		title: "Remove Roles",
		value: "role_remove",
		label: "Removes one or more roles from a user",
		requirements: ["Member"]
	},
	{
		icon: faHourglass,
		title: "Wait before running another action",
		value: "wait_action",
		label: "Wait before executing another action"
	},
	{
		icon: faHourglass,
		title: "Wait before running another action",
		value: "wait_action",
		label: "Wait before executing another action"
	},
	{ icon: faExclamation, title: "Send an error log message", value: "send_error_message", label: "Trigger an error message" },

	{ icon: faComment, value: "channel_create", title: "Create a Channel", label: "Creates a channel in the server", requirements: ["Guild"] },
	{ icon: faEdit, value: "channel_edit", title: "Edit a Channel", label: "Edits a channel in the server", requirements: ["Guild"] },
	{
		icon: faCommentSlash,
		value: "channel_delete",
		title: "Delete a Channel",
		label: "Deletes a channel in the server",
		requirements: ["Guild"]
	},
	{ icon: faComment, value: "thread_create", title: "Create a Thread", label: "Create a public or private thread in the server", requirements: ["Guild"] },
	{ icon: faEdit, value: "thread_edit", title: "Edit a Thread", label: "Edit a public or private thread in the server", requirements: ["Guild"] },
	{ icon: faCommentSlash, value: "thread_delete", title: "Delete a Thread", label: "Delete a public or private thread in the server", requirements: ["Guild"] },
	{
		icon: faUserSlash,
		value: "kick_action",
		title: "Kick a Member",
		label: "Kick a member from the guild"
	},
	{
		icon: faUserTimes,
		value: "ban_action",
		title: "Ban a Member",
		label: "Ban a member from the guild"
	},
	{
		icon: faUserFriends,
		title: "Create a role",
		value: "create_role",
		label: "Creates a role in the server",
		requirements: ["Guild"]
	},
	{
		icon: faUsersSlash,
		title: "Delete a role",
		value: "delete_role",
		label: "Deletes a role in the server",
		requirements: ["Guild"]
	},
	{ icon: faUserCog, value: "edit_role", title: "Edit a Role", label: "Edits a role in the server", requirements: ["Guild"] },
	{
		icon: faHourglassEnd,
		value: "timeout_action",
		title: "Timeout a Member",
		label: "Timeout a member in the server"
	},
	{
		icon: faMoneyBill,
		title: "Add Currency",
		value: "add_currency",
		label: "Adds currency to a users balance"
	},
	{
		icon: faMoneyBillAlt,
		title: "Remove Currency",
		value: "remove_currency",
		label: "Removes currency from a users balance"
	},
	{ icon: faCartPlus, title: "Add Item", value: "add_item", label: "Adds an item to a users inventory" },
	{ icon: faCartArrowDown, title: "Remove Item", value: "remove_item", label: "Removes an item from a users inventory." },
	{ icon: faBars, title: "Send a Message with a Select Menu", value: "select_menu", label: "Create a select menu with attached actions", button: false },
	{ icon: faTrash, title: "Delete a Message", value: "delete_message", label: "Delete a message or reply" },
	{ icon: faRandom, title: "Publish a Message", value: "crosspost_message", label: "Publish a message sent in an announcement channel" },
	{ icon: faEquals, title: "Set Variable", value: "set_var", label: "Set the value of a custom variable.", button: false },
	{ icon: faCalculator, title: "Run Equation on Variable", value: "math_var", label: "Run an equation on a custom variable.", button: false },
	{ icon: faTimes, title: "Delete Variable", value: "delete_var", label: "Delete the value of a custom variable.", button: false },
	{ icon: faCode, title: "Send an API Request", value: "api_action", label: "Send a HTTP request to an external API.", button: false },
	{ icon: faEdit, value: "change_nickname", title: "Change a Members Nickname", label: "Change a member nickname in the server." },
	{ icon: faMapPin, value: "pin_message", title: "Pin a Message", label: "Pin a message or reply sent in this command." },
	{ icon: faTable, title: "Send a Form", value: "modal", label: "Send a form or modal and wait for a response", button: false },
	{ icon: faCode, title: "Execute an IFTTT Trigger", value: "ifttt_action", label: "Execute an IFTTT trigger and any associated applets", button: false },
	{ beta: true, icon: faUserPlus, value: "create_invite", title: "Create Server Invite", label: "Create an invite for the server" }
];

export const MARKET_CATEGORIES = [
	{ label: "Tickets", value: "tickets", menu: true },
	{ label: "Economy", value: "economy", menu: true },
	{ label: "Server Management", value: "server_management", menu: true },
	{ label: "Fun", value: "fun", menu: true },
	{ label: "Verification", value: "verification" },
	{ label: "API", value: "api", menu: true },
	{ label: "Moderation", value: "moderation", menu: true },
	{ label: "AI", value: "AI", menu: true },
	{ label: "Logging", value: "logging" },
	{ label: "Roleplay", value: "roleplay" },
	{ label: "Anime", value: "anime" },
	{ label: "Minecraft", value: "minecraft" },
	{ label: "Roblox", value: "roblox" },
	{ label: "Giveaway", value: "giveaway" },
	{ label: "Memes", value: "memes" },
	{ label: "Pokemon", value: "pokemon" },
	{ label: "Welcomer", value: "welcomer" },
	{ label: "Crypto", value: "crypto" },
	{ label: "Image Manipulation", value: "image_manipulation" },

	{ label: "Other", value: "other" }
];

export const LANGUAGE_OPTIONS = {
	af: "Afrikaans",
	sq: "Albanian",
	am: "Amharic",
	ar: "Arabic",
	hy: "Armenian",
	az: "Azerbaijani",
	eu: "Basque",
	be: "Belarusian",
	bn: "Bengali",
	bs: "Bosnian",
	bg: "Bulgarian",
	ca: "Catalan",
	ceb: "Cebuano",
	ny: "Chichewa",
	"zh-cn": "Chinese Simplified",
	"zh-tw": "Chinese Traditional",
	co: "Corsican",
	hr: "Croatian",
	cs: "Czech",
	da: "Danish",
	nl: "Dutch",
	en: "English",
	eo: "Esperanto",
	et: "Estonian",
	tl: "Filipino",
	fi: "Finnish",
	fr: "French",
	fy: "Frisian",
	gl: "Galician",
	ka: "Georgian",
	de: "German",
	el: "Greek",
	gu: "Gujarati",
	ht: "Haitian Creole",
	ha: "Hausa",
	haw: "Hawaiian",
	iw: "Hebrew",
	hi: "Hindi",
	hmn: "Hmong",
	hu: "Hungarian",
	is: "Icelandic",
	ig: "Igbo",
	id: "Indonesian",
	ga: "Irish",
	it: "Italian",
	ja: "Japanese",
	jw: "Javanese",
	kn: "Kannada",
	kk: "Kazakh",
	km: "Khmer",
	ko: "Korean",
	ku: "Kurdish (Kurmanji)",
	ky: "Kyrgyz",
	lo: "Lao",
	la: "Latin",
	lv: "Latvian",
	lt: "Lithuanian",
	lb: "Luxembourgish",
	mk: "Macedonian",
	mg: "Malagasy",
	ms: "Malay",
	ml: "Malayalam",
	mt: "Maltese",
	mi: "Maori",
	mr: "Marathi",
	mn: "Mongolian",
	my: "Myanmar (Burmese)",
	ne: "Nepali",
	no: "Norwegian",
	ps: "Pashto",
	fa: "Persian",
	pl: "Polish",
	pt: "Portuguese",
	ma: "Punjabi",
	ro: "Romanian",
	ru: "Russian",
	sm: "Samoan",
	gd: "Scots Gaelic",
	sr: "Serbian",
	st: "Sesotho",
	sn: "Shona",
	sd: "Sindhi",
	si: "Sinhala",
	sk: "Slovak",
	sl: "Slovenian",
	so: "Somali",
	es: "Spanish",
	su: "Sundanese",
	sw: "Swahili",
	sv: "Swedish",
	tg: "Tajik",
	ta: "Tamil",
	te: "Telugu",
	th: "Thai",
	tr: "Turkish",
	uk: "Ukrainian",
	ur: "Urdu",
	uz: "Uzbek",
	vi: "Vietnamese",
	cy: "Welsh",
	xh: "Xhosa",
	yi: "Yiddish",
	yo: "Yoruba",
	zu: "Zulu"
};

export const TEMPLATES = [
	{
		name: "Hug",
		description: "Send someone a hug",
		icon: faHeartbeat,
		data: {
			name: "hug",
			id: "",
			description: "Send someone a hug.",
			options: [
				{
					type: "user",
					name: "user",
					description: "The person you want to hug.",
					required: true
				}
			],
			actions: [
				{
					type: "plain_text",
					response: " {user} just sent {option_user} a hug!"
				}
			],
			permissions: {
				allowed_roles: [
					{
						name: "@everyone",
						id: "everyone"
					}
				],
				banned_roles: [],
				banned_channels: [],
				banned_users: [],
				required_permissions: []
			},
			ephemeral: false,
			enabled: false,
			example: true
		}
	},
	{
		name: "Button",
		description: "An example of a button command",
		icon: faMousePointer,
		data: {
			name: "buttoncommand",
			id: "",
			description: "An example of a button custom command.",
			options: [
				{
					type: "string",
					name: "name",
					description: "Your name",
					required: true
				},
				{
					type: "channel",
					name: "channel-option",
					description: "A channel to post to.",
					required: true
				},
				{
					type: "role",
					name: "role",
					description: "A role to give the user when they click the Grey button.",
					required: true
				}
			],
			actions: [
				{
					type: "button_response",
					response_options: {
						response: "Click one of the buttons below!",
						target: {
							reply: true
						}
					},
					buttons: [
						{
							label: "Blue",
							style: "PRIMARY",
							actions: [
								{
									type: "plain_text",
									response: "{option_name} clicked the Blue button!"
								}
							]
						},
						{
							label: "Red",
							style: "DANGER",
							actions: [
								{
									type: "random_response",
									responses: [
										{
											response: "{option_name} just clicked the red button!"
										},
										{
											response: "The red button has been clicked by {option_name}."
										}
									]
								}
							]
						},
						{
							label: "Green",
							style: "SUCCESS",
							actions: [
								{
									type: "targeted_response",
									response_options: {
										response: "{option_name} clicked the Green button!"
									},
									target: {
										variable: "channel-option"
									}
								}
							]
						},
						{
							label: "Grey",
							style: "SECONDARY",
							actions: [
								{
									type: "embed",
									embed: {
										title: "Role added!",
										description: "Giving {option_role} to you!"
									}
								},
								{
									roles: [
										{
											variable: "role",
											name: "role"
										}
									],
									type: "role_add",
									target: "trigger"
								}
							]
						}
					]
				}
			],
			permissions: {
				allowed_roles: [
					{
						name: "@everyone",
						id: "everyone"
					}
				],
				banned_roles: [],
				banned_channels: [],
				banned_users: [],
				required_permissions: []
			},
			ephemeral: false,
			enabled: false,
			example: true
		}
	},
	{
		name: "Reaction Roles",
		description: "Create a reaction roles system.",
		icon: faSmile,

		data: {
			name: "reactionrole",
			id: "",
			description: "Create a reaction role.",
			options: [
				{
					type: "channel",
					name: "channel",
					description: "Which channel would you like this to post in?",
					required: true,
					validated: true
				}
			],
			actions: [
				{
					type: "plain_text",
					response: "The reaction role message has been sent in {option_channel}!",
					emojis: [],
					linked: true,
					validated: true
				},
				{
					type: "button_response",
					response_options: {
						embed: {
							title: "What is your [ROLE-NAME-HERE]?",
							description: "Click the button below that best fits you.",
							color: "#f8f8fa"
						},
						target: {
							variable: "channel"
						},
						emojis: []
					},
					linked: true,
					buttons: [
						{
							label: "Role 1",
							style: "SUCCESS",
							actions: [
								{
									roles: [
										{
											id: "948310431502569484",
											name: "Your Reaction Role Here",
											color: 10181046
										}
									],
									type: "role_add",
									target: "trigger",
									linked: true,
									validated: false
								},
								{
									type: "plain_text",
									response: "The role **[INSERT ROLE NAME HERE]** has been added!",
									emojis: [],
									linked: true
								}
							]
						},
						{
							label: "Role 2",
							style: "SUCCESS",
							actions: [
								{
									type: "role_add",
									roles: [
										{
											id: "948310431502569484",
											name: "Your Reaction Role Here",
											color: 10181046
										}
									],
									target: "trigger",
									linked: true
								},
								{
									type: "plain_text",
									response: "The role **[INSERT ROLE NAME HERE]** has been added!",
									emojis: [],
									linked: true
								}
							]
						},
						{
							label: "Role 3",
							style: "SUCCESS",
							actions: [
								{
									roles: [
										{
											id: "948310431502569484",
											name: "Your Reaction Role Here",
											color: 10181046
										}
									],
									type: "role_add",
									target: "trigger",
									linked: true
								},
								{
									type: "plain_text",
									response: "The role **[INSERT ROLE NAME HERE]** has been added!",
									emojis: [],
									linked: true
								}
							]
						},
						{
							label: "Role 4",
							style: "SUCCESS",
							actions: [
								{
									type: "role_add",
									roles: [
										{
											id: "948310431502569484",
											name: "Your Reaction Role Here",
											color: 10181046
										}
									],
									target: "trigger",
									linked: true
								},
								{
									type: "plain_text",
									response: "The role **[INSERT ROLE NAME HERE]** has been added!",
									emojis: [],
									linked: true
								}
							]
						},
						{
							label: "Reset",
							style: "DANGER",
							actions: [
								{
									type: "role_remove",
									roles: [
										{
											id: "948312245794926613",
											name: "Add all the roles from before here (Roles 1-4)",
											color: 3447003
										}
									],
									target: "trigger",
									linked: true
								},
								{
									type: "plain_text",
									response: "Your [CATAGORY ROLE] role(s) has/have been removed!",
									emojis: [],
									linked: true
								}
							]
						}
					]
				}
			],
			permissions: {
				allowed_roles: [],
				banned_roles: [],
				banned_channels: [],
				banned_users: [],
				required_permissions: ["ADMINISTRATOR"]
			},
			ephemeral: true
		}
	},
	{
		name: "Ship",
		description: "Ship two people",
		icon: faHeart,
		data: {
			name: "ship",
			id: "",
			description: "Ship two people <3",
			options: [
				{
					type: "user",
					name: "first-user",
					description: "Select the first user you want to ship!",
					required: true,
					validated: true
				},
				{
					type: "user",
					name: "second-user",
					description: "Select the second user you want to ship!",
					required: true,
					validated: true
				}
			],
			actions: [
				{
					type: "embed",
					embed: {
						title: "Ship!",
						description: " {option_first-user}ㅤ-̶-̶-̶ㅤㅤ{random[1,100]}%ㅤㅤ-̶-̶-̶  ㅤ{option_second-user}\n\n",
						image: "https://cdn.discordapp.com/attachments/911650946340233237/913978166920495134/unknown.png",
						color: "#dd06f9"
					},
					emojis: [],
					validated: true,
					linked: true
				}
			],
			permissions: {
				allowed_roles: [
					{
						name: "@everyone",
						id: "everyone"
					}
				],
				banned_roles: [],
				banned_channels: [],
				banned_users: [],
				required_permissions: []
			},
			ephemeral: false
		}
	},
	{
		name: "Rock, Paper, Scissors",
		description: "Play a game of rock, paper, scissors",
		icon: faHandScissors,
		data: {
			name: "rps",
			id: "",
			description: "Play a game of Rock, Paper, Scissors!",
			options: [],
			actions: [
				{
					type: "button_response",
					response_options: {
						embed: {
							description: "{user}, choose from the options below.",
							title: "Rock, Paper, Scissors!",
							color: "#f7f6f8"
						},
						target: {
							reply: true
						},
						emojis: []
					},
					buttons: [
						{
							label: "🗻 Rock",
							style: "PRIMARY",
							actions: [
								{
									type: "random_response",
									responses: [
										{
											embed: {
												title: "🗻 Rock",
												description: "{user} I choose: ✂️, you win!",
												color: "#fefeff"
											},
											emojis: []
										},
										{
											embed: {
												title: "🗻 Rock",
												description: "{user} I choose:📄, I win!",
												color: "#fefeff"
											},
											emojis: []
										},
										{
											embed: {
												title: "🗻 Rock",
												description: "{user} I choose:🗻, It's a draw!",
												color: "#fefeff"
											},
											emojis: []
										}
									],
									linked: true
								}
							]
						},
						{
							label: "📄 Paper",
							style: "PRIMARY",
							actions: [
								{
									type: "random_response",
									responses: [
										{
											response: "{user}, I choose ```✂️``` ! **I win!**",
											emojis: ["🔴"]
										},
										{
											response: "{user}, I choose ```📄``` ! **It's a draw!**",
											emojis: ["🟠"]
										},
										{
											response: "{user}, I choose ```🗻``` ! **You win!**",
											emojis: ["🟢"]
										}
									],
									linked: true
								}
							]
						},
						{
							label: "✂️ Scissors ",
							style: "PRIMARY",
							actions: [
								{
									type: "random_response",
									responses: [
										{
											response: "{user}, I choose ```📄``` ! **You win!**",
											emojis: ["🟢"]
										},
										{
											response: "{user}, I choose ```✂️``` ! **It's a draw!**",
											emojis: ["🟠"]
										},
										{
											response: "{user}, I choose ```🗻```! **I win!**",
											emojis: ["🔴"]
										}
									],
									linked: true
								}
							]
						}
					],
					linked: true
				}
			],
			permissions: {
				allowed_roles: [
					{
						name: "@everyone",
						id: "everyone"
					}
				],
				banned_roles: [],
				banned_channels: [],
				banned_users: [],
				required_permissions: []
			},
			ephemeral: false
		}
	},
	{
		name: "Poll",
		description: "Create a poll",
		icon: faPoll,
		data: {
			name: "poll",
			id: "CMD_e2723091-d601-4ce1-be0e-0908de89cb8a",
			showImportModal: false,
			description: "Create a poll for a selected channel!",
			options: [
				{
					type: "channel",
					name: "channel",
					description: "Select a channel where the poll message should be send!",
					required: true,
					validated: true
				},
				{
					type: "string",
					name: "message",
					description: "What is the message to up- or downvote?",
					required: true,
					validated: true
				},
				{
					type: "string",
					name: "thumbsup",
					description: "Select the thumbsup message!",
					required: true,
					validated: true
				},
				{
					type: "string",
					name: "thumbsdown",
					description: "Select the thumbsdown message!",
					required: true,
					validated: true
				}
			],
			actions: [
				{
					type: "targeted_response",
					response_options: {
						embed: {
							title: "Poll Time!",
							description: "{option_message}\n\n> :thumbsup: {option_thumbsup}\n> **OR**\n> :thumbsdown: {option_thumbsdown}",
							color: "#08f70b",
							thumbnail: "",
							footer: "Vote via the thumbsup and thumbsdown!"
						},
						emojis: ["👍", "👎"]
					},
					target: {
						variable: "channel"
					},
					validated: true,
					linked: true
				},
				{
					type: "plain_text",
					response: "Your poll has been sent!\nChannel: {option_channel}\nMessage: {option_message}\nThumbsup: {option_thumbsup}\nThumbsdown: {option_thumbsdown}",
					emojis: [],
					validated: true,
					linked: true
				}
			],
			permissions: {
				allowed_roles: [
					{
						name: "@everyone",
						id: "everyone"
					}
				],
				banned_roles: [],
				banned_channels: [],
				banned_users: [],
				required_permissions: []
			},
			error: "",
			ephemeral: true
		}
	},
	{
		name: "Embed",
		description: "Send an embed message to a specific channel",
		icon: faBullhorn,
		data: {
			name: "embed",
			id: "CMD_ee62487b-d55e-4d40-9f40-4f95b1790839",
			showImportModal: false,
			description: "Send an embed message into a selected channel!",
			options: [
				{
					type: "string",
					name: "title",
					description: "Add a title!",
					required: true,
					validated: true
				},
				{
					type: "string",
					name: "description",
					description: "Add a description!",
					required: true,
					validated: true
				},
				{
					type: "string",
					name: "footer",
					description: "Add a footer!",
					required: true,
					validated: true
				},
				{
					type: "channel",
					name: "channel",
					description: "Select a channel!",
					required: true,
					validated: true
				}
			],
			actions: [
				{
					type: "targeted_response",
					response_options: {
						embed: {
							title: "{option_title}",
							description: "{option_description}",
							footer: "{option_footer}",
							color: "#06f03d"
						}
					},
					target: {
						variable: "channel"
					},
					validated: true,
					linked: true
				},
				{
					type: "plain_text",
					response: "Your embed message has been sent! :white_check_mark:\n**Title:** {option_title}\n**Description:** {option_description}\n**Footer:** {option_footer}\n**Channel:** {option_channel}",
					validated: true,
					linked: true
				}
			],
			permissions: {
				allowed_roles: [],
				banned_roles: [],
				banned_channels: [],
				banned_users: [],
				required_permissions: ["MANAGE_MESSAGES"]
			},
			error: "",
			ephemeral: true
		}
	},
	{
		name: "Info",
		description: "Server and User Information",
		icon: faInfo,
		data: {
			name: "info",
			id: "CMD_8f44abc7-4edf-447d-8bae-0e90859dbffd",
			showImportModal: false,
			description: "Server & User Information command!",
			options: [],
			actions: [
				{
					type: "embed",
					embed: {
						title: "Server & User Info!",
						description: "__**Server Info!**__\n**Server Name :**\n{server}\n**Server Owner : **\n{server_owner}\n**Server Icon URL :**\n:link: [Click Here]({server_icon})\n**Server Members :**\n{server_members}\n\n__**User Info!**__\n**User Name :**\n{user_name}\n**User Tag :**\n{user_tag}\n**User Server Name :**\n{user_displayName}\n**User Id :**\n{user_id}\n**User Created :**\n{user_createdAt}\n**User Joined :**\n{user_joined}\n",
						color: "#05ff05"
					},
					emojis: [],
					validated: true,
					linked: true
				}
			],
			permissions: {
				allowed_roles: [
					{
						name: "@everyone",
						id: "everyone"
					}
				],
				banned_roles: [],
				banned_channels: [],
				banned_users: [],
				required_permissions: []
			},
			error: "",
			ephemeral: false
		}
	},
	{
		name: "Invoice",
		description: "Create a custom invoice",
		icon: faFileInvoiceDollar,
		data: {
			name: "invoice-create",
			id: "",
			description: "Create a custom invoice in discord!",
			options: [
				{
					type: "user",
					name: "client",
					description: "For who is this invoice created?",
					required: true,
					validated: true
				},
				{
					type: "string",
					name: "currency",
					description: "What a currency you are using?",
					required: true,
					choices: ["€", "$", "CZK"],
					validated: true
				},
				{
					type: "string",
					name: "item",
					description: "What a item you are selling?",
					required: true,
					validated: true
				},
				{
					type: "int",
					name: "price",
					description: "What a price is for your item?",
					required: true,
					validated: true
				},
				{
					type: "string",
					name: "item-2",
					description: "Additional item, If you don´t need it, use - ",
					required: true,
					validated: true
				},
				{
					type: "int",
					name: "price-2",
					description: "Additional price for item-2, if no need, use 0",
					required: true,
					validated: true
				},
				{
					type: "string",
					name: "item-3",
					description: "Additional item, If you don´t need it, use - ",
					required: true,
					validated: true
				},
				{
					type: "int",
					name: "price-3",
					description: "Additional price for item-3, if no need, use 0",
					required: true,
					validated: true
				},
				{
					type: "int",
					name: "full-price",
					description: "What is a full price of all items?",
					required: true,
					validated: true
				},
				{
					type: "string",
					name: "date-of-pay",
					description: "By what date should it be paid? (example: 07.12.2021)",
					required: true,
					validated: true
				},
				{
					type: "string",
					name: "note",
					description: "If you don´t want add note, say -",
					required: true,
					validated: true
				},
				{
					type: "channel",
					name: "channel",
					description: "In which channel you want post it?",
					required: true,
					validated: true
				}
			],
			actions: [
				{
					type: "targeted_response",
					response_options: {
						embed: {
							title: "NEW INVOICE",
							description: "Invoice number:  **{random[1000,99999]}**\n\n**From:** {user}\n**To:** {option_client}\n\nItems:\n1. **{option_item} - {option_price}** **{option_currency}**\n2. **{option_item-2} - {option_price-2}** **{option_currency}**\n3. **{option_item-3} - {option_price-3}** **{option_currency}**\n\n**SUMMARY**\nTotal **{option_full-price}** **{option_currency}**\nPay to date: **{option_date-of-pay}**\nNote: **{option_note}**\n\nCreated and approved by {user} ",
							thumbnail: "{server_icon}",
							footer: "Invoice system is powered by Rooster#1591",
							color: "#39f605",
							image: "https://i.imgflip.com/5wctgb.jpg"
						}
					},
					target: {
						variable: "channel"
					},
					validated: true,
					linked: true
				},
				{
					type: "plain_text",
					response: "{user} Invoice has been sucesully created and sent!",
					validated: true,
					linked: true
				}
			],
			permissions: {
				allowed_roles: [
					{
						name: "@everyone",
						id: "everyone"
					}
				],
				banned_roles: [],
				banned_channels: [],
				banned_users: [],
				required_permissions: []
			},
			ephemeral: false
		}
	},
	{
		name: "Wanted",
		description: "Check if you are wanted?",
		icon: faDollarSign,
		data: {
			name: "wanted",
			id: "CMD_89b0b6a2-d42c-47ef-8626-2b4c5eb8d89a",
			showImportModal: false,
			description: "Check if you are wanted?!",
			options: [],
			actions: [
				{
					type: "embed",
					embed: {
						title: "You Are Wanted!",
						description: "[Image](https://api.popcat.xyz/wanted?image={user_icon})",
						image: "https://api.popcat.xyz/wanted?image={user_icon}",
						color: "#e3cb5d"
					},
					emojis: [],
					validated: true,
					linked: true
				}
			],
			permissions: {
				allowed_roles: [
					{
						name: "@everyone",
						id: "everyone"
					}
				],
				banned_roles: [],
				banned_channels: [],
				banned_users: [],
				required_permissions: []
			},
			error: "",
			ephemeral: false
		}
	},
	{
		name: "Pet",
		description: "Pet yourself",
		icon: faDog,
		data: {
			name: "pet",
			id: "",
			description: "Pet yourself with this command!",
			options: [],
			actions: [
				{
					type: "embed",
					embed: {
						title: "pet",
						description: "[Image](https://api.popcat.xyz/pet?image={user_icon})",
						image: "",
						color: "#eeedf3",
						link: "https://api.popcat.xyz/pet?image={user_icon}"
					},
					emojis: [],
					validated: true,
					linked: true
				}
			],
			permissions: {
				allowed_roles: [
					{
						name: "@everyone",
						id: "everyone"
					}
				],
				banned_roles: [],
				banned_channels: [],
				banned_users: [],
				required_permissions: []
			},
			ephemeral: false
		}
	},
	{
		name: "Verify",
		description: "Verify someone in the server",
		icon: faLockOpen,
		data: {
			name: "verify",
			id: "",
			description: "Verify yourself into the server!",
			options: [],
			actions: [
				{
					type: "dm_response",
					response_options: {
						response: "{user}, you have been verified in **{server}**!",
						emojis: ["✅"]
					},
					validated: true,
					linked: true
				},
				{
					type: "plain_text",
					response: "{user}, you have been verified!",
					validated: true,
					linked: true,
					emojis: ["✅"]
				},
				{
					roles: [
						{
							id: "932791352658387014",
							name: "new role",
							color: 0
						}
					],
					type: "role_add",
					target: "trigger",
					validated: true,
					linked: true
				},
				{
					type: "targeted_response",
					response_options: {
						response: "{user} is now verified!",
						emojis: ["ℹ️"]
					},
					target: {
						id: "944542232839585898",
						name: "general"
					},
					validated: true,
					linked: true
				}
			],
			permissions: {
				allowed_roles: [
					{
						name: "@everyone",
						id: "everyone"
					}
				],
				banned_roles: [],
				banned_channels: [],
				banned_users: [],
				required_permissions: []
			},
			ephemeral: true
		}
	}
];

export const TIMEZONES = [
	{
		label: "(GMT-11:00) Midway Island",
		value: "Pacific/Midway"
	},
	{
		label: "(GMT-11:00) Samoa",
		value: "US/Samoa"
	},
	{
		label: "(GMT-10:00) Hawaii",
		value: "US/Hawaii"
	},
	{
		label: "(GMT-09:00) Alaska",
		value: "US/Alaska"
	},
	{
		label: "(GMT-08:00) Pacific Time (US & Canada)",
		value: "US/Pacific"
	},
	{
		label: "(GMT-08:00) Tijuana",
		value: "America/Tijuana"
	},
	{
		label: "(GMT-07:00) Arizona",
		value: "US/Arizona"
	},
	{
		label: "(GMT-07:00) Mountain Time (US & Canada)",
		value: "US/Mountain"
	},
	{
		label: "(GMT-07:00) Chihuahua",
		value: "America/Chihuahua"
	},
	{
		label: "(GMT-07:00) Mazatlan",
		value: "America/Mazatlan"
	},
	{
		label: "(GMT-06:00) Mexico City",
		value: "America/Mexico_City"
	},
	{
		label: "(GMT-06:00) Monterrey",
		value: "America/Monterrey"
	},
	{
		label: "(GMT-06:00) Saskatchewan",
		value: "Canada/Saskatchewan"
	},
	{
		label: "(GMT-06:00) Central Time (US & Canada)",
		value: "US/Central"
	},
	{
		label: "(GMT-05:00) Eastern Time (US & Canada)",
		value: "US/Eastern"
	},
	{
		label: "(GMT-05:00) Indiana (East)",
		value: "US/East-Indiana"
	},
	{
		label: "(GMT-05:00) Bogota",
		value: "America/Bogota"
	},
	{
		label: "(GMT-05:00) Lima",
		value: "America/Lima"
	},
	{
		label: "(GMT-04:30) Caracas",
		value: "America/Caracas"
	},
	{
		label: "(GMT-04:00) Atlantic Time (Canada)",
		value: "Canada/Atlantic"
	},
	{
		label: "(GMT-04:00) La Paz",
		value: "America/La_Paz"
	},
	{
		label: "(GMT-04:00) Santiago",
		value: "America/Santiago"
	},
	{
		label: "(GMT-03:30) Newfoundland",
		value: "Canada/Newfoundland"
	},
	{
		label: "(GMT-03:00) Buenos Aires",
		value: "America/Buenos_Aires"
	},
	{
		label: "(GMT-03:00) Greenland",
		value: "Greenland"
	},
	{
		label: "(GMT-02:00) Stanley",
		value: "Atlantic/Stanley"
	},
	{
		label: "(GMT-01:00) Azores",
		value: "Atlantic/Azores"
	},
	{
		label: "(GMT-01:00) Cape Verde Is.",
		value: "Atlantic/Cape_Verde"
	},
	{
		label: "(GMT) Casablanca",
		value: "Africa/Casablanca"
	},
	{
		label: "(GMT) Dublin",
		value: "Europe/Dublin"
	},
	{
		label: "(GMT) Lisbon",
		value: "Europe/Lisbon"
	},
	{
		label: "(GMT) London",
		value: "Europe/London"
	},
	{
		label: "(GMT) Monrovia",
		value: "Africa/Monrovia"
	},
	{
		label: "(GMT+01:00) Amsterdam",
		value: "Europe/Amsterdam"
	},
	{
		label: "(GMT+01:00) Belgrade",
		value: "Europe/Belgrade"
	},
	{
		label: "(GMT+01:00) Berlin",
		value: "Europe/Berlin"
	},
	{
		label: "(GMT+01:00) Bratislava",
		value: "Europe/Bratislava"
	},
	{
		label: "(GMT+01:00) Brussels",
		value: "Europe/Brussels"
	},
	{
		label: "(GMT+01:00) Budapest",
		value: "Europe/Budapest"
	},
	{
		label: "(GMT+01:00) Copenhagen",
		value: "Europe/Copenhagen"
	},
	{
		label: "(GMT+01:00) Ljubljana",
		value: "Europe/Ljubljana"
	},
	{
		label: "(GMT+01:00) Madrid",
		value: "Europe/Madrid"
	},
	{
		label: "(GMT+01:00) Paris",
		value: "Europe/Paris"
	},
	{
		label: "(GMT+01:00) Prague",
		value: "Europe/Prague"
	},
	{
		label: "(GMT+01:00) Rome",
		value: "Europe/Rome"
	},
	{
		label: "(GMT+01:00) Sarajevo",
		value: "Europe/Sarajevo"
	},
	{
		label: "(GMT+01:00) Skopje",
		value: "Europe/Skopje"
	},
	{
		label: "(GMT+01:00) Stockholm",
		value: "Europe/Stockholm"
	},
	{
		label: "(GMT+01:00) Vienna",
		value: "Europe/Vienna"
	},
	{
		label: "(GMT+01:00) Warsaw",
		value: "Europe/Warsaw"
	},
	{
		label: "(GMT+01:00) Zagreb",
		value: "Europe/Zagreb"
	},
	{
		label: "(GMT+02:00) Athens",
		value: "Europe/Athens"
	},
	{
		label: "(GMT+02:00) Bucharest",
		value: "Europe/Bucharest"
	},
	{
		label: "(GMT+02:00) Cairo",
		value: "Africa/Cairo"
	},
	{
		label: "(GMT+02:00) Harare",
		value: "Africa/Harare"
	},
	{
		label: "(GMT+02:00) Helsinki",
		value: "Europe/Helsinki"
	},
	{
		label: "(GMT+02:00) Istanbul",
		value: "Europe/Istanbul"
	},
	{
		label: "(GMT+02:00) Jerusalem",
		value: "Asia/Jerusalem"
	},
	{
		label: "(GMT+02:00) Kyiv",
		value: "Europe/Kiev"
	},
	{
		label: "(GMT+02:00) Minsk",
		value: "Europe/Minsk"
	},
	{
		label: "(GMT+02:00) Riga",
		value: "Europe/Riga"
	},
	{
		label: "(GMT+02:00) Sofia",
		value: "Europe/Sofia"
	},
	{
		label: "(GMT+02:00) Tallinn",
		value: "Europe/Tallinn"
	},
	{
		label: "(GMT+02:00) Vilnius",
		value: "Europe/Vilnius"
	},
	{
		label: "(GMT+03:00) Baghdad",
		value: "Asia/Baghdad"
	},
	{
		label: "(GMT+03:00) Kuwait",
		value: "Asia/Kuwait"
	},
	{
		label: "(GMT+03:00) Nairobi",
		value: "Africa/Nairobi"
	},
	{
		label: "(GMT+03:00) Riyadh",
		value: "Asia/Riyadh"
	},
	{
		label: "(GMT+03:00) Moscow",
		value: "Europe/Moscow"
	},
	{
		label: "(GMT+03:30) Tehran",
		value: "Asia/Tehran"
	},
	{
		label: "(GMT+04:00) Baku",
		value: "Asia/Baku"
	},
	{
		label: "(GMT+04:00) Volgograd",
		value: "Europe/Volgograd"
	},
	{
		label: "(GMT+04:00) Muscat",
		value: "Asia/Muscat"
	},
	{
		label: "(GMT+04:00) Tbilisi",
		value: "Asia/Tbilisi"
	},
	{
		label: "(GMT+04:00) Yerevan",
		value: "Asia/Yerevan"
	},
	{
		label: "(GMT+04:30) Kabul",
		value: "Asia/Kabul"
	},
	{
		label: "(GMT+05:00) Karachi",
		value: "Asia/Karachi"
	},
	{
		label: "(GMT+05:00) Tashkent",
		value: "Asia/Tashkent"
	},
	{
		label: "(GMT+05:30) Kolkata",
		value: "Asia/Kolkata"
	},
	{
		label: "(GMT+05:45) Kathmandu",
		value: "Asia/Kathmandu"
	},
	{
		label: "(GMT+06:00) Ekaterinburg",
		value: "Asia/Yekaterinburg"
	},
	{
		label: "(GMT+06:00) Almaty",
		value: "Asia/Almaty"
	},
	{
		label: "(GMT+06:00) Dhaka",
		value: "Asia/Dhaka"
	},
	{
		label: "(GMT+07:00) Novosibirsk",
		value: "Asia/Novosibirsk"
	},
	{
		label: "(GMT+07:00) Bangkok",
		value: "Asia/Bangkok"
	},
	{
		label: "(GMT+07:00) Jakarta",
		value: "Asia/Jakarta"
	},
	{
		label: "(GMT+08:00) Krasnoyarsk",
		value: "Asia/Krasnoyarsk"
	},
	{
		label: "(GMT+08:00) Chongqing",
		value: "Asia/Chongqing"
	},
	{
		label: "(GMT+08:00) Hong Kong",
		value: "Asia/Hong_Kong"
	},
	{
		label: "(GMT+08:00) Kuala Lumpur",
		value: "Asia/Kuala_Lumpur"
	},
	{
		label: "(GMT+08:00) Perth",
		value: "Australia/Perth"
	},
	{
		label: "(GMT+08:00) Singapore",
		value: "Asia/Singapore"
	},
	{
		label: "(GMT+08:00) Taipei",
		value: "Asia/Taipei"
	},
	{
		label: "(GMT+08:00) Ulaan Bataar",
		value: "Asia/Ulaanbaatar"
	},
	{
		label: "(GMT+08:00) Urumqi",
		value: "Asia/Urumqi"
	},
	{
		label: "(GMT+09:00) Irkutsk",
		value: "Asia/Irkutsk"
	},
	{
		label: "(GMT+09:00) Seoul",
		value: "Asia/Seoul"
	},
	{
		label: "(GMT+09:00) Tokyo",
		value: "Asia/Tokyo"
	},
	{
		label: "(GMT+09:30) Adelaide",
		value: "Australia/Adelaide"
	},
	{
		label: "(GMT+09:30) Darwin",
		value: "Australia/Darwin"
	},
	{
		label: "(GMT+10:00) Yakutsk",
		value: "Asia/Yakutsk"
	},
	{
		label: "(GMT+10:00) Brisbane",
		value: "Australia/Brisbane"
	},
	{
		label: "(GMT+10:00) Canberra",
		value: "Australia/Canberra"
	},
	{
		label: "(GMT+10:00) Guam",
		value: "Pacific/Guam"
	},
	{
		label: "(GMT+10:00) Hobart",
		value: "Australia/Hobart"
	},
	{
		label: "(GMT+10:00) Melbourne",
		value: "Australia/Melbourne"
	},
	{
		label: "(GMT+10:00) Port Moresby",
		value: "Pacific/Port_Moresby"
	},
	{
		label: "(GMT+10:00) Sydney",
		value: "Australia/Sydney"
	},
	{
		label: "(GMT+11:00) Vladivostok",
		value: "Asia/Vladivostok"
	},
	{
		label: "(GMT+12:00) Magadan",
		value: "Asia/Magadan"
	},
	{
		label: "(GMT+12:00) Auckland",
		value: "Pacific/Auckland"
	},
	{
		label: "(GMT+12:00) Fiji",
		value: "Pacific/Fiji"
	}
];
