import React, { Component } from 'react';
import { connect } from 'react-redux';

// import 'emoji-mart/css/emoji-mart.css';
import Picker from '@emoji-mart/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

export class EmojiAdder extends Component {

    constructor(props) {
        super(props);

        this.node = React.createRef();

        this.state = {
            showDropDown: false
        };
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }


    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (this.node && !this.node.current.contains(event.target)) {
            this.setState({ showDropDown: false });
        }
    };




    renderEmojiPicker = () => {
        return (
            <div className="react-role-emoji-container" >
                <Picker onEmojiSelect={this.onEmojiSelect} title="Pick your emoji..."></Picker>
            </div>

        );
    };

    onEmojiSelect = (emoji) => {
        const activeEmojis = [...this.props.emojis];
        activeEmojis.push(emoji.native);
        this.props.update(activeEmojis);
        this.setState({ showDropDown: false });
    };

    removeEmoji = (index) => {
        const activeEmojis = [...this.props.emojis];
        activeEmojis.splice(index, 1);
        this.props.update(activeEmojis);
    };

    renderEmojis = () => {
        var items = [];
        this.props.emojis.forEach((emoji, index) => {
            items.push(
                <li className="role-adder-role" key={index}>

                    <FontAwesomeIcon onClick={(e) => { this.removeEmoji(index); }} icon={faTimesCircle} />
                    <span class="">{emoji}</span>
                </li>
            );
        });
        return items;
    };


    render() {

        return (

            <div className="role-adder" ref={this.node}>
                {this.state.showDropDown == true ? this.renderEmojiPicker() : null}
                <ul>
                    {this.renderEmojis()}
                    <li style={{ display: "flex", alignItems: "center", marginLeft: "15px" }}>
                        <div className="role-adder-container">
                            {this.props.dropdown != false ?
                                <img src={process.env.PUBLIC_URL + "/icons/feather/plus-circle.svg"} onClick={() => { this.setState({ showDropDown: !this.state.showDropDown }); }}></img>
                                : null
                            }
                            {/* <img src={process.env.PUBLIC_URL + "/icons/feather/plus-circle.svg"} onClick={(e) =>{this.setState({showDropDown:true})}}></img> */}

                        </div>

                    </li>
                </ul>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EmojiAdder);