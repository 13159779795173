import React, { Component } from "react";
import { connect } from "react-redux";
import { CheckoutCart } from "@chargebee/chargebee-js-react-wrapper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import server from "../../../api/server";

import SectionHeader from "../elements/SectionHeader";
import PremiumTable from "../elements/PremiumTable";
import ModuleHeader from "../elements/ModuleHeader";
import Toggle from "../elements/Toggle";
import Command from "../elements/Command";
import PremiumPlan from "../elements/PremiumPlan";
import PremiumPlan2 from "../elements/PremiumPlan2";
import { PremiumFeature } from "../elements/PremiumFeature";
import { Accordion } from "../elements/Accordion";
import "../styles/premium.css";
import { setAuth, upgradeSubscription, updateProcessing } from "../../../actions";
import ReactGA from "react-ga4";
import Dashboard from "../Dashboard";
import { Loader } from "../../Loader";
import { Cookies } from "react-cookie";
import Select from "../elements/Select";
import axios from "axios";
import history from "../../../history";

var STEPS = [1, 5, 10, 25, 50, 100];

export class Premium extends Component {
	constructor(props) {
		super(props);

		this.state = {
			chargebeeReady: false,
			instance: null,
			cart: null,
			portal: null,
			currency: "USD",
			processing: true,
			plan: process.env.REACT_APP_YEARLY,
			ga_price: 39.99,
			ga_plan: "Yearly",
			selected: "Yearly",
			type: true,
			quantity: 1,
			stepIndex: 0,
			loading: false,
			version: "A",
			billing_type: false,
			// Sale Stuff
			sale: false,
			sale_amount: this.props.current_sale ? (100 - this.props.current_sale.percent) / 100 : 0,
			sale_coupon: this.props.current_sale ? this.props.current_sale.code : ""
		};
	}

	componentDidMount() {
		if (this.props.current_sale == null) {
			history.push("/dashboard/modules");
		}
		// console.log(window, "WINDOW");

		try {
			if (window.sa_event) {
				window.sa_event("visit_premium_page");
			}
		} catch (e) { }

		var version = "A";
		const cookies = new Cookies();
		if (cookies.get("premium_version") == "B") {
			version = "B";
		}
		this.setState({ version: version });

		document.body.scrollTop = 0; // For Safari
		document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
		const el = document.createElement("script");
		el.onload = () => {
			window.Chargebee.init({
				site: "botghost"
			});
			window.Chargebee.registerAgain();
			this.setState({ chargebeeReady: true });
		};
		el.setAttribute("src", "https://js.chargebee.com/v2/chargebee.js");
		document.body.appendChild(el);
		var plan = process.env.REACT_APP_YEARLY;

		this.setState({ plan: plan });
		this.setCurrency();
		this.props.setAuth();

		if (this.props.user.id == undefined) {
			history.push("/dashboard/modules");
		}
		this.countPremiumView();
	}

	countPremiumView = async () => {
		var response = await server.post("/dashboard/premiumPageCount");
	};

	componentDidUpdate(prevProps, prevState) {
		if (!this.props.auth) {
			this.props.setAuth();
		}
	}

	setCurrency = async () => {
		var response = axios
			.get("https://ipapi.co/json")
			.then((results) => {
				if ("data" in results && "currency" in results.data && results.data.currency == "EUR") {
					this.setState({ currency: "EURO" });
				}
			})
			.catch((e) => {
				// console.log(e, "aRHUASUHAHUSDHASDU");
				console.error(e);
			});
	};

	typeChange = () => { };

	setPortalCallbacks = (portal) => {
		portal.callbacks.subscriptionChanged = (data) => {
			// console.log("SUB CHANGE");
			this.setState({ loading: true });
			setTimeout(() => {
				window.location.replace("/dashboard/settings");
			}, 10000);
		};

		portal.callbacks.loaded = () => {
			// console.log("PORTAL LOADED");
		};
		// console.log(portal.callbacks, portal, "CALLBACKS");
		return portal;
	};

	setCurrency = async () => {
		var response = axios
			.get("https://ipapi.co/json")
			.then((results) => {
				if ("data" in results && "currency" in results.data && results.data.currency == "EUR") {
					this.setState({ currency: "EURO" });
				}
			})
			.catch((e) => {
				// console.log(e, "aRHUASUHAHUSDHASDU");
				console.error(e);
			});
	};

	typeChange = () => { };

	setCallBacks = (cart) => {
		cart.callbacks.close = async () => {
			this.props.updateProcessing(false);
			var response = await server.post("/dashboard/abandondedcart", { email: this.props.user.email });
		};

		cart.callbacks.success = (pageId) => {
			this.props.updateProcessing(true);
			this.checkoutSuccess(pageId);
		};

		cart.callbacks.step = (step) => {
			ReactGA.initialize("G-WHCMYGFHKX");
			ReactGA.event({
				category: "Checkout Step",
				label: step,
				action: "New Dashboard"
			});
		};
		return cart;
	};

	createInstance = () => {
		if (this.state.chargebeeReady && this.state.instance == null) {
			var cbInstance = window.Chargebee.getInstance();
			var cbPortal = cbInstance.createChargebeePortal();
			cbPortal = this.setPortalCallbacks(cbPortal);

			var cart = cbInstance.getCart();
			var customer = { cf_user_id: this.props.user.id, cf_premium_version: this.state.version };
			cart.setCustomer(customer);
			// console.log(cart, "CART");
			cart = this.setCallBacks(cart);
			this.setState({ instance: cbInstance, cart: cart, portal: cbPortal });
		}
	};

	toggleChange = () => {
		if (this.state.type == false) {
			var plan = process.env.REACT_APP_YEARLY_ULTIMATE;
			this.setState({ type: true, plan: plan, selected: "Yearly" });
		} else {
			var plan = process.env.REACT_APP_YEARLY;
			this.setState({ type: false, plan: plan, selected: "Yearly" });
		}
	};

	change = () => {
		this.state.portal.open({
			subscriptionChanged: () => {
				// console.log("SUB CHANGE1");
			}
		});
	};

	checkout = (plan, price, quantity) => {
		this.props.updateProcessing(true);
		let product = this.state.instance.initializeProduct(plan);
		// var price = this.state.type == false ? norm_price : ult_price;
		if (this.state.sale && this.state.type) {
			product.addCoupon(this.state.sale_coupon);
		}

		// if (this.state.sale) {
		// 	product.addCoupon(this.state.sale_coupon);
		// }
		const cookies = new Cookies();

		const referred_by = cookies.get("referred_by");
		if (referred_by != undefined) {
			product.addCoupon("REFERRAL");
		}

		if (quantity) {
			product.setPlanQuantity(quantity);
		}
		this.state.cart.replaceProduct(product);
		this.state.cart.proceedToCheckout();
		this.setState({ ga_plan: plan, ga_price: price });
		try {
			if (window.sa_loaded) {
				window.sa_event("checkout");
			}
		} catch (e) { }
	};

	checkoutSuccess = async (hostedPageId) => {
		ReactGA.initialize("G-WHCMYGFHKX");

		ReactGA.plugin.require("ecommerce");
		ReactGA.plugin.execute("ecommerce", "addItem", {
			id: this.props.bot.id,
			name: this.state.ga_plan,
			sku: "--", // seems to be required
			price: this.state.ga_price,
			category: "Subscriptions",
			quantity: "1"
		});
		ReactGA.plugin.execute("ecommerce", "addTransaction", {
			id: this.props.bot.id, // the same as for addItem to connect them
			revenue: this.state.ga_price // obviously it's price * quantity
		});
		ReactGA.plugin.execute("ecommerce", "send");
		ReactGA.plugin.execute("ecommerce", "clear");

		if (window.sa_loaded) {
			window.sa_event("sale");
		}

		ReactGA.plugin.execute("ecommerce", "send");
		this.setState({ loading: true });
		// const response =await  server.post("/dashboard/chargebee",{page_id:hostedPageId,bot:this.props.bot,user:this.props.user});
		setTimeout(() => {
			window.location.replace("/dashboard/settings");
		}, 10000);
	};

	renderProcessing = () => {
		if ((this.state.processing = true)) {
			return <Loader />;
		} else {
			return null;
		}
	};

	renderPlan = (type) => {
		var code = "";
		if (type == "lifetime") {
			// if (this.state.type == false) {
			code = "REACT_APP_LIFETIME";
			// } else {
			// code = "REACT_APP_LIFETIME_ULTIMATE";
			// }
		} else if (type == "yearly") {
			// if (this.state.type == false) {
			code = "REACT_APP_YEARLY";
			// }
			// else {
			//   code = "REACT_APP_YEARLY_ULTIMATE";
			// }
		} else {
			// if (this.state.type == false) {
			code = "REACT_APP_MONTHLY";
			// }
			// else {
			//   code = "REACT_APP_MONTHLY_ULTIMATE";
			// }
		}
		return process.env[`${code}${this.state.currency == "USD" ? "" : "_EURO"}`];
	};

	renderVersion = () => {
		// if (this.state.version == "A") {
		//   return (
		//     <section>
		//       <SectionHeader
		//         title="Upgrade"
		//         pretitle="Premium"
		//       ></SectionHeader>

		//       <div className="premium-plan-quantity">
		//         <div style={{
		//           display: "flex",
		//           flexDirection: "column",
		//           justifyContent: "center",
		//         }}>
		//           <span>Number of Bots</span>
		//           <div class="input-group">
		//             <input type="button" value="-" class="button-minus" data-field="quantity" onClick={() => {
		//               var stepIndex = this.state.stepIndex - 1;
		//               if (stepIndex >= 0) {
		//                 this.setState({ quantity: STEPS[stepIndex], stepIndex: stepIndex });
		//                 ReactGA.initialize("G-WHCMYGFHKX");
		//                 ReactGA.event({
		//                   category: "Quantity Change",
		//                   label: STEPS[stepIndex],
		//                   action: "click",
		//                 });
		//               }

		//             }} />
		//             <input type="number" disabled step="1" max="" value={this.state.quantity} name="quantity" class="quantity-field" />
		//             <input type="button" value="+" class="button-plus" data-field="quantity" onClick={() => {
		//               if (this.state.stepIndex < STEPS.length - 1) {
		//                 var stepIndex = this.state.stepIndex + 1;
		//                 this.setState({ quantity: STEPS[stepIndex], stepIndex: stepIndex });
		//                 ReactGA.initialize("G-WHCMYGFHKX");
		//                 ReactGA.event({
		//                   category: "Quantity Change",
		//                   label: STEPS[stepIndex],
		//                   action: "click",
		//                 });
		//               }
		//             }} />
		//           </div>
		//         </div>
		//       </div>

		//       <div className="premium-plains mt-15">
		//         <div className="row prem-row">

		//           <PremiumPlan
		//             name="Yearly"
		//             selected={this.state.selected == "Yearly"}
		//             type={this.state.type == false ? "single" : "unlimited"}
		//             select={(name, plan, ga_price) => {
		//               this.setState({ selected: name, plan: plan, ga_price });
		//             }}
		//             quantity={this.state.quantity}
		//             price={this.state.currency == "USD" ? "5.00" : "5.00"}
		//             ultimate_price={
		//               this.state.currency == "USD" ? "10.00" : "10.00"
		//             }
		//             ga_price="60"
		//             ga_ultimate_price="120"
		//             checkout={this.checkout}
		//             change={this.change}
		//             plan={this.renderPlan("yearly")}
		//             normal_plan={process.env.REACT_APP_YEARLY}
		//             ultimate_plan={process.env.REACT_APP_YEARLY_ULTIMATE}
		//             symbol={this.state.currency == "USD" ? "$" : "€"}
		//             description="per year"
		//             tag="Save $35"
		//             billing={`${this.state.currency == "USD" ? "$60" : "€60"
		//               }* billed yearly. 7-day money-back guarantee.`}
		//             ultimate_billing={`${this.state.currency == "USD" ? "$120" : "€120"
		//               }* billed yearly. 7-day money-back guarantee.`}
		//             version={this.state.version}
		//           ></PremiumPlan>

		//           <PremiumPlan
		//             name="Lifetime"
		//             most_popular={true}
		//             selected={this.state.selected == "Lifetime"}
		//             type={this.state.type == false ? "single" : "unlimited"}
		//             select={(name, plan, ga_price) => {
		//               this.setState({ selected: name, plan: plan, ga_price });
		//             }}
		//             quantity={this.state.quantity}
		//             checkout={this.checkout}
		//             change={this.change}
		//             price={this.state.currency == "USD" ? "99.99" : "99.99"}
		//             ultimate_price={
		//               this.state.currency == "USD" ? "149.99" : "149.99"
		//             }
		//             symbol={this.state.currency == "USD" ? "$" : "€"}
		//             sale={false}
		//             sale_price="99"
		//             ga_price="99.99"
		//             ga_ultimate_price="149.99"
		//             plan={this.renderPlan("lifetime")}
		//             description="One-time Payment"
		//             tag="Most Popular"
		//             billing={`${this.state.currency == "USD" ? "$99.99" : "€99.99"
		//               }* paid once. 7-day money-back guarantee.`}
		//             ultimate_billing={`${this.state.currency == "USD" ? "$149.99" : "€149.99"
		//               }* paid once. 7-day money-back guarantee.`}
		//             version={this.state.version}
		//           ></PremiumPlan>
		//           {/* <PremiumPlan
		//                       name="6-months"
		//                       checkout={this.checkout}
		//                       price="7"
		//                       ga_price="42"
		//                       ga_ultimate_price="126"
		//                       normal_plan={process.env.REACT_APP_HALF_YEARLY}
		//                       ultimate_plan={process.env.REACT_APP_HALF_YEARLY_ULTIMATE}
		//                       description="per month"
		//                       tag="Save 35%"
		//                       billing="$42* billed half yearly. 7-day money-back guarantee."
		//                   ></PremiumPlan> */}

		//           <PremiumPlan
		//             name="Monthly"
		//             selected={this.state.selected == "Monthly"}
		//             type={this.state.type == false ? "single" : "unlimited"}
		//             select={(name, plan, ga_price) => {
		//               this.setState({ selected: name, plan: plan, ga_price });
		//             }}
		//             quantity={this.state.quantity}
		//             checkout={this.checkout}
		//             change={this.change}
		//             price={this.state.currency == "USD" ? "11.95" : "11.95"}
		//             ultimate_price={
		//               this.state.currency == "USD" ? "30.00" : "30.00"
		//             }
		//             ga_price="11.95"
		//             ga_ultimate_price="30"
		//             plan={this.renderPlan("monthly")}
		//             symbol={this.state.currency == "USD" ? "$" : "€"}
		//             normal_plan={process.env.REACT_APP_MONTHLY}
		//             ultimate_plan={process.env.REACT_APP_MONTHLY_ULTIMATE}
		//             description="per month"
		//             tag="Save 0%"
		//             billing={`${this.state.currency == "USD" ? "$11.95" : "€11.95"
		//               }* billed monthly. 7-day money-back guarantee.`}
		//             ultimate_billing={`${this.state.currency == "USD" ? "$30" : "€30"
		//               }* billed monthly. 7-day money-back guarantee.`}
		//             version={this.state.version}
		//           ></PremiumPlan>
		//         </div>
		//       </div>

		//       <div className="checkout-section">
		//         {/* <button className="btn btn-red" onClick={(e) =>{this.checkout()}}>Checkout</button> */}
		//       </div>
		//     </section>
		//   );
		// } else {
		return (
			<section>
				<SectionHeader title="Upgrade" pretitle="Premium"></SectionHeader>

				<div className="premiumToggleContainer">
					<span>Single Bot</span>
					<Toggle
						premiumToggle={true}
						value={this.state.type}
						update={(value) => {
							this.setState({ type: !this.state.type });
						}}
					/>
					<span>Unlimited Bots</span>
				</div>

				<div className="premium-plains mt-15">
					<div className="row prem-row">
						<PremiumPlan2
							name={"Yearly"}
							selected={this.state.selected == "Yearly"}
							type={this.state.type == false ? "single" : "unlimited"}
							select={(name, plan, ga_price) => {
								this.setState({ selected: name, plan: plan, ga_price });
							}}
							quantity={25}
							price={this.state.type == false ? "49.99" : "79.99"}
							ga_price="49.99"
							ga_ultimate_price="79.99"
							checkout={this.checkout}
							change={this.change}
							plan={this.state.type ? this.renderPlan("lifetime") : this.renderPlan("monthly")}
							normal_plan={process.env.REACT_APP_YEARLY}
							ultimate_plan={process.env.REACT_APP_YEARLY_ULTIMATE}
							symbol={this.state.currency == "USD" ? "$" : "€"}
							description="per year"
							tag="Save $35"
							billing={`${this.state.currency == "USD" ? "$49.99" : "€79.99"}* billed yearly. 7-day money-back guarantee.`}
							ultimate_billing={`${this.state.currency == "USD" ? "$79.99" : "€79.99"}* billed yearly. 7-day money-back guarantee.`}
							version={this.state.version}
							sale={false}
							// sale={true}
							sale_amount={this.state.sale_amount}
						></PremiumPlan2>

						<PremiumPlan2
							name={"Lifetime"}
							most_popular={true}
							selected={this.state.selected == "Lifetime"}
							type={this.state.type == false ? "single" : "unlimited"}
							select={(name, plan, ga_price) => {
								this.setState({ selected: name, plan: plan, ga_price });
							}}
							quantity={10}
							checkout={this.checkout}
							change={this.change}
							price={this.state.type == false ? "79.99" : "99.99"}
							symbol={this.state.currency == "USD" ? "$" : "€"}
							sale_price="99"
							ga_price="99.99"
							ga_ultimate_price="149.99"
							plan={this.state.type ? this.renderPlan("lifetime") : this.renderPlan("monthly")}
							description="One-time Payment"
							tag="Most Popular"
							billing={`${this.state.currency == "USD" ? "$79.99" : "€79.99"}* paid once. 7-day money-back guarantee.`}
							ultimate_billing={`${this.state.currency == "USD" ? "$99.99" : "€99.99"}* paid once. 7-day money-back guarantee.`}
							version={this.state.version}
							sale={false}
							// sale={true}
							sale_amount={this.state.sale_amount}
						></PremiumPlan2>
						{/* <PremiumPlan
                          name="6-months"
                          checkout={this.checkout}
                          price="7"
                          ga_price="42"
                          ga_ultimate_price="126"
                          normal_plan={process.env.REACT_APP_HALF_YEARLY}
                          ultimate_plan={process.env.REACT_APP_HALF_YEARLY_ULTIMATE}
                          description="per month"
                          tag="Save 35%"
                          billing="$42* billed half yearly. 7-day money-back guarantee."
                      ></PremiumPlan> */}

						<PremiumPlan2
							name={"Monthly"}
							selected={this.state.selected == "Monthly"}
							type={this.state.type == false ? "single" : "unlimited"}
							select={(name, plan, ga_price) => {
								this.setState({ selected: name, plan: plan, ga_price });
							}}
							quantity={1}
							checkout={this.checkout}
							change={this.change}
							price={this.state.type == false ? "7.99" : "17.99"}
							ga_price="11.95"
							ga_ultimate_price="30"
							plan={this.state.type ? this.renderPlan("lifetime") : this.renderPlan("monthly")}
							symbol={this.state.currency == "USD" ? "$" : "€"}
							normal_plan={process.env.REACT_APP_MONTHLY}
							ultimate_plan={process.env.REACT_APP_MONTHLY_ULTIMATE}
							description="per month"
							tag="Save 0%"
							billing={`${this.state.currency == "USD" ? "$7.99" : "€7.99"}* billed monthly. 7-day money-back guarantee.`}
							ultimate_billing={`${this.state.currency == "USD" ? "$12.99" : "€12.99"}* billed monthly. 7-day money-back guarantee.`}
							version={this.state.version}
							sale={false}
							// sale={true}
							// sale_amount={this.state.sale_amount}
							// sale={true}
							sale_amount={this.state.sale_amount}
						></PremiumPlan2>
					</div>
				</div>
			</section>
		);
		// }
	};
	render() {
		if (this.state.loading == true) {
			return <Loader processing={true} />;
		} else {
			return (
				<Dashboard>
					<ToastContainer></ToastContainer>
					{this.createInstance()}
					{/* {this.renderProcessing()} */}
					{this.state.chargebeeReady ? (
						<div>
							<div className="premium-header m-mt-110">
								<img className="brand" src={process.env.PUBLIC_URL + "/images/icons/botghostpremium.png"}></img>
							</div>
							{this.renderVersion()}

							<section>
								<SectionHeader title="Features" pretitle="Premium" />

								<PremiumTable />
							</section>

							{/* <section>
                        <SectionHeader title="Features" pretitle="Premium" ></SectionHeader>
                        <div className="premium-features">
    
                            <div className="row premium-features-container">
    
    
                                <PremiumFeature
                                    icon="customcommands.png"
                                    title="Remove all BotGhost Advertising."
                                    info="Remove all BotGhost branding from your bot."
                                />
                                                            <PremiumFeature
                                    icon="discord.png"
                                    title="Premium Role"
                                    info="Receieve a premium role and support in the BotGhost official Discord Server."
                                />
    
                                <PremiumFeature
                                    icon="unlock.png"
                                    title="Unlock all current and future features"
                                    info="Gain complete access to all current and future features."
                                />
    
                                <PremiumFeature
                                    icon="moneyback.png"
                                    title="7 day money back guarantee"
                                    info="Rest assured with a 7 day money back guarantee; no questions asked."
                                />
    
                                <PremiumFeature
                                    icon="levels.png"
                                    title="Customize your Leaderboard"
                                    info="Individually customize your bots levels leaderboards."
                                />
    
                                <PremiumFeature
                                    icon="priority.png"
                                    title="Priority Hosting"
                                    info="Unlock superior performance compared to trial bots."
                                />
    
                                
    
    
                            </div>
                        </div>
    
                    </section> */}

							<section>
								<SectionHeader title="Frequently Asked Questions" pretitle="Premium"></SectionHeader>

								<div className="accordions">
									<Accordion title="What do I get from BotGhost premium?" description={`BotGhost premium allows you access to all current and future modules, you may also set your own status message, removing the "Made with botghost.com" message from your bot, premium bots also do not share hosting with bots that are on a free trial, meaning that they are detected more resources and are generally faster than their non premium counterparts.`}></Accordion>

									<Accordion title="Can I get a refund if I am not satisified?" description={`Of course! If you are not satisfied with your premium subscription simply contact us within 7 days and we will give you a full refund, no questions asked.`}></Accordion>

									<Accordion title="How do I stop my subscription?" description={`Simply go to settings and click 'Manage Subscription'. From there you can download your receipt and cancel your subscription, if you do decide to cancel your subscription, your bot will stay online for the entire period you payed for, this means you can buy a premium subscription and cancel it straight away if you only want one month of premium.`}></Accordion>

									<Accordion title="How many Discord Servers is my subscription valid for?" description={`Your premium subscription is valid for EVERY server that your bot is in, a premium subscription is valid for ONE bot only and an Unlimited subscription is valid for UNLIMITED bots.`}></Accordion>

									<Accordion title="What payment methods are supported?" description={`We support all credit cards, Paypal, Google Pay and Apple Pay, please note, that if your credit card does not work it may be due to your bank provider blocking online payments, in this case, please use PayPal to complete your transaction.`}></Accordion>

									<Accordion
										title="Is my credit card secure?"
										description={`
                               We do not store any credit cart information, all credit and debit card transactions are handled through Stripe, a payment processor which handles billions of dollars in online transactions every year, to learn more about their security, visit https://stripe.com/docs/security/stripe, similarly, we also never have access to your Paypal account information and your transactions with us area protected by PayPal's Purchase Protection.`}
									></Accordion>

									<Accordion title="How do I upgrade my bot after purchasing a subscription." description={`Once you have purchased a subscription, you will receive premium tokens based on how many bots you chose to upgrade, you then must go to 'Settings' and click 'Upgrade this Bot' to upgrade that bot to premium, if you wish to upgrade a different bot then the one you are currently editing, change bots by clicking the dropdown in the very top right of this page.`}></Accordion>

									<Accordion title="Is my bots hosting 24/7?" description={`Yes, all bots are hosted 24/7 on dedicated hosting, the only reason your bot may briefly go offline is due to maintenance or updates to the server. Free bots are switched offline after 7 days of inactivity. Premium bots are never switched offline.`}></Accordion>

									<Accordion title="Can I pay in a different currency?" description={`All prices are listed in USD or EURO depending on your location, you can still pay in your own currency however as both Stripe and Paypal will do the conversion for you.`}></Accordion>
								</div>
							</section>
						</div>
					) : null}
				</Dashboard>
			);
		}
	}
}

const mapStateToProps = (state) => ({
	bot: state.data.bot,
	user: state.data.user,
	auth: state.auth,
	current_sale: state.data.current_sale
});

const mapDispatchToProps = {
	upgradeSubscription,
	updateProcessing,
	setAuth
};

export default connect(mapStateToProps, mapDispatchToProps)(Premium);
