import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

const bold = /\*\*(.*)\*\*/gim;
const italics = /\*(.*)\*/gim;
const link = /\[(.*?)\]\((.*?)\)/gim;
const lineBreak = /\n$/gim;
const underline = /\_\_(.*)\_\_/gim;

export class MarkdownRender extends Component {

    parseMarkdown = (markdownText) => {
        const htmlText = markdownText
            .replace(bold, '<b>$1</b>')
            .replace(italics, '<i>$1</i>')
            .replace(link, "<a href='$2' className='embed-link'>$1</a>")
            .replace(lineBreak, '<br />')
            .replace(underline, '<u>$1</u>')

        return htmlText.trim()
    }
    render() {
        return (
            <>{ReactHtmlParser(this.parseMarkdown(this.props.text))}</>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(MarkdownRender)