import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faSyncAlt } from '@fortawesome/free-solid-svg-icons';

import { PERMISSIONS, CHANNEL_PERMISSIONS } from "../../../variables";
import RefreshBotData from './RefreshBotData';

export class RoleAdd extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showDropDown: false,
            search_term: "",
            items: [],
            addedItems: []
        };

    }

    componentDidMount() {
        // console.log(this.props);
        document.addEventListener("click", this.handleClick, true);
        if (this.props.type == "permissions") {
            this.setState({ items: PERMISSIONS });
        }
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.handleClick, true);
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log(this.state);
    }

    handleClick = (event) => {
        const domNode = ReactDOM.findDOMNode(this);
        if (!domNode || !domNode.contains(event.target)) {
            this.setState({ showDropDown: false });
        }
    };

    addItem = (item) => {
        const addedItems = [...this.props.items];
        // Check if exists;
        if (this.props.type == "permissions") {
            if (addedItems.includes(item)) return;
        } else {
            if (addedItems.find((i) => i.id == item.id)) return;
        }
        addedItems.push(item);
        this.props.update(addedItems, this.props.option);
    };

    addSlashCommandOption = (item) => {
        const addedItems = [...this.props.items];
        addedItems.push({
            variable: item.name,
            name: item.name
        });
        this.props.update(addedItems, this.props.option);
    };


    renderDropDown = () => {
        const items = [];
        if (this.props.bot_data == undefined && this.state.showDropDown == true) {
            return (
                <div className="role-adder-dropdown">
                    <div className="role-adder-dropdown-main">
                        <div className="role-adder-guild">
                            <span style={{ marginTop: "50px" }}>Please add your bot to a server and refresh bot data by clicking the <i class="bi bi-arrow-clockwise"></i> above. </span>
                        </div>
                    </div>
                </div>
            );
        }
        if (this.props.type == "permissions") {

            let permissions = this.props.subType && this.props.subType === "channel" ? CHANNEL_PERMISSIONS : PERMISSIONS;

            permissions.forEach((item, key) => {
                if (this.state.search_term == "" || (item.toLowerCase().includes(this.state.search_term.toLowerCase())))
                    items.push(
                        <div key={key} className="role-adder-item" onClick={(e) => {
                            this.addItem(item);
                            this.setState({ showDropDown: false, search_term: "" });
                        }}>
                            <span>{item}</span>
                        </div>
                    );
            });
        } else if (this.props.type == "members") {
            this.props.bot_data.forEach((guild, guildIndex) => {
                items.push(
                    <div key={`guildIndex${guildIndex}`} className="role-adder-guild">
                        <span>{guild.name}</span>
                    </div>
                );

                guild.members.forEach((member, key) => {
                    if (this.state.search_term == "" || member.name.toLowerCase().includes(this.state.search_term.toLowerCase())) {
                        items.push(
                            <div key={`${guildIndex} - ${key}`} className="role-adder-item" onClick={(e) => {
                                this.addItem(member);
                                this.setState({ showDropDown: false, search_term: "" });
                            }}>
                                <span>{member.name}</span>
                            </div>
                        );
                    }

                });
            });
        } else if (this.props.type == "channels") {
            this.props.bot_data.forEach((guild, guildIndex) => {
                items.push(
                    <div key={guildIndex} className="role-adder-guild">
                        <span>{guild.name}</span>
                    </div>
                );

                guild.channels.forEach((channel, key) => {
                    if (this.state.search_term == "" || channel.name.toLowerCase().includes(this.state.search_term.toLowerCase())) {
                        if ((this.props.channelFilter == undefined && (channel.type == 0)) || this.props.channelFilter && this.props.channelFilter.length > 0 && this.props.channelFilter.includes(channel.type)) {

                            items.push(
                                <div key={`${guildIndex} - ${key}`} className="role-adder-item" onClick={(e) => {
                                    this.addItem(channel);
                                    this.setState({ showDropDown: false, search_term: "" });
                                }}>
                                    <span>#{channel.name}</span>
                                </div>
                            );
                        }

                    }

                });
            });
        } else {
            this.props.bot_data.forEach((guild, guildIndex) => {
                items.push(
                    <div key={guildIndex} className="role-adder-guild">
                        <span>{guild.name}</span>
                    </div>
                );

                guild.roles.forEach((role, key) => {
                    if (this.state.search_term == "" || role.name.toLowerCase().includes(this.state.search_term.toLowerCase())) {
                        items.push(
                            <div key={`${guildIndex} - ${key}`} className="role-adder-item" onClick={(e) => {
                                this.addItem(role);
                                this.setState({ showDropDown: false, search_term: "" });
                            }}>
                                <span>{role.name}</span>
                            </div>
                        );
                    }

                });
            });
        }

        if (this.props.slash == true) {
            this.props.slash_options.forEach((slashoption, index) => {
                if (slashoption.type == "role") {
                    items.unshift(
                        <div key={`slashoption - ${index}`} className="role-adder-item" onClick={(e) => {
                            this.addSlashCommandOption(slashoption);
                            this.setState({ showDropDown: false, search_term: "" });
                        }}>
                            <span>{slashoption.name}</span>
                        </div>
                    );
                }
            });

            items.unshift(
                <div key={"SLASHCOMMAND"} className="role-adder-guild">
                    <span>Command Options</span>
                </div>
            );
        }


        if (this.state.showDropDown == true) {
            return (
                <div className="role-adder-dropdown" style={{ top: this.props.bottom == true ? "50px" : "-320px" }}>
                    <header>
                        <RefreshBotData absolute={true}></RefreshBotData>
                        <input type="text" value={this.state.search_term} onChange={(e) => { this.setState({ search_term: e.target.value }); }} placeholder="Search"></input>
                    </header>
                    <div className="role-adder-dropdown-main">
                        {
                            items.length == 0 || (this.props.slash == true && items.length == this.props.slash_options.length + 1) ?
                                <div style={{ padding: "15px" }}>
                                    <h3>Refresh Guild Data</h3>
                                    <p>Ensure that your bot is in a guild and click the <FontAwesomeIcon icon={faSyncAlt} color={"#fff"} /> above. </p>
                                </div>


                                : items
                        }
                    </div>
                </div>
            );
        } else {
            return (null);
        }
    };

    renderItems = () => {
        // types :roles,permissions,channels,members
        const items = [];
        this.props.items.forEach((item, index) => {
            if (this.props.type == "permissions" || this.props.type == "words") {
                items.push(
                    <li className="role-adder-role" key={index}>
                        <FontAwesomeIcon onClick={(e) => { this.removeRole(index); }} icon={faTimesCircle} />
                        <span class="">{item}</span>
                    </li>
                );
            } else if (this.props.type == "channels") {
                if ((this.props.channelFilter == undefined && (item.type == 0 || item.type == 4)) || this.props.channelFilter && this.props.channelFilter.length > 0 && this.props.channelFilter.includes(item.type)) {

                    items.push(
                        <li className="role-adder-role" key={index}>
                            <FontAwesomeIcon onClick={(e) => { this.removeRole(index); }} icon={faTimesCircle} />
                            <span class="">#{item.name}</span>
                        </li>
                    );
                }


            } else {
                if (item != undefined) {
                    items.push(
                        <li className="role-adder-role" key={index}>
                            <FontAwesomeIcon onClick={(e) => { this.removeRole(index); }} icon={faTimesCircle} />
                            <span class="">{item.name}</span>
                        </li>
                    );
                }

            }
        });


        return items;
    };

    removeRole = (index) => {
        const addedItems = [...this.props.items];
        addedItems.splice(index, 1);
        this.props.update(addedItems, this.props.option);
    };

    render() {
        return (
            <div className={`role-adder ${this.props.commandSaveFailed == true ? "command-required" : ""}`}>
                {this.state.showDropDown == true ? this.renderDropDown() : null}
                <ul>
                    {this.renderItems()}
                    <li style={{ display: "flex", alignItems: "center", marginLeft: "15px" }}>
                        <div className="role-adder-container">
                            {this.props.dropdown != false ?
                                <img src={process.env.PUBLIC_URL + "/icons/feather/plus-circle.svg"} onClick={(e) => { this.props.disabled != true ? this.setState({ showDropDown: true }) : this.setState({ showDropDown: false }); }}></img>
                                : null
                            }
                            {/* <img src={process.env.PUBLIC_URL + "/icons/feather/plus-circle.svg"} onClick={(e) =>{this.setState({showDropDown:true})}}></img> */}

                        </div>

                    </li>
                </ul>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    bot_data: state.data.bot_data
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(RoleAdd);
