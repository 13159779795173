import React, { Component } from "react";
import { connect } from "react-redux";
import server from "../../../api/server";

import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";

import { CLEAN_BOT } from "../../../variables";
import { setQuickStart } from "../../../actions";
import IntentsFailedModal from "../elements/IntentsFailedModal";
export class NewBot extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: "",
      version: "A",
      disabled: false,
      error: "",
      showIntentsFail: false,
    };
  }

  createBot = async () => {
    if (this.state.token != "") {
      var label = "Existing Bot";
      if (this.props.bot.validated == false) {
        label = "New Bot";
      }
      this.setState({ disabled: true, error: "" });
      var bot = CLEAN_BOT;
      bot.token = this.state.token;
      const response = await server.post("/dashboard/save", { bot: bot });
      if (response.data.updated == true) {
        try {
          if (window.sa_loaded) {
            window.sa_event("new_bot");
          }
        } catch (e) {

        }
        ReactGA.initialize("G-WHCMYGFHKX");
        ReactGA.event({
          category: "New Bot",
          label: label,
          action: "click",
          value: 1
        });
        if (label == "Existing Bot") {
          var quick_start = { ...this.props.quick_start };
          quick_start["new_bot"] = true;
          this.props.setQuickStart(quick_start);
        }
        window.location.replace("/dashboard");
      } else {
        var reason = "";

        if (response.data.reason == "validation") {
          reason =
            "Your bot's token could not be validated, please double check you have pasted your bot's token and not it's ID";
        } else if (response.data.reason == "intents") {
          this.setState({ showIntentsFail: true });
        } else if (response.data.reason == "token") {
          reason =
            "You did not provide your bot's token, please read the Getting Started section under the Dashboard to get your Bot's Token";
        } else if (response.data.reason == "exists") {
          reason = "A bot with this token already exists on your account.";
        } else if (response.data.reason == "cocreator_disabled") {
          reason = "The author of this bot has disabled co-creator";
        } else if (response.data.reason == "other_account") {
          reason = "A bot with this token already exists on another account.";
        }
        this.setState({ error: reason, disabled: false });
      }
    } else {
      this.setState({ error: "Please provide a token" });
    }
  };

  renderAnalytics = () => {
    // ReactGA.initialize("G-WHCMYGFHKX");
    // // console.log(window.location.pathname)
    // ReactGA.pageview(window.location.pathname);


  };

  componentDidMount() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  render() {
    return (
      <>
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        </Helmet>
        <div className="newbot">
          {this.state.showIntentsFail == true ? (
            <IntentsFailedModal
              closeModal={() => {
                this.setState({ showIntentsFail: false });
              }}
            />
          ) : null}

          {this.renderAnalytics()}
          <div className="newbot-container">
            <div className="section-content-normal m-mt-0 m-h-100">
              <div className="newbot-logo">
                <img src={process.env.PUBLIC_URL + "/images/logo-red.png"}></img>
              </div>

              <div className="newbot-iframe">
                <iframe
                  className="video-iframe"
                  src="https://www.youtube.com/embed/zXeg8JJyt0k"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen=""
                ></iframe>
              </div>
              <div className="mb-15">
                <h3 style={{ marginBottom: "0px" }}>Setup</h3>
                <span
                  style={{ display: "block" }}
                  class="section-content-header setup-span"
                >
                  To create a bot you must generate a bot and token at the{" "}
                  <a
                    href="https://discord.com/developers/applications"
                    target="_blank"
                    className="text-red"
                  >
                    Discord Developer Portal
                  </a>
                  . Once you have generated a token, paste it below and click Submit
                  to create your bot
                </span>
              </div>

              <div class="long-input">
                <label>Bot Token</label>
                <input
                  type="text"
                  value={this.state.token}
                  onChange={(e) => {
                    this.setState({ token: e.target.value });
                  }}
                />
              </div>

              <div className="mt-2">
                <span style={{ color: "red", fontWeight: "600" }}>
                  {this.state.error}
                </span>
              </div>

              <div className="mt-3 flex m-buttons" style={{ display: "flex" }}>
                <a
                  style={{ marginLeft: "auto" }}
                  className="btn btn-gray m-ml-0 m-mr-0"
                  href="https://discord.com/developers/applications"
                  target="_blank"
                >
                  Discord Developer Portal
                </a>
                <button
                  className="btn btn-red ml-3 m-ml-0 m-mr-0"
                  disabled={this.state.disabled}
                  onClick={(e) => {
                    this.createBot();
                  }}
                >
                  Create Bot
                </button>
              </div>
              <p className="text-center text-white mt-2">Logged into the wrong account? Click <a href={process.env.REACT_APP_API_SERVER + "/logout"}> here to log out</a>.</p>
            </div>
          </div>
        </div>
      </>);
  }
}

const mapStateToProps = (state) => ({
  bot: state.data.bot,
});

const mapDispatchToProps = {
  setQuickStart,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewBot);
