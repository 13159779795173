

export function renderResponseActionOptions(actions, current_id) {
    var options = [{
        label: "",
        value: null
    }];

    var types = [
        'plain_text',
        "embed",
        'random_response',
        'button_response',
        'targeted_response',
        'dm_response',
        'select_menu'
    ];

    actions.forEach(action => {
        if (action.id != current_id && 'data' in action && 'data' in action.data && action.data.data && types.includes(action.data.data.type)) {
            var label = action.data.node_options.title;
            if ("command_label" in action.data.data && action.data.data.command_label != "") {
                label = action.data.data.command_label;
            }
            options.push({
                value: action.id,
                label: label
            });
        }
    });


    return options;
};

