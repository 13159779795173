import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { updatePage } from "../../actions";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import CustomModuleSideMenuItem from "./elements/CustomModuleSideMenuItem";
import history from "../../history";
import BotSwitcher from "./modules/BotSwitcher";
import PremiumModal from "./elements/PremiumModal";
import QuickStart from "./elements/QuickStart";
import { TASKS } from "../../variables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAd, faCog, faFile, faCogs, faDollarSign, faExclamationCircle, faHammer, faHandHolding, faHome, faInfo, faInfoCircle, faLink, faMoneyBill, faPager, faQuestionCircle, faServer, faShoppingCart, faGift, faToolbox, faWrench, faHandshake, faCodeBranch, faDatabase } from "@fortawesome/free-solid-svg-icons";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";

import { AllModules } from "../../variables";
import moment from "moment";
import BotDropdown from "./modules/BotDropdown";

class SideMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hovering: "",
			openBotSwitcher: false,
			showQuickStart: false,
			premiumModule: false,
			length: 0,
			percent: 0,
			tasks_left: 0,
			completed: 0
		};
	}

	componentDidMount() {
		const length = TASKS.length;
		var completed = 0;
		TASKS.forEach((task) => {
			if (this.props.quick_start[task.slug] != undefined && this.props.quick_start[task.slug] == true) {
				completed += 1;
			}
		});

		this.setState({
			length: length,
			percent: (completed / length) * 100,
			tasks_left: length - completed
		});
	}

	handleMouseHover = (e, category) => {
		this.setState({ hovering: category });
		// this.setState(this.toggleHoverState);
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.quick_start != this.props.quick_start) {
			const length = TASKS.length;
			var completed = 0;
			TASKS.forEach((task) => {
				if (this.props.quick_start[task.slug] != undefined && this.props.quick_start[task.slug] == true) {
					completed += 1;
				}
			});

			this.setState({
				length: length,
				percent: (completed / length) * 100,
				tasks_left: length - completed,
				completed: completed
			});
		}
	}

	changePage = (page, premium) => {
		// if (premium == true && this.props.premium == false) {
		//     console.log("PREMIUM PAGE");
		//     this.setState({ premiumModule: true });
		//     console.log(this.state);
		// } else {
		history.push("/dashboard/" + page);
		// }
	};

	closeBotSwitcher = () => {
		this.setState({ openBotSwitcher: false });
	};

	renderImage = () => {
		var img = "";
		if (this.props.bot.img != "" && this.props.bot.img != null) {
			return this.props.bot.img;
		}
		this.props.bots.forEach((bot) => {
			if (bot.id == this.props.bot.id) {
				img = bot.avatar;
			}
		});
		return img;
	};

	checkEnabled = (module) => {
		if (module == "Moderation") {
			var check = false;
			Object.keys(this.props.bot.commands.moderation).forEach((key) => {
				if (this.props.bot.commands.moderation[key].enabled === true && key != "afk" && key != "unban") {
					check = true;
				}
			});
			return check;
		} else if (module == "Announcements" && (this.props.bot.commands.announcements.join.length > 0 || this.props.bot.commands.announcements.ban.length > 0 || this.props.bot.commands.announcements.leave.length > 0)) {
			return true;
		} else if (module == "Automod" && this.props.bot.commands.automod.automod.enabled == true) {
			return true;
		} else if (module == "Autoresponder" && this.props.bot.commands.responder.enabled == true) {
			return true;
		} else if (module == "Auto-Role" && this.props.bot.commands.autorole.enabled == true) {
			return true;
		} else if (module == "Timed Messages" && this.props.bot.commands.messenger.enabled == true) {
			return true;
		} else if (module == "Statistics Channels" && this.props.bot.commands.statsChannels.enabled == true) {
			return true;
		} else if (module == "Status" && this.props.premium == true) {
			return true;
		} else if (module == "Reaction Roles" && this.props.bot.commands.reaction_roles.enabled == true) {
			return true;
		} else if (module == "YouTube" && this.props.bot.commands.youtube.enabled == true) {
			return true;
		} else if (module == "Twitter" && this.props.bot.commands.twitter.enabled == true) {
			return true;
		} else if (module == "Reddit" && this.props.bot.commands.reddit.enabled == true) {
			return true;
		} else if (module == "Twitch" && this.props.bot.commands.twitch.length > 0) {
			return true;
		} else if (module == "Meme Creator" && this.props.bot.commands.meme_creator.enabled == true) {
			return true;
		} else if (module == "Levels" && this.props.bot.commands.levels.enabled == true) {
			return true;
		} else if (module == "Economy" && this.props.bot.commands.economy.enabled == true) {
			return true;
		} else if (module == "Music" && this.props.bot.commands.music.enabled == true) {
			return true;
		} else if (module == "Crypto" && this.props.bot.commands.crypto.enabled == true) {
			return true;
		} else if (module == "NFTs" && this.props.bot.commands.opensea.enabled == true) {
			return true;
		} else if (module == "Embed Builder") {
			return true;
		} else if (module == "Giveaways" && this.props.bot.commands.giveaways.enabled == true) {
			return true;
		} else if (module == "Trivia" && this.props.bot.commands.fun.trivia.enabled == true) {
			return true;
		} else if (module == "Search") {
			var check = false;
			Object.keys(this.props.bot.commands.search).forEach((key) => {
				if (this.props.bot.commands.search[key].enabled == true) {
					check = true;
				}
			});
			return check;
		} else if (module == "Logging") {
			var check = false;
			Object.keys(this.props.bot.commands.logging).forEach((key) => {
				if (this.props.bot.commands.logging[key].enabled == true) {
					check = true;
				}
			});
			return check;
		} else if (module == "Botlist" && this.props.bot.commands.advertise.enabled == true) {
			return true;
		} else if (module == "Weather" && this.props.bot.commands.utils.weather.enabled == true) {
			return true;
		} else if (module == "Translator" && this.props.bot.commands.utils.translate.enabled == true) {
			return true;
		} else if (module == "Co Create" && this.props.bot.commands.cocreate.enabled == true) {
			return true;
		} else if (module == "Fortnite" && this.props.bot.commands.games.fortnite.enabled == true) {
			return true;
		} else if (module == "Apex Legends" && this.props.bot.commands.games.apex.enabled == true) {
			return true;
		} else if (module == "Custom Commands" && this.props.bot.commands.slash.commands.length > 0) {
			return true;
		} else if (module == "Usage" && this.props.bot.commands.settings.embeds.enabled == true) {
			return true;
		} else if (module == "Tickets" && this.props.bot.commands.tickets.enabled == true) {
			return true;
		} else if (module == "Data Storage" && this.props.bot.commands.variables.variables.length > 0) {
			return true;
		} else if (module == "Custom Events" && this.props.bot.commands.custom_events.events.length > 0) {
			return true;
		} else if (module == "Active Developer Badge" && this.props.bot.commands.activeDevBadge.enabled == true) {
			return true;
		} else if (module == "Webhooks" && this.props.bot.commands.webhooks.webhooks?.length > 0) {
			return true;
		} else if (module == "Timed Events" && this.props.bot.commands.timed_events.events?.length > 0) {
			return true;
		} else if (module == "IFTTT" && this.props.ifttt_linked) {
			return true;
		}
	};

	customModuleClick = (id, name) => {
		history.push(`/dashboard/module/${id}/${name}`);
		this.forceUpdate();
	};

	checkDepreciation(module) {
		var depreciated = true;
		if (module.name == "Reaction Roles") {
			if (this.props.bot?.commands?.reaction_roles?.enabled == true) {
				depreciated = false;
			}
		} else if (module.name == "Levels") {
			if (this.props.bot?.commands?.levels?.enabled == true) {
				depreciated = false;
			}
		} else if (module.name == "Announcements" && this.props.bot?.commands.announcements?.enabled != false) {
			depreciated = false;
		} else if (module.name == "Auto-Role" && this.props.bot?.commands.announcements?.enabled != false) {
			depreciated = false;
		} else if (module.name == "Autoresponder" && this.props.bot?.commands.responder?.enabled != false) {
			depreciated = false;
		} else if (module.name == "Translator" && this.props.bot?.commands.utils?.translate?.enabled != false) {
			depreciated = false;
		} else if (module.name == "Economy" && this.props.bot?.commands.economy?.enabled != false) {
			depreciated = false;
		} else if (module.name == "Weather" && this.props.bot?.commands.utils?.weather?.enabled != false) {
			depreciated = false;
		}

		return depreciated;
	}

	renderModules = () => {
		var modules = [];
		var customModules = [...this.props.modules];


		// Filter out beta modules if beta is false
		if (this.props.beta != true) {
			customModules = customModules.filter(module => module.beta !== true);
		}

		var featured = [];
		var favourited = [];
		var newModules = [];

		var favModules = this.props.user.favModules;
		var ungrouped = [];
		// console.log(history.location.pathname);
		AllModules.forEach((moduleCategory) => {
			var module_items = [];

			moduleCategory.modules.forEach((module) => {
				if (module.name != "Text Commands" && (module.beta != true || this.props.beta == true)) {
					if (module.depreciated == true && this.checkDepreciation(module)) {
						return;
					}
					var moduleItem = (
						<li
							className={`${module.premium == true && this.props.premium == false ? "side-menu-premium" : null}`}
							onClick={() => {
								this.changePage(module.name.toLowerCase().split(" ").join(""), module.premium);
							}}
						>
							<span
								onMouseEnter={(e) => {
									this.handleMouseHover(e, module.name.toLowerCase().split(" ").join(""));
								}}
								onMouseLeave={(e) => {
									this.setState({ hovering: "" });
								}}
								className={`dash-sidebar-item ${window.location.pathname == `/dashboard/${module.name.toLowerCase().split(" ").join("")}` ? "active" : ""}`}
							>
								<div className={`${this.checkEnabled(module.name) == true ? "dash-sidebar-item-icon-enabled" : "dash-sidebar-item-icon-disabled"}`}>
									<img src={process.env.PUBLIC_URL + "/images/icons/" + module.icon} className="w-[15px] h-[15px] mr-[15px] rounded-full"></img>
								</div>{" "}
								{module.name}
							</span>
						</li>
					);

					if (module.featured) {
						featured.push(moduleItem);
					} else if (favModules.includes(module.name)) {
						favourited.push(moduleItem);
					} else if (module.new) {
						newModules.push(moduleItem);
					} else {
						module_items.push(moduleItem);
					}
				}
			});

			var index = customModules.length;

			while (index--) {
				var customModule = customModules[index];
				if (customModule.hidden) {
					customModules.splice(index, 1);
					continue;
				}

				if (customModule.depreciated == true && this.props.bot.commands.moduleSettings?.[customModule.id]?.enabled != true) {
					customModules.splice(index, 1);
					continue;
				}
				// customModules.forEach((customModule, index) => {

				var moduleItem = (
					<CustomModuleSideMenuItem
						premiumRequired={customModule.premium}
						changePage={(id, name) => {
							this.customModuleClick(id, name);
						}}
						active={history.location.pathname == `/dashboard/module/${customModule.id}/${customModule.name.toLowerCase().split(" ").join("")}`}
						id={customModule.id}
						name={customModule.name}
						img={customModule.img}
					/>
				);

				if (customModule.featured) {
					featured.push(moduleItem);
					customModules.splice(index, 1);
				} else if (favModules.includes(customModule.id)) {
					favourited.push(moduleItem);
					customModules.splice(index, 1);
				} else if (customModule.new) {
					newModules.push(moduleItem);
					customModules.splice(index, 1);
				} else if (customModule.category == moduleCategory.name) {
					// Put at start of array;
					module_items.unshift(moduleItem);

					customModules.splice(index, 1);
				} else {
					// index++;
					// customModules.splice(index, 1);
				}
			}
			// });

			if (module_items.length > 0) {
				modules.push(
					<div key={moduleCategory.name}>
						<label>{moduleCategory.name}</label>
						{module_items}
					</div>
				);
			}
		});

		// Check for modules that are not in a category;
		// Create that category and add any other modules with the same category to it.
		// Group remaining by category
		var groupedModules = customModules.reduce((r, a) => {
			r[a.category] = [...(r[a.category] || []), a];
			return r;
		}, {});
		Object.keys(groupedModules).forEach((key, index) => {
			var module_items = [];
			groupedModules[key].forEach((customModule, index) => {


					module_items.unshift(
						<CustomModuleSideMenuItem 
							premiumRequired={customModule.premium} 
							active={history.location.pathname == `/dashboard/module/${customModule.id}/${customModule.name.toLowerCase().split(" ").join("")}`} 
							id={customModule.id} 
							name={customModule.name} 
							img={customModule.img} 
						/>
					);
				
			});

			if (module_items.length > 0) {
				modules.push(
					<div key={key}>
						<label>{key}</label>
						{module_items}
					</div>
				);
			}
		});

		var featuredandfavourited = [...featured, ...favourited, ...newModules];
		if (featured.length > 0) {
			modules.unshift(
				<div>
					<label>Featured & Favourited</label>
					{featuredandfavourited}
				</div>
			);
		}

		// <label>SERVER MANAGEMENT</label>
		// <li onClick={() =>{this.changePage("moderation")}}>
		//     <span onMouseEnter={(e) =>{this.handleMouseHover(e,"support")}} onMouseLeave={(e) =>{this.setState({hovering:""})}} className={`dash - sidebar - item ${ window.location.pathname == "/dashboard/support" ? 'active' : "" }`}><FontAwesomeIcon icon={faQuestionCircle} fixedWidth/> Moderation</span>
		// </li>
		return modules;
	};

	checkCustomModuleEnabled = (id) => {
		var enabled = false;
		if (this.props.moduleSettings[id] != undefined && this.props.moduleSettings[id].enabled == true) {
			enabled = true;
		}
		return enabled;
	};

	renderErrorCount = () => {
		if (this.props.user.logged == undefined || this.props.api_errors.length == 0) {
			return null;
		} else {
			var count = 0;
			this.props.api_errors.forEach((error) => {
				if ((this.props.logsChecked == null || moment(this.props.logsChecked).isBefore(error.timeStamp)) && moment(this.props.user.logged).isBefore(moment(error.timeStamp))) {
					count += 1;
				}
			});
			if (count == 0) {
				return null;
			} else {
				return (
					<div className="error-log-count">
						<div className="error-log-count-inner">{count}</div>
					</div>
				);
			}
		}
	};

	render() {
		return (
			<div>
				{this.state.showQuickStart ? (
					<QuickStart
						close={() => {
							this.setState({ showQuickStart: false });
						}}
					/>
				) : null}
				{this.state.openBotSwitcher ? <BotSwitcher close={this.closeBotSwitcher} /> : null}
				{this.state.premiumModule ? (
					<PremiumModal
						closeModal={() => {
							this.setState({ premiumModule: false });
						}}
					/>
				) : null}
				<nav className="dash-sidebar" style={{ top: `${this.props.premium == true ? "0px" : "10px"}` }}>
					<div className="mt-[30px] px-2">
						<BotDropdown />
					</div>

					{/* <div className="dash-sidebar-avatar" onClick={(e) => { this.setState({ openBotSwitcher: true }); }}>
                        <div className={`${this.props.bot.online == true ? "sidebar-avatar-enabled" : "sidebar-avatar-disabled"}`}>
                            <img src={this.renderImage() || "https://res-3.cloudinary.com/crunchbase-production/image/upload/c_lpad,f_auto,q_auto:eco/v1440924046/wi1mlnkbn2jluko8pzkj.png"}></img>
                        </div>
                        <span style={{ marginTop: "5px", fontWeight: "800" }}>{this.props.bot.name}</span>
                    </div> */}

					<ul>
						{/* <label>DASHBOARD</label> */}
						<li
							onClick={(e) => {
								this.changePage("modules");
							}}
						>
							<span
								onMouseEnter={(e) => {
									this.handleMouseHover(e, "modules");
								}}
								onMouseLeave={(e) => {
									this.setState({ hovering: "" });
								}}
								className={`dash-sidebar-item ${window.location.pathname == "/dashboard/modules" || window.location.pathname == "/dashboard" ? "active" : ""}`}
							>
								<FontAwesomeIcon fixedWidth icon={faHome} /> Modules
							</span>
						</li>

						<li
							href="https://botghost.com/market"
							onClick={() => {
								// Open in new tab
								var win = window.open("https://botghost.com/market", "_blank");
							}}
							target="_blank"
						>
							<span
								onMouseEnter={(e) => {
									this.handleMouseHover(e, "freepremium");
								}}
								onMouseLeave={(e) => {
									this.setState({ hovering: "" });
								}}
								className={`dash-sidebar-item ${window.location.pathname == "/dashboard/market" ? "active" : ""}`}
							>
								<FontAwesomeIcon icon={faShoppingCart} fixedWidth /> Market
							</span>
						</li>
						{/* <label>SETTINGS</label> */}
						<label>Settings</label>
						<li
							id="settings-side-menu"
							onClick={(e) => {
								this.changePage("settings");
							}}
						>
							<span
								onMouseEnter={(e) => {
									this.handleMouseHover(e, "settings");
								}}
								onMouseLeave={(e) => {
									this.setState({ hovering: "" });
								}}
								className={`dash-sidebar-item ${window.location.pathname == "/dashboard/settings" ? "active" : ""}`}
							>
								<FontAwesomeIcon icon={faCog} fixedWidth /> Settings
							</span>
						</li>

						<a target="_blank" href={`https://discord.com/oauth2/authorize?client_id=${this.props.bot.id}&scope=applications.commands%20bot&permissions=8`}>
							<li>
								<span
									id="invite-side-menu"
									onMouseEnter={(e) => {
										this.handleMouseHover(e, "invite");
									}}
									onMouseLeave={(e) => {
										this.setState({ hovering: "" });
									}}
									className={`dash-sidebar-item ${this.props.page == "invite" ? "active" : ""}`}
								>
									<FontAwesomeIcon icon={faLink} fixedWidth /> Invite
								</span>
							</li>
						</a>

						<li
							onClick={() => {
								this.changePage("datastorage");
							}}
						>
							<span
								onMouseEnter={(e) => {
									this.handleMouseHover(e, "servers");
								}}
								onMouseLeave={(e) => {
									this.setState({ hovering: "" });
								}}
								className={`dash-sidebar-item ${window.location.pathname == "/dashboard/datastorage" ? "active" : ""}`}
							>
								<FontAwesomeIcon icon={faDatabase} fixedWidth /> Data Storage
							</span>
						</li>

						<li
							onClick={() => {
								this.changePage("servers");
							}}
						>
							<span
								onMouseEnter={(e) => {
									this.handleMouseHover(e, "servers");
								}}
								onMouseLeave={(e) => {
									this.setState({ hovering: "" });
								}}
								className={`dash-sidebar-item ${window.location.pathname == "/dashboard/servers" ? "active" : ""}`}
							>
								<FontAwesomeIcon icon={faServer} fixedWidth /> Servers
							</span>
						</li>

						<li
							onClick={() => {
								this.changePage("module/42056fd5-1c1d-4f89-8db7-794fe732424c/status");
							}}
						>
							<span
								onMouseEnter={(e) => {
									this.handleMouseHover(e, "freepremium");
								}}
								onMouseLeave={(e) => {
									this.setState({ hovering: "" });
								}}
								className={`dash-sidebar-item ${window.location.pathname == "/dashboard/module/42056fd5-1c1d-4f89-8db7-794fe732424c/status" ? "active" : ""}`}
							>
								<FontAwesomeIcon icon={faWrench} fixedWidth /> Status
							</span>
						</li>

						{this.props.collab != true ? (
							<li
								onClick={() => {
									this.changePage("collab");
								}}
							>
								<span
									onMouseEnter={(e) => {
										this.handleMouseHover(e, "collab");
									}}
									onMouseLeave={(e) => {
										this.setState({ hovering: "" });
									}}
									className={`dash-sidebar-item ${window.location.pathname == "/dashboard/collab" ? "active" : ""}`}
								>
									<FontAwesomeIcon icon={faHandshake} fixedWidth /> Collab
								</span>
							</li>
						) : null}

						<li
							onClick={(e) => {
								this.changePage("errors");
							}}
						>
							<span
								onMouseEnter={(e) => {
									this.handleMouseHover(e, "errors");
								}}
								onMouseLeave={(e) => {
									this.setState({ hovering: "" });
								}}
								className={`dash-sidebar-item ${window.location.pathname == "/dashboard/errors" ? "active" : ""}`}
							>
								<FontAwesomeIcon icon={faExclamationCircle} fixedWidth size="1x" /> Error Logs {this.renderErrorCount()}
							</span>
						</li>

						<li
							onClick={() => {
								this.changePage("module/be383fbe-8339-433b-a51c-94db234fde38/activedeveloperbadge");
							}}
						>
							<span
								onMouseEnter={(e) => {
									this.handleMouseHover(e, "freepremium");
								}}
								onMouseLeave={(e) => {
									this.setState({ hovering: "" });
								}}
								className={`dash-sidebar-item ${window.location.pathname == "/dashboard/module/be383fbe-8339-433b-a51c-94db234fde38/activedeveloperbadge" ? "active" : ""}`}
							>
								<FontAwesomeIcon icon={faCodeBranch} fixedWidth /> Active Developer Badge
							</span>
						</li>

						{/* =========== PREMIUM ========= */}
						<label>Premium</label>

						<li
							id="premium-side-menu"
							onClick={(e) => {
								this.changePage("premium");
							}}
						>
							<span
								onMouseEnter={(e) => {
									this.handleMouseHover(e, "premium");
								}}
								onMouseLeave={(e) => {
									this.setState({ hovering: "" });
								}}
								className={`dash-sidebar-item ${window.location.pathname == "/dashboard/premium" ? "active" : ""}`}
							>
								<img style={{ height: "20px", marginRight: "10px" }} src={process.env.PUBLIC_URL + "/images/crown.svg"}></img> Premium
							</span>
						</li>

						<li
							onClick={() => {
								this.changePage("freepremium");
							}}
						>
							<span
								onMouseEnter={(e) => {
									this.handleMouseHover(e, "freepremium");
								}}
								onMouseLeave={(e) => {
									this.setState({ hovering: "" });
								}}
								className={`dash-sidebar-item ${window.location.pathname == "/dashboard/freepremium" ? "active" : ""}`}
							>
								<FontAwesomeIcon icon={faDollarSign} fixedWidth /> Free Premium
							</span>
						</li>

						<li
							onClick={() => {
								this.changePage("gifting");
							}}
						>
							<span
								onMouseEnter={(e) => {
									this.handleMouseHover(e, "freepremium");
								}}
								onMouseLeave={(e) => {
									this.setState({ hovering: "" });
								}}
								className={`dash-sidebar-item ${window.location.pathname == "/dashboard/gifting" ? "active" : ""}`}
							>
								<FontAwesomeIcon icon={faGift} fixedWidth /> Gifting
							</span>
						</li>

						<label>Help</label>

						<a target="_blank" href="https://discord.gg/botghost">
							<li>
								<span
									onMouseEnter={(e) => {
										this.handleMouseHover(e, "invite");
									}}
									onMouseLeave={(e) => {
										this.setState({ hovering: "" });
									}}
									className={`dash-sidebar-item ${this.props.page == "invite" ? "active" : ""}`}
								>
									<FontAwesomeIcon icon={faDiscord} fixedWidth /> Support Server
								</span>
							</li>
						</a>

						{this.renderModules()}
					</ul>

					{/* 
                    <ul style={{paddingLeft:"16px",marginTop:"15px"}}>
                        
                        <li className="quick-start-container">
                            <div  style={{cursor:"pointer",width:"100%"}} onClick={() =>{this.setState({showQuickStart:!this.state.showQuickStart})}}>
                                <div style={{width:"40px",float:"left",marginRight:"10px"}}>
                                    <CircularProgressbar value={this.state.percent} text={this.state.tasks_left || "0"} styles={buildStyles({
                                        textSize:"40px",
                                        fontWeight:"800",
                                        pathColor:"#f45142",
                                        textColor:"#f45142"
                                    })}/>
                                </div>
                                <div>
                                    <div style={{fontWeight:"800"}}>Quick Start </div>
                                    <div style={{fontSize:"12px"}}>{this.state.tasks_left || "0"} Remaining Tasks</div>
                                </div>
                            </div>
                        </li>
                    </ul> */}
				</nav>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	page: state.page,
	bot: state.data.bot,
	bots: state.data.bots,
	premium: state.data.premium,
	quick_start: state.quick_start,
	user: state.data.user,
	beta: state.data.beta ? true : false,
	api_errors: state.data.api_errors,
	logsChecked: state.data.logsChecked,
	moduleSettings: state.data.bot.commands.moduleSettings,
	modules: state.data.modules,
	ifttt_linked: state.data.user.ifttt_linked,
	collab: state.data.collab
});

export default connect(mapStateToProps, { updatePage })(SideMenu);
